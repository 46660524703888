import React from 'react';
import VSelectInput from '../../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import VFormCard from '../../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import { usePinnedEventForm } from './hook';

const PinnedEventForm = ({ formState, events, onChange }) => {
  const { eventsOptions } = usePinnedEventForm(events);

  return (
    <VFormCard label="Evento destacado">
      <VSelectInput
        onChange={onChange}
        options={eventsOptions}
        name="pinnedEventId"
        value={formState?.pinnedEventId.value}
      />
    </VFormCard>
  );
};

export default PinnedEventForm;
