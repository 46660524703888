import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Divider, Stack, Accordion, AccordionSummary } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './EventTicketCard.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import EventTicketCardDetail from '../EventTicketCardDetail/EventTicketCardDetail';
import { useEventTicketCard } from './hook';
import Button from '../../../../Components/Buttons/Button/Button';
import TransferQrBottomSheet from '../../../../Components/BottomSheets/TransferQrBottomSheet/TransferQrBottomSheet';
import ReturnQrBottomSheet from '../../../../Components/BottomSheets/ReturnQrBottomSheet/ReturnQrBottomSheet';
import Chip from '../../../../Venti-UI-Kit/Chip/Chip';
import { formatDatetime, getEventVenueName } from '../../../../utils/utils';
import PostQrBottomSheet from '../../../../Components/BottomSheets/PostQrBottomSheet/PostQrBottomSheet';
import DeleteQrPostBottomSheet from '../../../../Components/BottomSheets/DeleteQrPostBottomSheet/DeleteQrPostBottomSheet';
import TextButton from '../../../../Components/Buttons/TextButton/TextButton';
import { QR_STATUS, SECONDARY_MARKET_PUBLICATION_STATUS } from '../../../../constants/types';
import SelfManagedRefundBottomSheet from '../../../../Components/BottomSheets/SelfManagedRefundBottomSheet/SelfManagedRefundBottomSheet';
import { ReactComponent as QrShieldedImage } from '../../../../assets/images/qrShielded.svg';
import { ReactComponent as QrExpiredImage } from '../../../../assets/images/qrExpired.svg';
import { ReactComponent as QrUsedImage } from '../../../../assets/images/qrUsed.svg';

const EventTicketCard = ({ classes, event, qr, getEventQrs, selfManagedRefundEnabled }) => {
  const { bannerImg, name, settings } = event;

  const {
    qrStatus,
    qrId,
    qrMessage,
    qrIsLoading,
    setQrIsLoading,
    formattedTicketPrice,
    ticketBelongsToAnotherCustomer,
    actionsButtons,
    bottomSheets,
    originCustomerLabel,
    secondaryMarketStatusLabel,
    secondaryMarketTransactionData,
    isOriginalOwner,
    handleOpenSelfManagedRefundBottomSheet,
    showMoreIsOpen,
    setShowMoreIsOpen,
  } = useEventTicketCard(qr, settings.isShieldActive, settings.isShieldActiveForPublishedQrs);

  const { show } = useIntercom();

  const eventVenue = getEventVenueName(event);
  const {
    orderId,
    orderCountNumber,
    ticketTypeName,
    section,
    sectionColor,
    qrTransfer,
    externalId,
    secondaryMarket,
    startDate,
  } = qr;

  return (
    <Box className={classes.userEventTicketCard} position="relative">
      <img alt={name} src={bannerImg} className={classes.bannerImg} />

      {secondaryMarketStatusLabel?.show && (
        <Box position="absolute" top={8} right={8}>
          <Chip
            label={secondaryMarketStatusLabel.label}
            bgcolor={secondaryMarketStatusLabel.bgcolor}
            labelColor="white"
            labelVariant="overline"
          />
        </Box>
      )}

      <Stack padding="16px 16px 24px 16px" gap={2}>
        <EventTicketCardDetail title="Orden ID" value={`#${orderId} - ${orderCountNumber}`} />
        <Divider />
        <Stack alignItems="center" gap={2}>
          {ticketBelongsToAnotherCustomer ? (
            <Stack className={classes.transferInfoBox}>
              {qrTransfer && (
                <>
                  <VTypography variant="body2">Este ticket fue transferido a:</VTypography>
                  {qrTransfer.destinationCustomer.firstName && (
                    <VTypography variant="subtitle4">
                      {qrTransfer.destinationCustomer.firstName}{' '}
                      {qrTransfer.destinationCustomer.lastName}
                    </VTypography>
                  )}
                  <VTypography variant="subtitle4">
                    {qrTransfer.destinationCustomer.mail}
                  </VTypography>
                  <VTypography variant="body2">
                    {formatDatetime(qrTransfer.createdAt).fullTimeDate} hs
                  </VTypography>
                </>
              )}
              {secondaryMarketTransactionData?.type ===
                SECONDARY_MARKET_PUBLICATION_STATUS.SOLD && (
                <>
                  <VTypography variant="body2" align="center">
                    Este ticket fue vendido a través de Venti Swap
                  </VTypography>
                  <VTypography variant="body2" align="center">
                    ¿Tuviste algún problema?{<br />}
                    <TextButton title="Escribinos" variant="body2" onClick={show} />
                  </VTypography>
                  <VTypography variant="body2">
                    {formatDatetime(secondaryMarket.soldData.date).fullTimeDate} hs
                  </VTypography>
                </>
              )}
            </Stack>
          ) : (
            <>
              {qrStatus === QR_STATUS.USED && <QrUsedImage />}
              {qrStatus === QR_STATUS.EXPIRED && <QrExpiredImage />}
              {qrStatus === QR_STATUS.PAID && !qrId && <QrShieldedImage />}
              {qrStatus === QR_STATUS.PAID && qrId && (
                <img
                  alt={`QR ${qrId}`}
                  fetchPriority="high"
                  src={`https://quickchart.io/qr?text=${qrId}&size=136x136&margin=0`}
                  style={{ opacity: qrIsLoading ? 0 : 1 }}
                  onLoad={() => {
                    setQrIsLoading(false);
                  }}
                  className="qr"
                />
              )}
              {qrMessage && <Chip label={qrMessage} variant="outline" />}
            </>
          )}
        </Stack>

        {actionsButtons.length > 0 && (
          <>
            <Divider />
            {actionsButtons.map(
              ({ key, show, label, action }) =>
                show && (
                  <Button
                    size="small"
                    title={label}
                    color="alternative"
                    key={key}
                    onClick={action}
                  />
                )
            )}
          </>
        )}

        {secondaryMarketTransactionData && (
          <>
            <Divider />
            <EventTicketCardDetail
              title="Venti Swap ID"
              value={`#${secondaryMarketTransactionData.id}`}
            />
          </>
        )}

        <Divider />
        <Stack gap={2}>
          <EventTicketCardDetail title="Ticket" value={ticketTypeName} />
          <EventTicketCardDetail title="Ticket ID" value={externalId} />
          <Stack direction="row">
            <EventTicketCardDetail title="Precio" value={formattedTicketPrice} />
            {section && (
              <EventTicketCardDetail
                title="Sector"
                value={section}
                valueTypographyProps={{
                  style: { backgroundColor: sectionColor },
                  className: classes.sectionValueContainer,
                }}
              />
            )}
          </Stack>

          <EventTicketCardDetail title="Lugar" value={eventVenue} />
          <Stack direction="row">
            <EventTicketCardDetail
              title="Fecha del evento"
              value={formatDatetime(startDate).date}
            />
            <EventTicketCardDetail
              title="Hora del evento"
              value={`${formatDatetime(startDate).time} hs`}
            />
          </Stack>
        </Stack>
        {selfManagedRefundEnabled && isOriginalOwner && (
          <>
            <Divider />
            <Accordion
              aria-controls="accordion"
              className={classes.showMoreAccordion}
              expanded={showMoreIsOpen}
              elevation={0}
              onChange={() => {
                setShowMoreIsOpen(!showMoreIsOpen);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <VTypography variant="button1">Más acciones</VTypography>
              </AccordionSummary>
              <Button
                size="small"
                title="Solicitar Reembolso"
                variant="text"
                onClick={handleOpenSelfManagedRefundBottomSheet}
              />
            </Accordion>
          </>
        )}
      </Stack>
      {selfManagedRefundEnabled && (
        <SelfManagedRefundBottomSheet
          open={bottomSheets.selfManagedRefund.open}
          onClose={bottomSheets.selfManagedRefund.onClose}
          orderId={orderId}
          getEventQrs={getEventQrs}
          canRefund={qr.selfManagedRefundAvailability.canRefund}
        />
      )}
      <TransferQrBottomSheet
        open={bottomSheets.transfer.open}
        onClose={bottomSheets.transfer.onClose}
        qrExternalId={externalId}
        getEventQrs={getEventQrs}
      />
      <ReturnQrBottomSheet
        open={bottomSheets.return.open}
        onClose={bottomSheets.return.onClose}
        userName={originCustomerLabel}
        eventName={name}
        qrExternalId={externalId}
        getEventQrs={getEventQrs}
      />
      <PostQrBottomSheet
        open={bottomSheets.post.open}
        onClose={bottomSheets.post.onClose}
        qrExternalId={externalId}
      />
      <DeleteQrPostBottomSheet
        open={bottomSheets.deletePost.open}
        onClose={bottomSheets.deletePost.onClose}
        qrExternalId={externalId}
        getEventQrs={getEventQrs}
      />
    </Box>
  );
};

export default withStyles(styles)(EventTicketCard);
