import React from 'react';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import SuccessPurchaseContent from './SuccessPurchaseContent/SuccessPurchaseContent';
import ErrorPurchaseContent from './ErrorPurchaseContent/ErrorPurchaseContent';
import { usePurchaseResultPages } from './hook';

const PurchaseResultPage = () => {
  const { internalErrorCode, isLoading, order, orderSuccess } = usePurchaseResultPages();

  return (
    <PageContainer withFooter>
      {isLoading ? (
        <CustomCircularProgress />
      ) : orderSuccess ? (
        <SuccessPurchaseContent order={order} />
      ) : (
        <ErrorPurchaseContent internalErrorCode={internalErrorCode} />
      )}
    </PageContainer>
  );
};

export default PurchaseResultPage;
