import { useState } from 'react';
import { handleRequestHelper } from '../../../../utils/helpers';
import api from '../../../../api/api';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';

export const useMoreEvents = (event) => {
  const [moreEvents, setMoreEvents] = useState([]);
  const { showNotification } = useNotifications();

  const getMoreEvents = async () => {
    await handleRequestHelper({
      endpoint: () => api.getMoreEventsFromProducer(event.id),
      showNotification,
      onSuccess: ({ events }) => {
        setMoreEvents(events);
      },
    });
  };

  return {
    moreEvents,
    getMoreEvents,
  };
};
