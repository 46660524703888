import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { Stack } from '@mui/material';
import Slider from 'react-slick';
import TicketQuantitySelector from '../../TicketQuantitySelector/TicketQuantitySelector';
import styles from './EventTicketsCardStyles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Chip from '../../../Venti-UI-Kit/Chip/Chip';
import { useEventTicketCard } from './hook';

const TICKET_TYPE = {
  table: 'Acceso Múltiple',
  presential: 'Acceso Estándar',
  streaming: 'Streaming',
  autoshow: 'Autoshow',
};

const EventTicketsCard = ({ classes, currentValue, ticket, addToCart }) => {
  const theme = useTheme();

  const {
    hasImages,
    section,
    isAvailableLabel,
    multipleAccessPriceLabel,
    hasDiscountWithLemon,
    lemonDiscount,
    ticketGroupSize,
    mainPriceLabel,
    ticketQuantityToSell,
    selectTicketQuantity,
  } = useEventTicketCard(ticket, addToCart);

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} className={classes.ticketCardContainer}>
      <Stack gap={2} width="100%" direction={{ xs: 'column', sm: hasImages ? 'column' : 'row' }}>
        <Stack flex={3}>
          <Stack direction="row" gap={1} alignItems="center">
            {section && (
              <Chip
                label={section.name}
                bgcolor={section.color}
                labelColor="white"
                labelVariant="subtitle5"
              />
            )}
            <VTypography variant="body2" color={theme.palette.grey[700]}>
              {TICKET_TYPE[ticket.type]}
            </VTypography>
          </Stack>
          <VTypography variant="h4">{ticket.name}</VTypography>
          <VTypography variant="body1">{ticket.description}</VTypography>
        </Stack>

        <Stack gap={2} alignItems={{ xs: 'end', sm: hasImages ? 'start' : 'end' }}>
          <Stack alignItems="inherit">
            <VTypography variant="body2" color={theme.palette.grey[700]}>
              {isAvailableLabel}
            </VTypography>
            <VTypography variant="h3">{multipleAccessPriceLabel}</VTypography>
            {hasDiscountWithLemon && (
              <VTypography variant="subtitle6" color={theme.palette.primary.main}>
                Descuento con Astropay {lemonDiscount}%
              </VTypography>
            )}
            {ticket.isLemonExclusive && (
              <VTypography variant="subtitle6">Venta exclusiva con Astropay</VTypography>
            )}
            {ticketGroupSize > 1 && (
              <VTypography variant="subtitle6">
                {ticket.groupSize} entradas x {mainPriceLabel} c/u
              </VTypography>
            )}
            {ticket.TicketTypeCollection.lemonCollectionId && (
              <VTypography variant="subtitle6">
                'Lemon Drop: Este acceso contiene un NFT'
              </VTypography>
            )}
          </Stack>

          <TicketQuantitySelector
            value={currentValue / ticketGroupSize}
            ticketId={`ticket-selector-${ticket.id}`}
            onSelect={selectTicketQuantity}
            canBeSelled={ticket.canBeSelled}
            maxQuantity={ticketQuantityToSell}
          />
        </Stack>
      </Stack>

      {hasImages && (
        <Stack maxWidth={230} height={230} width="100%">
          <Slider
            dots
            speed={500}
            slidesToScroll={1}
            slidesToShow={1}
            autoplay
            autoplaySpeed={2000}
            appendDots={(dots) => <ul style={{ bottom: -15 }}> {dots} </ul>}
          >
            {ticket.Images.map(({ url }) => (
              <img className={classes.ticketCardImage} src={url} key={url} alt={url} />
            ))}
          </Slider>
        </Stack>
      )}
    </Stack>
  );
};

export default withStyles(styles)(EventTicketsCard);
