import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import { Mail, Person } from '@material-ui/icons';
import { Stack } from '@mui/material';
import styles from './SendInvitationPage.styles';
import { icons } from '../../../Providers/Icons/IconsProvider';
import CustomInput from '../../../Components/CustomInput/CustomInput';
import InputTitle from '../../../Components/InputTitle/InputTitle';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { useFormDeprecated } from '../../../hooks/useFormDeprecated';
import { NOTIFICATION_VARIANT, USER_PERMISSIONS } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { validateUserRole } from '../../../utils/utils';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import api from '../../../api/api';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const MAX_QUANTITY = 20;
const NO_INVITATIONS_MESSAGE = 'No hay invitaciones disponibles';

const SendInvitationPage = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const { eventId } = urlParams;
  const { user } = useAuth();
  const {
    formState,
    handleInputChange,
    validateRequiredField,
    validateMail,
    validateNumber,
    validateText,
    resetForm,
  } = useFormDeprecated({
    firstName: '',
    lastName: '',
    mail: '',
    ticketQuantity: '',
    ticketType: '',
  });
  const [eventInvitations, setEventInvitations] = useState([]);
  const [amountAvailable, setAmountAvailable] = useState();
  const [userInvitation, setUserInvitation] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [reset, setReset] = useState(false);
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [loading, setLoading] = useState(true);
  const eventName = useRef('');

  const getEventInvitations = async () => {
    await handleRequestHelper({
      endpoint: () => api.getUserInvitationsByEvent(eventId),
      showNotification,
      onFailure: () => setLoading(false),
      onSuccess: ({ userInvitations }) => {
        const invitations = userInvitations.map(({ name, ...invitation }) => ({
          label: name,
          value: invitation,
        }));
        setEventInvitations(invitations);
      },
    });
    setLoading(false);
  };

  const getEventName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventName(eventId),
      onSuccess: ({ name }) => {
        eventName.current = name;
      },
    });
  };

  useEffect(() => {
    getEventName();
    resetForm();
    getEventInvitations();
    setUserInvitation(null);
    setAmountAvailable(0);
  }, [reset]);

  const getUserInvitation = async () => {
    const { amountAsigned, amountSent } = formState.ticketType;
    setAmountAvailable(amountAsigned - amountSent);
    setUserInvitation(formState.ticketType);
  };

  useEffect(() => {
    if (formState.ticketType) {
      getUserInvitation();
    }
  }, [formState.ticketType]);

  const validateInvitation = () => {
    return userInvitation && userInvitation.amountAsigned - userInvitation.amountSent > 0
      ? ''
      : 'No tienes invitaciones disponibles para este ticket';
  };

  const validateQuantity = () => {
    const { amountAsigned, amountSent } = userInvitation;
    return userInvitation && amountAsigned - amountSent - formState.ticketQuantity >= 0
      ? ''
      : 'No tienes suficientes invitaciones disponibles';
  };

  const validateMaxQuantity = () => {
    return formState.ticketQuantity <= MAX_QUANTITY
      ? ''
      : `Solo se pueden enviar hasta ${MAX_QUANTITY} invitaciones`;
  };

  const validateForm = () => {
    const errors = {
      firstName: validateText(formState.firstName),
      lastName: validateText(formState.lastName),
      mail: validateMail(formState.mail),
      ticketQuantity:
        validateNumber(formState.ticketQuantity) || validateQuantity() || validateMaxQuantity(),
      ticketType: validateRequiredField(formState.ticketType) || validateInvitation(),
    };
    setFormErrors((prevState) => ({ ...prevState, ...errors }));
    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError === '';
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      showNotification('Revisa los campos con errores', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    const dataToSend = {
      firstName: formState.firstName,
      lastName: formState.lastName,
      mail: formState.mail,
      ticketQuantity: parseInt(formState.ticketQuantity),
    };

    setSendingInvitation(true);

    await handleRequestHelper({
      endpoint: () =>
        validateUserRole(user.role, USER_PERMISSIONS.ADMIN)
          ? api.sendInvitationByAdmin(formState.ticketType.ticketTypeId, dataToSend)
          : api.sendInvitationBySeller(formState.ticketType.ticketTypeId, dataToSend),
      onSuccess: () => {
        showNotification('La invitación fue enviada correctamente', NOTIFICATION_VARIANT.SUCCESS);
        setReset((prevState) => !prevState);
      },
      onFailure: ({ errorMessage }) => {
        showNotification(
          `Error con el ticket seleccionado: ${errorMessage}`,
          NOTIFICATION_VARIANT.ERROR
        );
      },
    });
    setSendingInvitation(false);
  };

  return (
    <PageContainer title="Backoffice - Enviar Invitaciones">
      <VBreadcrumbs pageTitle="Enviar Invitaciones" />
      <Stack alignItems="center" gap={3} px={2}>
        <BackofficePageTitle mainTitle={eventName.current} secondaryTitle="Enviar invitaciones" />

        {!loading && (
          <>
            <Paper elevation={0} className={classes.paperContainer}>
              <div className={classes.sectionContainer}>
                <InputTitle title="Información del invitado" />
                <div className={classes.formContainer}>
                  <div className={classes.userInputs}>
                    <CustomInput
                      title="Nombre"
                      error={formErrors.firstName}
                      titleIcon={<Person />}
                      placeholder="Nombre"
                      type="text"
                      onChange={handleInputChange}
                      name="firstName"
                      value={formState.firstName}
                    />
                    <CustomInput
                      title="Apellido"
                      error={formErrors.lastName}
                      titleIcon={<Person />}
                      placeholder="Apellido"
                      type="text"
                      onChange={handleInputChange}
                      name="lastName"
                      value={formState.lastName}
                    />
                  </div>
                  <CustomInput
                    title="Correo electrónico"
                    error={formErrors.mail}
                    titleIcon={<Mail />}
                    placeholder="mail@mail.com"
                    type="email"
                    onChange={handleInputChange}
                    name="mail"
                    value={formState.mail}
                  />
                </div>
              </div>
              <div className={classes.sectionContainer}>
                <InputTitle title="Seleccioná la invitación y la cantidad a enviar" />
                <div className={classes.invitationAndQuantityInputs}>
                  <div className={classes.userInputs}>
                    <CustomInput
                      title="Invitación"
                      select
                      options={
                        eventInvitations.length
                          ? eventInvitations
                          : [{ label: NO_INVITATIONS_MESSAGE, value: NO_INVITATIONS_MESSAGE }]
                      }
                      onChange={handleInputChange}
                      titleIcon={<img alt="icon" src={icons.ticket} className={classes.icon} />}
                      name="ticketType"
                      value={
                        eventInvitations.length ? formState.ticketType : NO_INVITATIONS_MESSAGE
                      }
                      error={formErrors.ticketType}
                      fullWidth={false}
                    />
                    <CustomInput
                      title="Cantidad"
                      hint={
                        amountAvailable > 0
                          ? `${amountAvailable} invitaciones disponibles (Máx ${MAX_QUANTITY})`
                          : formState.ticketType
                          ? 'No tienes invitaciones disponibles para este ticket'
                          : ''
                      }
                      disabled={amountAvailable <= 0}
                      error={formErrors.ticketQuantity}
                      type="number"
                      titleIcon={<img alt="icon" src={icons.ticket} className={classes.icon} />}
                      onChange={handleInputChange}
                      name="ticketQuantity"
                      value={formState.ticketQuantity}
                    />
                  </div>
                </div>
              </div>
            </Paper>
            <div className={classes.button}>
              {sendingInvitation ? (
                <CircularProgress size={30} />
              ) : (
                <Button onClick={onSubmit} disabled={amountAvailable <= 0}>
                  Enviar
                </Button>
              )}
            </div>
          </>
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(SendInvitationPage);
