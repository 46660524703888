import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';

export const useCreateTicketPage = () => {
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const initialTicketData = useRef({});

  const getEventStadiumSections = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventStadium(eventId),
      onSuccess: ({ stadium }) => {
        initialTicketData.current = {
          ...initialTicketData.current,
          stadiumSections: stadium.sections,
        };
      },
    });
  };

  const getEventInfo = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        initialTicketData.current = {
          ...initialTicketData.current,
          eventId,
          shouldExtendStopOfSalesDateToInvitations: event.shouldExtendStopOfSalesDateToInvitations,
          eventStopOfSalesDate: event.defaultStopOfSalesDate,
          eventEndDate: event.endDate,
        };
      },
    });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    await getEventInfo();
    await getEventStadiumSections();
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return { isLoading, initialTicketData: initialTicketData.current };
};
