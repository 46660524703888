import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import priceProvider from '../../../Providers/Price/Price';
import styles from './ManageTicketTypes.styles';
import {
  TICKET_ACTIVITY_STATUS,
  TICKET_VISIBILITY_MODE,
  TICKET_TYPE,
  NOTIFICATION_VARIANT,
} from '../../../constants/types';
import TicketTypesTabs from '../../../Components/TicketTypesTabs/TicketTypesTabs';
import { copyToClipboard, getEventLink, sortObjectArray } from '../../../utils/utils';
import TicketTypesAccordion from '../../../Components/Accordions/TicketTypesAccordion/TicketTypesAccordion';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const ManageTicketTypes = ({ classes, urlParams }) => {
  const { user } = useAuth();
  const { showNotification } = useNotifications();

  const { eventId, producerId } = urlParams;
  const [ticketTypes, setTicketTypes] = useState([]);
  const [event, setEvent] = useState();
  const [defaultTickets, setDefaultTickets] = useState([]);
  const [invitationsTickets, setInvitationsTickets] = useState([]);
  const [tableTickets, setTableTickets] = useState([]);
  const [eventLink, setEventLink] = useState();

  const sections = [
    { label: 'Entradas', data: defaultTickets, index: '0' },
    { label: 'Invitaciones', data: invitationsTickets, index: '1' },
    { label: 'Accesos múltiples', data: tableTickets, index: '2' },
    { label: 'Ver todo', data: ticketTypes, index: '3' },
  ];

  const sectionWithoutViewAll = sections.slice(0, -1);

  const handleCopyTicketLink = (externalId) => {
    copyToClipboard(`${eventLink}?ticket=${externalId}`, showNotification, 'Enlace copiado!');
  };

  const getTicketTypes = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        setEvent(event);

        const tickets = event.TicketTypes.map((ticket) => ({
          ...ticket,
          price: priceProvider.getPrice(ticket.priceInCents, ticket.currency),
          image: ticket.Images.length > 0 && ticket.Images[0].url,
        })).filter(({ activityStatus }) => activityStatus !== TICKET_ACTIVITY_STATUS.ARCHIVED);

        setEventLink(getEventLink(event, user));
        setTicketTypes(sortObjectArray(tickets, 'name'));
      },
      showNotification,
    });
  };

  const handleDeleteTicket = async (ticketId) => {
    await handleRequestHelper({
      endpoint: () =>
        api.updateTicketType({
          id: ticketId,
          visibilityMode: TICKET_VISIBILITY_MODE.HIDDEN,
          activityStatus: TICKET_ACTIVITY_STATUS.ARCHIVED,
        }),
      onSuccess: () => setTicketTypes(ticketTypes.filter(({ id }) => id !== ticketId)),
      onFailure: () =>
        showNotification('Ocurrió un error al eliminar el ticket', NOTIFICATION_VARIANT.ERROR),
      showNotification,
    });
  };

  useEffect(() => {
    getTicketTypes();
  }, []);

  useEffect(() => {
    setDefaultTickets(ticketTypes.filter(({ type }) => type === TICKET_TYPE.PRESENTIAL));
    setInvitationsTickets(ticketTypes.filter(({ type }) => type === TICKET_TYPE.INVITATION));
    setTableTickets(ticketTypes.filter(({ type }) => type === TICKET_TYPE.TABLE));
  }, [ticketTypes]);

  return (
    <PageContainer title="Backoffice - Tickets">
      <VBreadcrumbs />
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <BackofficePageTitle mainTitle={event?.name} secondaryTitle="Tickets" />
          <Button
            className={classes.newTicketButton}
            href={`/backoffice/productoras/${producerId}/eventos/${eventId}/tickets/crear`}
          >
            Crear nuevo ticket
          </Button>
        </div>
        <div className={classes.desktopContainer}>
          <TicketTypesTabs
            tabs={sections}
            handleDeleteTicket={handleDeleteTicket}
            handleCopyTicketLink={handleCopyTicketLink}
          />
        </div>
        <div className={classes.mobileContainer}>
          <TicketTypesAccordion
            sections={sectionWithoutViewAll}
            handleDeleteTicket={handleDeleteTicket}
            handleCopyTicketLink={handleCopyTicketLink}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(ManageTicketTypes);
