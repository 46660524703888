import {
  TICKET_ACTIVITY_STATUS,
  TICKET_TYPE,
  TICKET_VISIBILITY_MODE,
} from '../../../constants/types';
import { translate } from '../../../utils/translate';

export const TICKET_VISIBILITY_MODE_OPTIONS = [
  { value: TICKET_VISIBILITY_MODE.VISIBLE, label: 'Visible' },
  { value: TICKET_VISIBILITY_MODE.HIDDEN, label: 'Oculto' },
];

export const TICKET_ACTIVITY_STATUS_OPTIONS = [
  { value: TICKET_ACTIVITY_STATUS.ACTIVE, label: 'Activo' },
  { value: TICKET_ACTIVITY_STATUS.INACTIVE, label: 'Inactivo' },
];

export const TICKET_TYPES_OPTIONS = Object.values(TICKET_TYPE).map((value) => ({
  value,
  label: translate(value),
}));

export const INVITATION_MAX_QUANTITY_PER_ORDER = 20;

export const TICKET_EMPTY_STATE = {
  name: '',
  priceInCents: 0,
  maxQuantity: 1,
  description: '',
  visibilityMode: TICKET_VISIBILITY_MODE.VISIBLE,
  activityStatus: TICKET_ACTIVITY_STATUS.ACTIVE,
  onlyOnline: true,
  type: TICKET_TYPE.PRESENTIAL,
  maxQuantityPerOrder: 1,
  groupSize: 1,
  stopOfSalesDate: '',
  stopOfAccessDate: '',
  isLemonExclusive: false,
  discountCodeData: {
    percentage: 0,
    code: '',
  },
  images: [],
  section: {},
};
