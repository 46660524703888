import { useEffect, useState } from 'react';

export const useEventsAccessesAccordion = (initialEventsAccesses) => {
  const [selectedProducersEvents, setSelectedProducersEvents] = useState(initialEventsAccesses);

  useEffect(() => {
    setSelectedProducersEvents(initialEventsAccesses);
  }, [initialEventsAccesses]);

  const modifySelectedProducersAllEventsState = (checked, producerId) => {
    setSelectedProducersEvents((prevState) => ({
      ...prevState,
      [producerId]: {
        ...prevState[producerId],
        allEvents: checked,
      },
    }));
  };

  const modifyAllSelectedProducerEventsStates = (checked, producerId) => {
    modifySelectedProducersAllEventsState(checked, producerId);

    Object.keys(selectedProducersEvents[producerId]).forEach((eventId) => {
      setSelectedProducersEvents((prevState) => ({
        ...prevState,
        [producerId]: {
          ...prevState[producerId],
          [eventId]: checked,
        },
      }));
    });
  };

  const modifySelectedProducerEventState = (checked, producerId, eventId) => {
    if (!checked) modifySelectedProducersAllEventsState(checked, producerId);
    setSelectedProducersEvents((prevState) => ({
      ...prevState,
      [producerId]: {
        ...prevState[producerId],
        [eventId]: checked,
      },
    }));
  };

  const getAmountOfSelectedEventsByProducerId = (producerId) => {
    const ALL_EVENTS_LABEL = 'Todos';
    if (!selectedProducersEvents[producerId]) return ALL_EVENTS_LABEL;

    const { allEvents, ...selectedEventsStates } = selectedProducersEvents[producerId];

    if (allEvents) return ALL_EVENTS_LABEL;

    return Object.values(selectedEventsStates).reduce(
      (amount, hasAccess) => (hasAccess ? amount + 1 : amount),
      0
    );
  };

  return {
    selectedProducersEvents,
    setSelectedProducersEvents,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    getAmountOfSelectedEventsByProducerId,
  };
};
