import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import EventPaymentMethodsAndPromotionsModal from '../../../../Components/EventPaymentMethodsAndPromotions/EventPaymentMethodsAndPromotionsModal/EventPaymentMethodsAndPromotionsModal';
import VIconButton from '../../../../Components/Buttons/IconButton/IconButton';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';
import Button from '../../../../Components/Buttons/Button/Button';
import ConfirmBottomSheet from '../../../../Components/BottomSheets/ConfirmBottomSheet/ConfirmBottomSheet';

const EventPromotionCard = ({ classes, eventPromotion, onDelete }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const theme = useTheme();

  const handleOpenPromotionModal = () => setOpenModal(true);
  const handleClosePromotionModal = () => setOpenModal(false);

  const handleCloseBottomSheet = () => setOpenBottomSheet(false);
  const handleOpenBottomSheet = () => setOpenBottomSheet(true);

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} className={classes.container}>
      <VTypography variant="subtitle3">Promoción #{eventPromotion.id}</VTypography>
      <Stack direction="row" gap={1}>
        <Button title="Ver promoción" size="small" onClick={handleOpenPromotionModal} />
        <VIconButton
          variant="outlined"
          Icon={<VIcon name="delete" color={theme.palette.error.main} />}
          color={theme.palette.error.main}
          onClick={handleOpenBottomSheet}
        />
      </Stack>
      <ConfirmBottomSheet
        open={openBottomSheet}
        onClose={handleCloseBottomSheet}
        onAccept={onDelete}
        title={`Eliminar promoción #${eventPromotion.id}`}
        subtitle="¿Estás seguro que deseas eliminar esta promoción?"
      />
      <EventPaymentMethodsAndPromotionsModal
        open={openModal}
        onClose={handleClosePromotionModal}
        eventPromotions={[eventPromotion]}
        title={`Promoción #${eventPromotion.id}`}
      />
    </Stack>
  );
};

export default withStyles(styles)(EventPromotionCard);
