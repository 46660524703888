import React from 'react';
import { Typography, useTheme, withStyles } from '@material-ui/core';
import styles from './AccessesReportCardStyles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import priceProvider from '../../../../Providers/Price/Price';

const AccessesReportCard = ({ classes, data }) => {
  const { name, totalSold, totalUsed, price, currency } = data;
  const theme = useTheme();

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInfo}>
        <div>
          <VTypography linesNumber={3} color={theme.palette.charts.text} variant="subtitle5">
            {name}
          </VTypography>
          <VTypography variant="body2" linesNumber={3} color={theme.palette.text.secondary}>
            {`${priceProvider.getPrice(price, currency)} ${currency}`}
          </VTypography>
        </div>
        <div className={classes.eventInfo}>
          <Typography>
            Ingresaron: {totalUsed}/{totalSold}
          </Typography>
        </div>
      </div>
      <div className={classes.amountContainer}>
        <div className={classes.totalSoldContainer}>
          <Typography className={classes.totalSoldValue}>{totalSold - totalUsed}</Typography>
          <Typography className={classes.totalSoldLabel}>Faltan ingresar</Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AccessesReportCard);
