import { createTheme } from '@material-ui/core/styles';

import darkTheme from '../darkTheme';

const breshTheme = createTheme(darkTheme, {
  palette: {
    primary: {
      main: '#DA0078',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    footer: {
      text: '#FFFFFF',
      background: '#000000',
    },
    header: {
      background: '#000000',
      buttonText: '#FFFFFF',
      buttonBackground: '#DA0078',
      subHeaderBackground: '#000000',
      text: '#FFFFFF',
    },
    buttons: {
      disabled: '#5C5C5C',
      disabledText: '#2A2A2A',
      main: {
        background: '#E44F9C',
        text: '#FFFFFF',
        hover: '#E44F9C',
      },
      alternative: {
        background: '#FFFFFF',
        text: '#000000',
        hover: '#CCCCCC',
      },
    },
    charts: {
      primary: '#E44F9C',
      secondary: '#FF69B4',
      text: '#FFFFFF',
    },
  },
});

export default breshTheme;
