import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';
import { handleRequestHelper } from '../../../utils/helpers';

export const useCreateEventPage = () => {
  const producerData = useRef({});
  const { producerId } = useParams();
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);

  const getProducerName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducer(producerId),
      onSuccess: ({ producer }) => {
        producerData.current.name = producer.name;
      },
      showNotification,
    });
  };

  const getProducerStadiums = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerStadiums(producerId),
      onSuccess: ({ stadiums }) => {
        producerData.current.stadiums = stadiums;
      },
      showNotification,
    });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    await getProducerStadiums();
    await getProducerName();
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return {
    isLoading,
    producerData: producerData.current,
  };
};
