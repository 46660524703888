import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { isValidMail } from '../../../utils/utils';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import { useEventsAccessesAccordion } from '../../../Components/Accordions/EventsAccessesAccordion/hook';
import { ERROR_CODE, ERROR_COLUMN, MAIL_COLUMN, TEAMLEADER_COLUMN } from './constants';
import { translate } from '../../../utils/translate';

export const useBulkCreateUsersPage = () => {
  const { producerId } = useParams();
  const [producer, setProducer] = useState();
  const [openCSVUploadModal, setOpenCSVUploadModal] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [role, setRole] = useState('seller');
  const [openEventsAccordion, setOpenEventsAccordion] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [validTeamLeaders, setValidTeamLeaders] = useState([]);
  const [showUsersErrorAlert, setShowUsersErrorAlert] = useState(false);

  const {
    selectedProducersEvents,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    getAmountOfSelectedEventsByProducerId,
  } = useEventsAccessesAccordion(selectedEvents);

  const { showNotification } = useNotifications();

  const getTeamLeaders = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerTeamLeaders(producerId),
      onSuccess: ({ teamLeaders }) => {
        setValidTeamLeaders(teamLeaders.map(({ code }) => code));
      },
    });
  };

  const setProducerData = async (producer) => {
    setProducer(producer);

    const tableColumns = [MAIL_COLUMN];

    if (producer?.generatesSellerReports) {
      tableColumns.push(TEAMLEADER_COLUMN);
      await getTeamLeaders();
    }

    setTableColumns(tableColumns);

    setEvents(producer?.events);
    const initialSelectedEvents = producer?.events.reduce(
      (initialSelectedEvents, { id }) => {
        initialSelectedEvents[id] = false;
        return initialSelectedEvents;
      },
      { allEvents: false }
    );

    setSelectedEvents({ [producerId]: initialSelectedEvents });
  };

  const getProducer = async () => {
    await handleRequestHelper({
      endpoint: () => api.getAllProducersWithEvents({ producerIds: [producerId] }),
      showNotification,
      onSuccess: async ({ producers }) => {
        await setProducerData(producers[0]);
      },
    });
  };

  useEffect(() => {
    getProducer();
  }, []);

  const handleUploadCSVFile = () => {
    setTableColumns((prevState) => prevState.filter(({ label }) => label !== ERROR_COLUMN.label));
    setUsersList([]);
    setOpenCSVUploadModal(true);
  };

  const handleCloseCSVUploadModal = () => {
    setOpenCSVUploadModal(false);
  };

  const handleUploadUsersByCSV = (file) => {
    let csvValid = true;
    let errorMessage = '';

    const mails = new Set();

    file.forEach(({ mail, teamLeader }, index) => {
      const fileRow = index + 2;

      if (!mail) {
        errorMessage = `Falta el mail del usuario en la fila N° ${fileRow}.`;
        csvValid = false;
      } else if (mail !== mail.trim()) {
        errorMessage = `El mail del usuario en la fila N° ${fileRow} contiene espacios vacíos.`;
        csvValid = false;
      } else if (!isValidMail(mail)) {
        errorMessage = `El mail del usuario en la fila N° ${fileRow} es invalido.`;
        csvValid = false;
      } else if (teamLeader && !validTeamLeaders.includes(teamLeader)) {
        errorMessage = `El team leader en la fila N° ${fileRow} no existe en esta productora.`;
        csvValid = false;
      }
      if (mails.has(mail)) {
        errorMessage = `El mail ${mail} del usuario en la fila N° ${fileRow} está repetido.`;
        csvValid = false;
      } else {
        mails.add(mail);
      }
    });

    if (!csvValid) {
      showNotification(errorMessage, NOTIFICATION_VARIANT.ERROR);
      return;
    }

    setUsersList(file);
  };

  const formatErrors = (errors) => {
    return errors.map(({ errorCode, userId, role, ...userData }) => {
      if (errorCode === ERROR_CODE.USER_ALREADY_EXISTS) {
        userData.error = (
          <>
            <span>Ya existe un usuario con este mail. </span>
            <a
              href={`/backoffice/productoras/${producerId}/usuarios/${userId}`}
              target="_blank"
              rel="noreferrer"
            >
              Hacé click
            </a>{' '}
            <span> para ir a modificar sus permisos.</span>
          </>
        );
      }
      if (errorCode === ERROR_CODE.USER_ALREADY_EXISTS_WITH_OTHER_ROLE) {
        userData.error += `. Tiene el rol ${translate(role)} ya asignado en otra productora.`;
      }
      return userData;
    });
  };

  const handleBulkCreateUsers = async () => {
    setIsCreating(true);
    const { allEvents, ...eventsAccess } = selectedProducersEvents[producerId];
    const eventsExcluded = Object.entries(eventsAccess).reduce(
      (eventsIds, [eventId, hasAccess]) =>
        hasAccess ? [...eventsIds, Number(eventId)] : eventsIds,
      []
    );

    const body = {
      users: usersList,
      role,
      producerId,
      eventsExcluded,
      hasRestrictedAccessToEvents: !allEvents,
    };
    await handleRequestHelper({
      endpoint: () => api.bulkCreateUsers(body),
      onSuccess: ({ errors }) => {
        if (errors.length) {
          showNotification('Algunos usuarios no pudieron ser creados', NOTIFICATION_VARIANT.INFO);
          setShowUsersErrorAlert(true);
          setTableColumns((prevState) => {
            prevState.push(ERROR_COLUMN);
            return prevState;
          });
        } else {
          showNotification('Usuarios creados correctamente', NOTIFICATION_VARIANT.SUCCESS);
        }

        setUsersList(formatErrors(errors));
      },
      showNotification,
    });
    setIsCreating(false);
  };

  const handleRoleSelectorChange = (event) => setRole(event.target.value);

  const handleEventsAccordionChange = () => {
    setOpenEventsAccordion((prevState) => !prevState);
  };

  const hasErrors = usersList.some(({ error }) => error);

  const isCreationAvailable =
    selectedProducersEvents[producerId] &&
    Object.values(selectedProducersEvents[producerId]).includes(true) &&
    usersList.length > 0 &&
    !hasErrors;

  return {
    producer,
    openCSVUploadModal,
    handleUploadCSVFile,
    handleCloseCSVUploadModal,
    handleUploadUsersByCSV,
    tableColumns,
    usersList,
    isCreating,
    handleBulkCreateUsers,
    role,
    handleRoleSelectorChange,
    events,
    openEventsAccordion,
    handleEventsAccordionChange,
    isCreationAvailable,
    selectedProducersEvents,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    getAmountOfSelectedEventsByProducerId,
    showUsersErrorAlert,
    hasErrors,
  };
};
