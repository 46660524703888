import PriceProvider from '../../../Providers/Price/Price';
import { CURRENCY, TICKET_VISIBILITY_MODE } from '../../../constants/types';
import { formatPrice } from '../../../utils/utils';

export const useEventTicketCard = (ticket, addToCart) => {
  const {
    Images: images,
    priceInCents,
    priceInCentsUSD,
    priceInCentsWithLemon,
    groupSize,
    canBeSelled,
    visibilityMode,
    soldUnits,
    maxQuantityPerOrder,
    maxQuantity,
    currency,
    ticketTypeSection,
  } = ticket;

  const section = ticketTypeSection && ticketTypeSection[0];
  const stock = Math.max(maxQuantity - soldUnits, 0);
  const hasImages = images.length > 0;
  const ticketGroupSize = groupSize || 1;
  const priceInARS = PriceProvider.getPrice(priceInCents, currency);
  const priceInUSD = PriceProvider.getPrice(priceInCentsUSD, currency);
  const priceWithLemon = PriceProvider.getPrice(priceInCentsWithLemon, currency);
  const lemonDiscount = Math.floor(100 - (priceWithLemon * 100) / priceInARS);
  const hasDiscountWithLemon = priceInARS !== priceWithLemon;
  const ticketQuantityToSell = Math.floor(Math.min(stock, maxQuantityPerOrder) / ticketGroupSize);

  const getPriceLabel = (price) => {
    return currency === CURRENCY.USD ? formatPrice(price, true, false, 'USD') : formatPrice(price);
  };

  const mainPrice = currency === CURRENCY.USD ? priceInUSD : priceInARS;
  const mainPriceLabel = getPriceLabel(mainPrice);
  const multipleAccessPriceLabel = getPriceLabel(mainPrice * ticketGroupSize);

  const selectTicketQuantity = ({ target }) => {
    const { value } = target;
    addToCart({
      ...ticket,
      quantity: value * ticketGroupSize,
      photoURL: images[0]?.url,
      price: priceInARS,
      priceInUSD,
      priceWithLemon,
    });
  };

  const isAvailableLabel =
    canBeSelled && visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE
      ? 'Entrada disponible'
      : 'Entrada NO disponible';

  return {
    hasImages,
    section,
    isAvailableLabel,
    multipleAccessPriceLabel,
    hasDiscountWithLemon,
    lemonDiscount,
    ticketGroupSize,
    mainPriceLabel,
    ticketQuantityToSell,
    selectTicketQuantity,
  };
};
