import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      padding: '40px 16px 32px',
      gap: 32,
    },
    producerLink: {
      textDecoration: 'underline',
      color: 'inherit',
    },
  });

export default styles;
