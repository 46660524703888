import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import styles from './ActiveEventsTotalVolume.styles';
import ChartLayout from '../ChartLayout';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useActiveEventsTotalVolume } from './hook';

const ActiveEventsTotalVolume = ({ classes, producerId }) => {
  const { totalVolume } = useActiveEventsTotalVolume(producerId);

  return (
    <ChartLayout title="Volumen total" padding={0} height="auto">
      {totalVolume && (
        <Stack gap={1} alignItems="center">
          <Typography className={classes.volumeText}>{totalVolume}</Typography>
          <VTypography variant="body1" align="center">
            Para todos los eventos activos
          </VTypography>
        </Stack>
      )}
    </ChartLayout>
  );
};

export default withStyles(styles)(ActiveEventsTotalVolume);
