export const getBreadcrumbRoute = (producerId, eventId) => [
  {
    show: eventId && producerId,
    key: 'eventos',
    link: `/backoffice/productoras/${producerId}/eventos/${eventId}`,
    text: `Evento ${eventId}`,
  },
  {
    show: eventId && producerId,
    key: 'tickets',
    link: `/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`,
    text: 'Tickets',
  },
  {
    show: producerId,
    key: 'usuarios',
    link: `/backoffice/productoras/${producerId}/usuarios`,
    text: 'Usuarios',
  },
  {
    show: producerId,
    key: 'teamLeaders',
    link: `/backoffice/productoras/${producerId}/teamLeaders`,
    text: 'Team Leaders',
  },
  {
    show: producerId && eventId,
    key: 'ordenes',
    link: `/backoffice/productoras/${producerId}/eventos/${eventId}/ordenes`,
    text: 'Lista de órdenes',
  },
  {
    show: producerId && eventId,
    key: 'promociones',
    link: `/backoffice/productoras/${producerId}/eventos/${eventId}/promociones`,
    text: 'Promociones',
  },
];
