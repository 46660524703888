import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    stadiumSectionInfoContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 16,
      borderRadius: 8,
    },
    imageContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    sectionImage: {
      maxWidth: 300,
      width: '100%',
      borderRadius: 8,
    },
  });

export default styles;
