import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import { useCreateTicketPage } from './hook';
import TicketForm from '../../../Components/Forms/TicketForm/TicketForm';
import { FORM_MODE } from '../../../Components/Forms/EventForm/constants';

const CreateTicketPage = () => {
  const { isLoading, initialTicketData } = useCreateTicketPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Crear Ticket">
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Crear Ticket">
      <VBreadcrumbs pageTitle="Crear Ticket" />
      <Stack alignItems="center" gap={3} px={2}>
        <VTypography variant="h3">Crear Ticket</VTypography>
        <TicketForm
          initialTicketData={initialTicketData}
          buttonLabel="Crear"
          mode={FORM_MODE.CREATE}
        />
      </Stack>
    </PageContainer>
  );
};

export default CreateTicketPage;
