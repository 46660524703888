import { USER_ROLES } from './types';

export const URUGUAY_OPTION = 'Uruguay';
export const BRAZIL_OPTION = 'Brasil';
export const OUT_OF_ARGENTINA_OPTION = 'Otros paises';

export const ARGENTINA_PROVINCES = [
  { label: 'Argentina', isCategory: true },
  { label: 'Buenos Aires' },
  { label: 'Ciudad Autónoma de Buenos Aires' },
  { label: 'Catamarca' },
  { label: 'Chaco' },
  { label: 'Chubut' },
  { label: 'Córdoba' },
  { label: 'Corrientes' },
  { label: 'Entre Ríos' },
  { label: 'Formosa' },
  { label: 'Jujuy' },
  { label: 'La Pampa' },
  { label: 'La Rioja' },
  { label: 'Mendoza' },
  { label: 'Misiones' },
  { label: 'Neuquén' },
  { label: 'Río Negro' },
  { label: 'Salta' },
  { label: 'San Juan' },
  { label: 'San Luis' },
  { label: 'Santa Cruz' },
  { label: 'Santa Fe' },
  { label: 'Santiago del Estero' },
  { label: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur' },
  { label: 'Tucumán' },
  { label: 'Fuera de Argentina', isCategory: true },
  { label: URUGUAY_OPTION },
  { label: BRAZIL_OPTION },
  { label: OUT_OF_ARGENTINA_OPTION },
];

export const REGULAR_EXPRESSION = {
  PASSWORD_LENGHT_VALIDATION: /^.{8,}$/,
  PASSWORD_VALIDATION:
    /^(?=.*\d.*\d)(?=.*[#?!@$%^&*|°…^`~«≠ºª•¶§∞¢£“¡¬≤≥<>.¿'¡?}{+´\][()/"\\-_])(?=.*[a-z])(?=.*[A-Z]).*$/,
  TEXT_AND_NUMBERS_VALIDATION:
    /^[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\s*[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$/,
  TEXT_VALIDATION:
    /^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\s*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$/,
  NUMBER_VALIDATION: /^[0-9]+$/,
  FLOAT: /^[0-9]+([.,][0-9]+)?$/,
  ALIAS_VALIDATION: /^[a-zA-Z0-9_-]+$/,
  DNI_VALIDATION: /^\d{7,8}$/,
  CUIL_VALIDATION: /^\d{11}$/,
  CDI_VALIDATION: /^\d{11}$/,
  CI_VALIDATION: /^\d{8}$/,
  PASSPORT_VALIDATION: /^[a-zA-Z0-9]+$/,
  LC_VALIDATION: /^\d{7}$/,
  LE_VALIDATION: /^\d{7}$/,
  DATE_VALIDATION: /^[0-9/]+$/,
  DATE_INFO_SPLITTER: /([^\s,]+),\s+(\d{2})\s+([^\s,]+)\s+(\d{4}),\s+(\d{2}:\d{2})/,
  EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
};

export const BLACKLISTABLE_ROLES = [
  USER_ROLES.SELLER,
  USER_ROLES.SELLER_WITH_CASH,
  USER_ROLES.READ_ONLY_ADMIN,
  USER_ROLES.VALIDATOR,
];

export const TERMS_BY_COUNTRY = {
  AR: 'https://legals.venti.live/documentacion/terminosycondiciones',
  UY: 'https://legals.venti.live/documentacion/tc-global',
};

export const POLICIES_BY_COUNTRY = {
  AR: 'https://legals.venti.live/documentacion/politicas-de-privacidad',
  UY: 'https://legals.venti.live/documentacion/privacy-global',
};

export const FLAGS = {
  VENUES_ENABLED: 'VENUES_ENABLED',
  CASH_SALE_ENABLED: 'CASH_SALE_ENABLED',
  SELF_MANAGED_REFUND_ENABLED: 'SELF_MANAGED_REFUND_ENABLED',
};

export const LOCAL_STORAGE_VARIABLES = {
  JWT: 'jwt',
  STORED_CART: 'storedCart',
  STORED_MERCADO_PAGO_KEY: 'storedMercadoPagoKey',
  INTERCOM_HASH: 'intercomHash',
};

export const IMAGES_CDN_BY_ENV = {
  local: 'https://images.pre.venti.com.ar',
  staging: 'https://images.pre.venti.com.ar',
  production: 'https://images.venti.live',
};
