import { useState } from 'react';
import { LOCAL_STORAGE_VARIABLES } from '../../../constants/constants';

export const useCart = () => {
  const getStoredCart = () => {
    const storedCart = localStorage.getItem(LOCAL_STORAGE_VARIABLES.STORED_CART);
    if (storedCart) return JSON.parse(storedCart);
    return {};
  };

  const [cart, setCart] = useState(getStoredCart());

  const updateStoredCart = (newCart) => {
    localStorage.setItem(LOCAL_STORAGE_VARIABLES.STORED_CART, JSON.stringify(newCart));
    setCart(newCart);
  };

  const deleteCart = () => {
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.STORED_CART);
    setCart({});
  };

  const updateTicketCart = (ticket) => {
    const newCart = { ...cart, [ticket.id]: ticket };
    updateStoredCart(newCart);
  };

  const removeTicketFromCart = (ticketId) => {
    const { [ticketId]: _, ...newCart } = cart;
    updateStoredCart(newCart);
  };

  return {
    updateTicketCart,
    getStoredCart,
    removeTicketFromCart,
    deleteCart,
    cart,
  };
};
