import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    helper: {
      color: 'red',
      height: 15,
      display: 'inline',
      float: 'left',
    },
    errorRoot: {
      backgroundColor: 'transparent',
      border: '1px solid red',
    },
  });

export default styles;
