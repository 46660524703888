import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import styles from './styles';

const VDateTimePicker = forwardRef(({ classes, showTimeSelect, ...props }, ref) => {
  return (
    <DatePicker
      ref={ref}
      showTimeSelect={showTimeSelect}
      calendarClassName={`${classes.datePicker} ${showTimeSelect ? classes.timePicker : ''}`}
      minDate={new Date()}
      locale="es"
      showDisabledMonthNavigation
      {...props}
    />
  );
});

VDateTimePicker.displayName = 'VDateTimePicker';

export default withStyles(styles)(VDateTimePicker);
