import { useCallback, useEffect, useMemo, useState } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';
import api from '../../../../api/api';
import { MercadoPagoPaymentMethod } from './PaymentMethods/MercadoPagoPaymentMethod';
import { LinePaymentMethod } from './PaymentMethods/LinePaymentMethod';
import { useForm } from '../../../../hooks/useForm';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../../constants/types';
import { getOrderResume } from './checkoutUtils';
import { formatDate } from '../../../../utils/utils';
import { getCardType } from './creditCardUtils';
import { useCreditCard } from '../../../../hooks/useCreditCard';
import { useReCaptcha } from '../../../../hooks/useReCaptcha';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import history from '../../../../appHistory';
import { useCart } from '../../EventPage/cart';

export const useCheckout = (externalId) => {
  const { showNotification } = useNotifications();
  const [order, setOrder] = useState();
  const [installmentsOptions, setInstallmentsOptions] = useState([]);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [remainingExpireTime, setRemainingExpireTime] = useState();
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [paying, setPaying] = useState(false);
  const [canPay, setCanPay] = useState(false);
  const [openTimeoutModal, setOpenTimeoutModal] = useState(false);
  const [availableCards, setAvailableCards] = useState([]);
  const [eventPromotions, setEventPromotions] = useState([]);
  const { buttonActionDisabled, validateRecaptchaToken } = useReCaptcha({
    history,
    page: 'checkout',
    action: 'ticket_buy',
  });
  const { deleteCart } = useCart();

  const checkoutForm = useForm({
    cardNumber: {
      title: 'Número de tarjeta',
      type: INPUT_TYPE.NUMBER,
    },
    cardName: {
      title: 'Nombre en la tarjeta',
      type: INPUT_TYPE.ALPHA,
    },
    cardExpirationDate: {
      title: 'Fecha de expiración (MM/AA)',
      type: INPUT_TYPE.CREDIT_CARD_EXPIRATION_DATE,
    },
    cvc: {
      title: 'Código de seguridad (CVV)',
      type: INPUT_TYPE.NUMBER,
    },
    idType: {
      title: 'Tipo',
      type: INPUT_TYPE.ID_TYPE,
      value: 'DNI',
    },
    idValue: {
      title: 'Número de documento',
      type: INPUT_TYPE.ID_NUMBER,
    },
    installments: {
      title: 'Cuotas',
      required: false,
      type: INPUT_TYPE.NUMBER,
    },
  });

  const cardTypeData = useMemo(
    () => getCardType(checkoutForm.formState.cardNumber.value),
    [checkoutForm.formState.cardNumber.value]
  );

  const getPaymentMethod = (order) => {
    switch (order.paymentMethod) {
      case 'mercadopago':
        return new MercadoPagoPaymentMethod(order, showNotification);
      case 'line':
        return new LinePaymentMethod(order, showNotification);
      default:
        console.error('Payment method not found');
    }
  };

  const getOrder = useCallback(async () => {
    await handleRequestHelper({
      endpoint: () => api.getOrderByExternalId(externalId),
      onSuccess: ({ order }) => {
        const { Payments, Qrs, Event, ...orderData } = order;
        let formattedOrder = {
          event: {
            id: Event.id,
            title: Event.name,
            startDate: formatDate(Event.startDate),
            urlName: Event.urlName,
          },
          payments: Payments,
          qrs: Qrs,
          currency: Payments[0].currency,
          paymentMethod: order.Payments[0].method,
          isLemonExclusive: Qrs.some(({ TicketType }) => TicketType.isLemonExclusive),
          ...orderData,
        };

        const resume = getOrderResume(formattedOrder);

        formattedOrder = { ...formattedOrder, resume };
        setOrder(formattedOrder);
        setRemainingExpireTime(differenceInSeconds(parseISO(order.expiryDate), new Date()));
      },
    });

    setLoadingInitialData(false);
  }, [externalId]);

  const paymentMethod = useMemo(() => order && getPaymentMethod(order), [order]);

  const getAvailableCards = async () => {
    await paymentMethod.getAvailableCards();
    setAvailableCards(paymentMethod.availableCards);
  };

  useEffect(() => {
    if (paymentMethod) getAvailableCards();
  }, [paymentMethod]);

  const getIdentificationTypes = useCallback(async () => {
    if (!paymentMethod) return;

    const identificationTypes = await paymentMethod.getIdentificationTypes();
    setIdentificationTypes(identificationTypes);
  }, [paymentMethod]);

  const updateFormData = useCallback(async () => {
    if (!paymentMethod) return;

    const cardNumber = checkoutForm.formState.cardNumber.value.replace(/\D/g, '');
    const { cardNumberIsComplete, cardNumberError } = await paymentMethod.validateCardNumber(
      cardNumber
    );

    if (cardNumberIsComplete && !cardNumberError) {
      const installments = await paymentMethod.getInstallments(cardNumber);
      setInstallmentsOptions(installments);
      setShowForm(true);
    } else {
      setShowForm(false);
    }

    checkoutForm.setInputError('cardNumber', cardNumberError);
  }, [checkoutForm.formState.cardNumber.value]);

  const payOrder = async () => {
    if (paying) return;
    setPaying(true);
    const isCaptchaValid = await validateRecaptchaToken();

    if (!isCaptchaValid) {
      setPaying(false);
      return;
    }

    const isCreditCardFormValid = checkoutForm.validateFormInputs();

    if (!isCreditCardFormValid) {
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      setPaying(false);
      return;
    }

    await paymentMethod.processPayment({
      data: checkoutForm.formState,
      onFailure: () => setPaying(false),
      onSuccess: () => {
        showNotification('El pago se realizó con éxito!', NOTIFICATION_VARIANT.SUCCESS);
        setTimeout(() => {
          history.replace(
            `/evento/${order.event.urlName}/resultado-operacion?code=200&externalId=${externalId}`
          );
        }, 1500);
      },
    });
  };

  const creditCardFormState = useCreditCard({
    cardNumber: checkoutForm.formState.cardNumber.value,
  });

  const handleCloseTimeoutModal = () => {
    setOpenTimeoutModal(false);
  };

  const onTimerFinished = () => {
    setOpenTimeoutModal(true);
  };

  const handleBackToEvent = () => {
    deleteCart();
    const eventUrl = window.location.pathname.split('/checkout')[0];
    history.replace(eventUrl);
  };

  const getEventPromotions = async () => {
    if (!order) return;
    const { event } = order;

    await handleRequestHelper({
      endpoint: () => api.getEventPromotions(event.id),
      onSuccess: ({ eventPromotions }) => {
        setEventPromotions(eventPromotions);
      },
    });
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  useEffect(() => {
    getEventPromotions();
  }, [order]);

  useEffect(() => {
    getIdentificationTypes();
  }, [getIdentificationTypes]);

  useEffect(() => updateFormData(), [updateFormData]);
  useEffect(() => setCanPay(checkoutForm.isFormComplete()), [checkoutForm.isFormComplete]);

  return {
    cardTypeData,
    remainingExpireTime,
    order,
    showForm,
    installmentsOptions,
    identificationTypes,
    loadingInitialData,
    canPay,
    paying,
    availableCards,
    openTimeoutModal,
    eventPromotions,
    buttonActionDisabled,
    validateRecaptchaToken,
    payOrder,
    handleCloseTimeoutModal,
    onTimerFinished,
    handleBackToEvent,
    checkoutForm: {
      ...creditCardFormState,
      ...checkoutForm,
    },
  };
};
