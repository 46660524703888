import React, { forwardRef, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { INPUT_TYPE, MATERIAL_UI_ICONS } from '../../../constants/types';
import VTypography from '../../VTypography/VTypography';
import VIcon from '../../VIcon/VIcon';
import FormItemHeader from '../../../Components/FormItemHeader/FormItemHeader';
import styles from './styles';

const VTextInput = forwardRef(
  (
    {
      classes,
      title,
      errorMessage,
      successMessage,
      leftIcon,
      type,
      infoMessage,
      onChange,
      onFocus,
      mask,
      upperCase = false,
      fullWidth = true,
      hint,
      flex,
      defaultValue,
      maxLength,
      maskChar = '‎',
      required = false,
      disabled = false,
      startAdornment,
      endAdornment,
      description,
      readOnly = false,
      showHelpText = true,
      value,
      ...props
    },
    ref
  ) => {
    const isPasswordInput = type === INPUT_TYPE.PASSWORD;
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const inputType = useMemo(() => {
      return type === INPUT_TYPE.PASSWORD && showPassword ? INPUT_TYPE.TEXT : type;
    }, [type, showPassword]);

    const handleFocus = (e) => {
      if (onFocus) onFocus(e);
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <Stack flex={flex} width={fullWidth ? '100%' : 240}>
        <FormItemHeader
          title={title}
          required={required}
          description={description}
          leftIcon={leftIcon}
        />
        <InputMask
          mask={mask}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maskChar={maskChar}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {(inputProps) => (
            <TextField
              inputRef={ref}
              defaultValue={defaultValue}
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              className={`
              ${classes.textInputContainer}
              ${errorMessage && classes.error}
              ${disabled && classes.disabled} 
              ${successMessage && classes.success}`}
              sx={{ ...(upperCase && { textTransform: 'uppercase' }) }}
              disabled={disabled}
              InputProps={{
                inputProps: { maxLength, ...inputProps },
                startAdornment,
                readOnly,
                value,
                endAdornment:
                  endAdornment ||
                  (isPasswordInput && (
                    <IconButton
                      className={classes.rightIcon}
                      disabled={disabled}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <VIcon
                        baseClassName={MATERIAL_UI_ICONS.DEFAULT}
                        name={showPassword ? 'visibility' : 'visibility_off'}
                      />
                    </IconButton>
                  )),
              }}
              type={inputType}
              {...props}
            />
          )}
        </InputMask>

        {showHelpText && (
          <Box className={classes.bottomInputBox}>
            <VTypography
              variant="caption"
              color={(errorMessage && 'error') || (successMessage && 'success')}
            >
              {errorMessage || successMessage || (isFocused && hint) || infoMessage}
            </VTypography>
          </Box>
        )}
      </Stack>
    );
  }
);

VTextInput.displayName = 'VTextInput';

export default withStyles(styles)(VTextInput);
