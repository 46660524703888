export const SCAN_STATE = {
  VALIDATE: 'validate',
  BURN: 'burn',
};

export const SCAN_OPTION = {
  ALL: 'all',
  SCAN_APP: 'scan-app',
  TURNSTILE_GATE: 'turnstile-gate',
};
