import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const RevertExpiredOrderBottomSheet = ({ onClose, open, orderId, getOrder }) => {
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaidWithLemon, setIsPaidWithLemon] = useState('false');
  const [paymentId, setPaymentId] = useState('');

  const isPaidWithLemonOptions = [
    { label: 'Con descuento', value: 'true' },
    { label: 'Sin descuento', value: 'false' },
  ];

  const handleClose = () => {
    onClose();
  };

  const handleRefund = async () => {
    setIsLoading(true);

    await handleRequestHelper({
      endpoint: () =>
        api.revertNonPaidOrder({ orderId, paymentId, isPaidWithLemon: isPaidWithLemon === 'true' }),
      showNotification,
      onSuccess: () => {
        getOrder();
        showNotification(
          'La orden se marcó como pagada correctamente',
          NOTIFICATION_VARIANT.SUCCESS
        );
      },
    });
    handleClose();
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={handleClose}>
      <Stack padding={2} gap={2.5}>
        <Stack gap={1}>
          <VTypography variant="h4">Marcar orden como pagada</VTypography>
          <VTypography variant="body2">
            Esta orden se venció antes que el usuario pueda completar el pago. Si el pago llegó
            efectivamente, podes marcarla como paga y se envíara el mail con los QRs
            correspondientes
          </VTypography>
        </Stack>
        <VSelectInput
          title="Pago con descuento"
          onChange={({ target }) => setIsPaidWithLemon(target.value)}
          name="discount"
          options={isPaidWithLemonOptions}
          value={isPaidWithLemon}
        />
        <VTextInput
          title="ID de pago"
          autoComplete="off"
          onChange={({ target }) => setPaymentId(target.value)}
          name="paymentId"
          value={paymentId}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button size="small" fullwidth variant="outlined" title="Volver" onClick={handleClose} />
          <Button
            size="small"
            fullwidth
            title="Continuar"
            onClick={handleRefund}
            loading={isLoading}
            disabled={!paymentId}
          />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default RevertExpiredOrderBottomSheet;
