import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    datePicker: {
      background: 'white',
      border: 0,
      padding: 16,
      fontSize: 16,
      fontFamily: 'Work Sans',
      height: 400,

      '& .react-datepicker': {
        '&__year-dropdown-container': {
          margin: '8px 0',
          width: 80,
          alignItems: 'center',
        },

        '&__year-read-view--down-arrow': {
          top: 3,
        },

        '&__header': {
          backgroundColor: 'transparent',
          border: 0,
          padding: 0,
        },

        '&__navigation': {
          '&-icon:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
          },

          '&--next': {
            right: 5,
            top: 15,
          },

          '&--previous': {
            left: 5,
            top: 15,
          },
        },

        '&__day': {
          borderRadius: 4,
          width: '100%',
          padding: '5px 0',
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '&--keyboard-selected': {
            backgroundColor: 'transparent',
          },

          '&-name': {
            margin: 0,
            fontWeight: 500,
            textTransform: 'uppercase',
            fontSize: 12,
            marginTop: 8,
            marginBottom: -8,
            width: '100%',
          },

          '&-names': {
            display: 'flex',
            margin: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          },

          '&--disabled': {
            opacity: 0.7,
          },

          '&--today': {
            color: theme.palette.primary.main,
            fontWeight: 700,
          },

          '&--outside-month': {
            opacity: 0.5,
          },

          '&--in-range, &--in-selecting-range': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 0,
            opacity: 1,
            fontWeight: 700,
          },

          '&--selected': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
            opacity: 1,
            fontWeight: 700,
          },

          '&--today.react-datepicker__day--selected': {
            color: 'white',
          },

          '&--range-start, &--selecting-range-start': {
            borderRadius: '16px 0 0 16px',
            backgroundColor: theme.palette.primary.main,
          },

          '&--range-end, &--selecting-range-end': {
            color: 'white',
            borderRadius: '0 16px 16px 0',
            backgroundColor: theme.palette.primary.main,
          },

          '&--range-start.react-datepicker__day--range-end': {
            borderRadius: 8,
          },
        },

        '&__week': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5px 0',
        },
        '&__month': {
          margin: 0,

          '&-container': {
            width: '100%',
          },
        },
      },
    },
    timePicker: {
      height: 'unset',
      padding: '8px 0 0',
    },
  });

export default styles;
