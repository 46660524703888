import React from 'react';
import { Stack } from '@mui/material';
import VFormCard from '../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import VTextArea from '../../../Venti-UI-Kit/VInputs/VTextArea/VTextArea';
import { useTicketForm } from './hook';
import { INPUT_TYPE } from '../../../constants/types';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';
import VDateTimeInput from '../../../Venti-UI-Kit/VInputs/VDateTimeInput/VDateTimeInput';
import CheckBox from '../../../Venti-UI-Kit/CheckBox/CheckBox';
import VImageUploader from '../../../Venti-UI-Kit/VImageUploader/VImageUploader';
import {
  TICKET_ACTIVITY_STATUS_OPTIONS,
  TICKET_TYPES_OPTIONS,
  TICKET_VISIBILITY_MODE_OPTIONS,
} from './constants';
import StadiumSectionInfo from '../../Backoffice/StadiumSectionInfo/StadiumSectionInfo';
import Button from '../../Buttons/Button/Button';
import { FORM_MODE } from '../EventForm/constants';

const TicketForm = ({ initialTicketData, buttonLabel, mode }) => {
  const {
    stadiumSectionsOptions,
    formState,
    isAccessTimeRestricted,
    isSuperAdmin,
    ticketSection,
    isTableType,
    isInviationType,
    showCashSaleCheckbox,
    toggleAccessTimeRestriction,
    handleTypeChange,
    handleSectionChange,
    handleInputChange,
    handleSubmit,
    skipAllTickets,
    handleNavigation,
    isLoading,
  } = useTicketForm(initialTicketData, mode);

  return (
    <Stack gap={2} alignItems="center" maxWidth={990} width="100%">
      <VFormCard label="Información general" icon="create">
        <VTextInput
          name="name"
          title="Nombre"
          required
          onChange={handleInputChange}
          value={formState.name.value}
          errorMessage={formState.name.error}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <VSelectInput
            name="type"
            title="Tipo de entrada"
            required
            value={formState.type.value}
            errorMessage={formState.type.error}
            options={TICKET_TYPES_OPTIONS}
            onChange={handleTypeChange}
            disabled={mode === FORM_MODE.EDIT}
          />
          <VTextInput
            name="maxQuantity"
            title="Stock"
            type={INPUT_TYPE.NUMBER}
            required
            onChange={handleInputChange}
            value={formState.maxQuantity.value}
            errorMessage={formState.maxQuantity.error}
          />
        </Stack>
        {isTableType && (
          <VTextInput
            name="groupSize"
            title="Cantidad de accesos por ticket"
            type={INPUT_TYPE.NUMBER}
            required
            onChange={handleInputChange}
            value={formState.groupSize.value}
            errorMessage={formState.groupSize.error}
          />
        )}
        {!isInviationType && (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <VTextInput
              name="price"
              title="Precio"
              required
              type={INPUT_TYPE.NUMBER}
              onChange={handleInputChange}
              value={formState.price.value}
              errorMessage={formState.price.error}
              disabled={mode === FORM_MODE.EDIT}
            />
            <VTextInput
              name="maxQuantityPerOrder"
              title="Cantidad máxima por orden"
              type={INPUT_TYPE.NUMBER}
              required
              onChange={handleInputChange}
              value={formState.maxQuantityPerOrder.value}
              errorMessage={formState.maxQuantityPerOrder.error}
              infoMessage={
                isTableType &&
                `La cantidad máxima de QRs que se van a recibir por compra es de: ${
                  formState.groupSize.value * formState.maxQuantityPerOrder.value
                }`
              }
            />
          </Stack>
        )}
        <Stack mb={2} gap={1}>
          {initialTicketData.stadiumSections?.length > 0 && (
            <VSelectInput
              name="section"
              title="Sector"
              required
              value={formState.section.value}
              errorMessage={formState.section.error}
              options={stadiumSectionsOptions}
              onChange={handleSectionChange}
              disabled={mode === FORM_MODE.EDIT}
            />
          )}
          {formState.section.value && <StadiumSectionInfo section={ticketSection} />}
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <VImageUploader
            title="Imagen del ticket"
            width={230}
            name="image"
            imageHeight={230}
            imageWidth={230}
            initialImageSrc={formState.image.value}
            onChange={handleInputChange}
            canDelete
          />
          <VTextArea
            name="description"
            title="Descripción"
            minRows={9}
            onChange={handleInputChange}
            value={formState.description.value}
            errorMessage={formState.description.error}
          />
        </Stack>
      </VFormCard>
      <Stack width="100%" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <VFormCard label="Venta del ticket" icon="settings">
          {!isInviationType && (
            <VDateTimeInput
              showYearDropdown
              title="Finalización de la venta"
              description="Elegí hasta que fecha y hora se puede vender este ticket."
              name="stopOfSalesDate"
              titleVariant="subtitle4"
              showTimeInput
              selected={new Date(formState.stopOfSalesDate.value)}
              onChange={handleInputChange}
              errorMessage={formState.stopOfSalesDate.error}
            />
          )}
          <CheckBox
            title="Limitar el horario de ingreso de estos accesos"
            checked={isAccessTimeRestricted}
            onChange={toggleAccessTimeRestriction}
            label="Limitar el horario de ingresos. Después de la fecha y hora seleccionada, los QRs no podrán usarse."
          />
          <VDateTimeInput
            showYearDropdown
            name="stopOfAccessDate"
            titleVariant="subtitle4"
            showTimeInput
            disabled={!isAccessTimeRestricted}
            selected={new Date(formState.stopOfAccessDate.value)}
            onChange={handleInputChange}
            errorMessage={formState.stopOfAccessDate.error}
          />
          {!isInviationType && showCashSaleCheckbox && (
            <CheckBox
              name="onlyOnline"
              title="Venta en puerta"
              checked={formState.onlyOnline.value}
              onChange={handleInputChange}
              label="Permitir la venta del ticket en puerta (Venta cash)."
            />
          )}
        </VFormCard>
        <VFormCard label="Visualización del ticket" icon="visibility">
          {!isInviationType && (
            <TitledRadioButtons
              title="Visibilidad en la página del evento"
              description="Permite seleccionar si el ticket va a estar visible en la página del evento u oculto."
              name="visibilityMode"
              currentValue={formState.visibilityMode.value}
              onChange={handleInputChange}
              values={TICKET_VISIBILITY_MODE_OPTIONS}
              row
            />
          )}
          <TitledRadioButtons
            title="Estado del ticket"
            description="Permite seleccionar si el ticket puede o no venderse."
            name="activityStatus"
            currentValue={formState.activityStatus.value}
            onChange={handleInputChange}
            values={TICKET_ACTIVITY_STATUS_OPTIONS}
            row
          />
        </VFormCard>
      </Stack>
      {!isInviationType && (
        <VFormCard
          label="Código de descuento"
          icon="percentage"
          description={
            formState.isLemonExclusive.value
              ? 'Este ticket cuenta con exclusividad por lo que no se puede usar código de descuento.'
              : ''
          }
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
            <VTextInput
              name="discountCode"
              title="Código"
              type={INPUT_TYPE.ALPHA_NUMERIC}
              maxLength={15}
              onChange={handleInputChange}
              value={formState.discountCode.value}
              errorMessage={formState.discountCode.error}
              disabled={formState.isLemonExclusive.value}
            />
            <VTextInput
              name="discountCodePercentage"
              title="Porcentaje de descuento"
              endAdornment="%"
              type={INPUT_TYPE.NUMBER}
              onChange={handleInputChange}
              value={formState.discountCodePercentage.value}
              errorMessage={formState.discountCodePercentage.error}
              disabled={formState.isLemonExclusive.value}
            />
          </Stack>
          {isSuperAdmin && (
            <CheckBox
              name="isLemonExclusive"
              title="Exclusividad"
              checked={formState.isLemonExclusive.value}
              onChange={handleInputChange}
              label="Activar la venta exclusiva de este tickets para usuarios de Astropay"
            />
          )}
        </VFormCard>
      )}
      {mode === FORM_MODE.DUPLICATE ? (
        <Stack direction={{ xs: 'column', sm: 'row' }} width="100%" justifyContent="center" gap={2}>
          <Button variant="outlined" onClick={skipAllTickets} title="Omitir todos los tickets" />
          <Button variant="outlined" onClick={handleNavigation} title="Omitir ticket" />
          <Button title={buttonLabel} onClick={handleSubmit} />
        </Stack>
      ) : (
        <Button fullwidth={false} title={buttonLabel} onClick={handleSubmit} loading={isLoading} />
      )}
    </Stack>
  );
};

export default TicketForm;
