import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import { CSVLink } from 'react-csv';
import styles from './CustomTableStyles';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import VIconButton from '../Buttons/IconButton/IconButton';

const DEFAULT_ROWS_PER_PAGE = 10;

const CustomTable = ({
  classes,
  data,
  columns,
  options,
  noMatch,
  showDownloadButton = false,
  csvName = 'Table.csv',
}) => {
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [downloadData, setDownloadData] = useState([]);
  const csvLink = useRef();

  const customOptions = {
    pagination: true,
    cellAlign: 'center',
    rowsPerPageOptions: [10, 25, 100],
    displayRowLabel: 'Filas por página:',
    ...options,
  };

  const { pagination, cellAlign, rowsPerPageOptions, displayRowLabel } = customOptions;

  const extractTextFromJSX = (element) => {
    if (typeof element === 'string') {
      return element;
    }

    if (React.isValidElement(element)) {
      return React.Children.toArray(element.props.children).map(extractTextFromJSX).join(' ');
    }

    return '';
  };

  const formatDownloadData = (data) => {
    const labels = columns.map(({ label }) => label).join(',');
    const values = columns.map(({ value }) => value);

    const formatedValues =
      data && data.length
        ? data
            .map((row) => {
              return values
                .reduce((acumm, value) => {
                  let valueData = row[value];
                  if (typeof valueData === 'object' && valueData !== null) {
                    if (valueData.props && valueData.props.children) {
                      valueData = extractTextFromJSX(valueData);
                    } else {
                      valueData = '';
                    }
                  }
                  return `${acumm}${valueData},`;
                }, '')
                .slice(0, -1);
            })
            .join('\n')
        : '';

    return `${labels}\n${formatedValues}`;
  };

  useEffect(() => {
    setDownloadData(formatDownloadData(data));
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  const handleDownload = () => {
    csvLink.current.link.click();
  };

  return (
    <Paper elevation={4} className={classes.paperContainer}>
      {showDownloadButton && (
        <Stack width="100%" alignItems="end" paddingTop={1} paddingRight={1}>
          <VIconButton
            Icon={<VIcon name="download" size={24} />}
            onClick={handleDownload}
            size={48}
            variant="outlined"
          />
          <CSVLink
            data={downloadData}
            filename={csvName}
            className={classes.hidden}
            ref={csvLink}
            target="_blank"
          />
        </Stack>
      )}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              {columns.map(({ value, label }, index) => (
                <TableCell
                  className={classes.tableCell}
                  align={cellAlign}
                  key={value + index}
                  scope="row"
                >
                  <Typography className={classes.headerText}>{label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Typography className={`${classes.cellText} ${classes.noMatchText}`}>
                    {noMatch}
                  </Typography>
                  <CSVLink
                    headers={columns.map(({ label, value }) => ({ label, key: value }))}
                    data={[
                      columns.reduce((rowInfo, { value }) => ({ ...rowInfo, [value]: '' }), {}),
                    ]}
                    filename="Template.csv"
                    className={`${classes.cellText} ${classes.noMatchText}`}
                  >
                    Descargar template
                  </CSVLink>
                </TableCell>
              </TableRow>
            )}
            {data
              .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow className={classes.tableRow} hover key={index}>
                  {Object.values(row).map((property, index) => (
                    <TableCell
                      className={classes.tableCell}
                      key={property + index}
                      align={cellAlign}
                      scope="row"
                    >
                      <Typography className={classes.cellText}>{property}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={tablePage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          labelRowsPerPage={displayRowLabel}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(CustomTable);
