import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Stack, Switch } from '@mui/material';
import styles from './TitledSwitch.styles';
import VTypography from '../VTypography/VTypography';

const TitledSwitch = ({
  classes,
  checked,
  onChange,
  name,
  placeholder,
  disabled,
  switchOnTheRight = true,
  label,
}) => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent={switchOnTheRight ? 'space-between' : 'start'}
      width={switchOnTheRight ? '100%' : 'auto'}
      direction={switchOnTheRight ? 'row' : 'row-reverse'}
    >
      <VTypography variant="body1">{label}</VTypography>
      <Switch
        checked={checked || false}
        className={classes.switch}
        onChange={onChange}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
      />
    </Stack>
  );
};

export default withStyles(styles)(TitledSwitch);
