import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './AutocompleteOption.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { formatDatetime, getEventVenueName } from '../../../../utils/utils';

const AutocompleteOption = ({ classes, option, ...props }) => {
  const { key, ...optionProps } = props;
  const { label, promoImg, urlName, startDate } = option;
  const eventVenue = getEventVenueName(option);
  const { time, date } = formatDatetime(startDate);
  return (
    <Stack
      {...optionProps}
      flexDirection="row"
      alignItems="start !important"
      gap={1}
      component="a"
      href={`/evento/${urlName}`}
      key={key}
      className={classes.autocompleteOptionContainer}
    >
      <img src={promoImg} className={classes.optionImage} alt={key} />
      <Stack height="100%" justifyContent="space-between">
        <VTypography linesNumber={2} variant="subtitle3" color="#121212">
          {label}
        </VTypography>
        <VTypography linesNumber={1} variant="body2" color="#616161">
          {eventVenue}
        </VTypography>
        <VTypography variant="body2" color="#616161">
          {date} - {time}
        </VTypography>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(AutocompleteOption);
