import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      color: theme.palette.grey[700],
      padding: 16,

      '& .bankPromotionModal': {
        maxHeight: '90vh',
        maxWidth: 600,
        width: '100%',
        gap: 12,
        position: 'relative',

        '& a': {
          color: theme.palette.primary.main,
        },
      },

      '& .closeButton': {
        position: 'absolute',
        right: 10,
        top: 10,
      },

      '& .promotionImage': {
        objectFit: 'contain',
        borderRadius: 6,
        maxHeight: 84,
        maxWidth: 256,
      },
    },
  });

export default styles;
