'use client';

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { GoogleReCaptchaProvider as GoogleReCaptchaV3Provider } from 'react-google-recaptcha-v3';
import api from '../../api/api';
import { handleRequestHelper } from '../../utils/helpers';

export const GoogleReCaptchaContext = createContext();

export const GOOGLE_RECAPTCHA_VERSION = {
  v2: 'v2',
  v3: 'v3',
};

export const GOOGLE_VERSIONS_PUBLIC_KEY = {
  v2: '6LeHErMqAAAAAPTu6WQ_8Xiupwghjx9Ndmr8H3f-',
  v3: '6LcnE7MqAAAAAPk04lSic8B4DF7f-4cOOC1tiMa-',
};

export function GoogleReCaptchaProvider({ children }) {
  const [googleReCaptchaVersion, setGoogleReCaptchaVersion] = useState(GOOGLE_RECAPTCHA_VERSION.v3);

  const getCaptchaVersion = async () => {
    await handleRequestHelper({
      endpoint: () => api.getGoogleReCaptchaVersion(),
      onSuccess: ({ version }) => {
        if (Object.values(GOOGLE_RECAPTCHA_VERSION).includes(version))
          setGoogleReCaptchaVersion(version);
      },
      onFailure: (error) => console.log('Error getting captcha version: ', error),
    });
  };

  useEffect(() => {
    getCaptchaVersion();
  }, []);

  const contextValues = useMemo(
    () => ({
      googleReCaptchaVersion,
    }),
    [googleReCaptchaVersion]
  );

  return (
    <GoogleReCaptchaV3Provider reCaptchaKey={GOOGLE_VERSIONS_PUBLIC_KEY.v3}>
      <GoogleReCaptchaContext.Provider value={contextValues}>
        {children}
      </GoogleReCaptchaContext.Provider>
    </GoogleReCaptchaV3Provider>
  );
}
