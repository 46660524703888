import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    textInputContainer: {
      '& .MuiAutocomplete-input': {
        padding: '0 !important',
      },

      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[800],
        borderRadius: 8,
        overflow: 'hidden',

        '& fieldset': {
          borderColor: theme.palette.grey[300],
          top: -4,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },

      '& .MuiOutlinedInput-input': {
        padding: 10,
        paddingRight: 4,
      },
    },

    error: {
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
          borderColor: theme.palette.error.main,
        },
      },
    },

    success: {
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
          borderColor: theme.palette.success.main,
        },
      },
    },

    disabled: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],

        '& fieldset': {
          borderColor: theme.palette.grey[400],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.grey[400],
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.grey[400],
        },
      },
    },

    rightIcon: {
      position: 'relative',
      border: 'none',
      background: 'transparent',
    },

    bottomInputBox: {
      marginTop: 4,
      minHeight: 16,
    },

    menu: {
      maxHeight: 400,
    },
  });

export default styles;
