import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { debounce } from '@mui/material';
import styles from './SearcherStyles';
import { useForm } from '../../../hooks/useForm';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import { MATERIAL_UI_ICONS } from '../../../constants/types';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const Searcher = ({ classes, onSubmit, placeholder, initialValue }) => {
  const { formState, handleInputChange } = useForm({
    searchQuery: {
      value: initialValue || '',
    },
  });

  const handleChange = useMemo(
    () => debounce(({ target: { value } }) => onSubmit(value), 500),
    [onSubmit]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.searchQuery.value);
  };

  return (
    <form onSubmit={handleSubmit} onChange={handleChange} className={classes.searcherContainer}>
      <VTextInput
        placeholder={placeholder}
        onChange={handleInputChange}
        name="searchQuery"
        defaultValue={formState.searchQuery.value}
        endAdornment={<VIcon baseClassName={MATERIAL_UI_ICONS.DEFAULT} name="search_outlined" />}
      />
    </form>
  );
};

export default withStyles(styles)(Searcher);
