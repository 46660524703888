import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    autocompleteOptionContainer: {
      textDecoration: 'none',
      margin: '8px 16px',
    },
    optionImage: {
      width: 80,
      height: 90,
      borderRadius: 8,
      objectFit: 'cover',
    },
  });

export default styles;
