import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core';
import styles from './CreateUserStyles';
import UserForm from '../../../Components/Forms/UserForm/UserForm';
import useProducers from '../../../hooks/useProducers';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const CreateUser = ({ classes, urlParams }) => {
  const { producerId, eventId } = urlParams;
  const eventName = useRef('');

  const isEventUserCreationPage = eventId && producerId;

  const { producers, getUserProducers } = useProducers(producerId);

  const formatInitialData = () => {
    return producers?.reduce(
      (initialProducerData, { id, events }) => {
        if (id !== parseInt(producerId)) return initialProducerData;

        events.forEach(({ id }) => {
          initialProducerData[id] = id === parseInt(eventId);
        });

        return initialProducerData;
      },
      { allEvents: false }
    );
  };

  const getEventName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventName(eventId),
      onSuccess: ({ name }) => {
        eventName.current = name;
      },
    });
  };

  useEffect(() => {
    isEventUserCreationPage && getUserProducers();
    if (eventId) getEventName();
  }, []);

  const initialUserData = isEventUserCreationPage
    ? { initialEventsAccesses: { [producerId]: formatInitialData() } }
    : {};

  return (
    <PageContainer title="Backoffice - Crear usuario">
      <VBreadcrumbs pageTitle={eventId ? 'Crear Usuario' : 'Crear'} />
      <Box className={classes.pageContainer}>
        <Box className={classes.pageContentContainer}>
          <BackofficePageTitle
            mainTitle={eventId && eventName.current}
            secondaryTitle="Crear usuario"
          />
          <UserForm producerId={producerId} initialData={initialUserData} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(CreateUser);
