import { useState } from 'react';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';
import { getToken } from '../../api/api.utils';
import history from '../../appHistory';
import { LOCAL_STORAGE_VARIABLES } from '../../constants/constants';

export const useAuthContext = () => {
  const [user, setUser] = useState(null);

  const clearUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.JWT);
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.INTERCOM_HASH);
    setUser(null);
  };

  const logout = () => {
    clearUser();
    history.push('/login');
  };

  const getUserIntercomHash = () => localStorage.getItem(LOCAL_STORAGE_VARIABLES.INTERCOM_HASH);

  const checkJWTToken = async () => {
    if (!getToken()) clearUser();
    else {
      await handleRequestHelper({
        endpoint: () => api.checkToken(),
        onSuccess: ({ user }) => setUser(user),
        onFailure: () => clearUser(),
      });
    }
  };

  const login = async (mail, password, onFailure) => {
    await handleRequestHelper({
      endpoint: () => api.login(mail, password),
      onSuccess: ({ user, token }) => {
        localStorage.setItem(LOCAL_STORAGE_VARIABLES.JWT, token);
        localStorage.setItem(LOCAL_STORAGE_VARIABLES.INTERCOM_HASH, user.intercomHash);
        setUser(user);
      },
      onFailure: () => {
        onFailure();
        clearUser();
      },
    });
  };

  return { user, getUserIntercomHash, login, logout, checkJWTToken };
};
