import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';

const ConfirmBottomSheet = ({ open, onClose, onAccept, title, subtitle }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = async () => {
    setIsLoading(true);
    await onAccept();
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">{title}</VTypography>
          <VTypography variant="body2">{subtitle}</VTypography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button size="small" fullwidth variant="outlined" title="Cancelar" onClick={onClose} />
          <Button
            size="small"
            fullwidth
            title="Aceptar"
            color="error"
            onClick={handleAccept}
            loading={isLoading}
          />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default ConfirmBottomSheet;
