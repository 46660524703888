import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';
import { useEventPromotionsPage } from './hook';
import Button from '../../../Components/Buttons/Button/Button';
import EventPromotionCard from './EventPromotionCard/EventPromotionCard';

const EventPromotionsPage = () => {
  const { eventPromotions, eventName, handleDeletePromotion } = useEventPromotionsPage();

  return (
    <PageContainer title="Backoffice - Promociones">
      <VBreadcrumbs />
      <Stack maxWidth={900} width="100%" gap={2} alignSelf="center" p={2}>
        <BackofficePageTitle secondaryTitle="Promociones" mainTitle={eventName} />
        <Stack width="100%" alignItems="center">
          <Button
            variant="contained"
            fullwidth={false}
            title="Cargar nueva promoción"
            href={`${window.location.pathname}/crear`}
            target="_self"
          />
        </Stack>
        <Stack gap={1.5}>
          {eventPromotions.map((eventPromotion) => (
            <EventPromotionCard
              key={eventPromotion.id}
              eventPromotion={eventPromotion}
              onDelete={() => handleDeletePromotion(eventPromotion.id)}
            />
          ))}
        </Stack>
        <Stack width="100%" alignItems="center" />
      </Stack>
    </PageContainer>
  );
};

export default EventPromotionsPage;
