import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    customLink: {
      color: theme.palette.primary.main,
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      fontSize: 14,
      padding: 0,

      '&:hover': {
        opacity: 0.8,
      },

      '&:visited': {
        color: theme.palette.primary.main,
      },
    },
  });

export default styles;
