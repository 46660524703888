import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignSelf: 'center',
      zIndex: 1,
      paddingTop: 24,
      width: '100%',
      gap: 24,
      '&.MuiStack-root': {
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      },
    },
    bannerDesktop: {
      borderRadius: 8,
      width: '60%',
      aspectRatio: 13 / 5,
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    bannerMobile: {
      maxWidth: 348,
      width: '100%',
      aspectRatio: 4 / 5,
      objectFit: 'cover',
      borderRadius: 8,

      alignSelf: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    producerLogo: {
      width: 40,
      aspectRatio: 1,
      borderRadius: 8,
    },
    textLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      width: 'max-content',
    },
  });

export default styles;
