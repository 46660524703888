import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { FLAGS } from '../../../constants/constants';

export const useUserEventTickets = () => {
  const { eventId } = useParams();
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
  const [eventQrs, setEventQrs] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [selfManagedRefundEnabled, setSelfManagedRefundEnabled] = useState(false);

  const getEventQrs = async () => {
    setIsFetching(true);
    await handleRequestHelper({
      endpoint: () => api.getUserEventQrs(eventId),
      onSuccess: ({ customerQrs }) => {
        const { qrs, ...eventInfo } = customerQrs;

        if (currentTicketIndex >= qrs.length) setCurrentTicketIndex(currentTicketIndex - 1);
        if (!qrs || !qrs.length) history.push('/mis-tickets');

        setEventQrs(qrs);
        setEventInfo(eventInfo);
      },
    });
    setIsFetching(false);
  };

  const getSelfManagedRefundFeaturedFlag = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFlag(FLAGS.SELF_MANAGED_REFUND_ENABLED),
      onSuccess: ({ flag }) => {
        setSelfManagedRefundEnabled(flag.context.enabled);
      },
    });
  };

  useEffect(() => {
    getEventQrs();
    getSelfManagedRefundFeaturedFlag();
  }, []);

  return {
    currentTicketIndex,
    eventQrs,
    eventInfo,
    isFetching,
    selfManagedRefundEnabled,
    setCurrentTicketIndex,
    getEventQrs,
  };
};
