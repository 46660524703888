import { useParams, useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import {
  getThemeByBaseUrl,
  sortArrayOrderByProps,
  userIsBlacklistedFromEvent,
} from '../../../utils/utils';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { TICKET_TYPE, TICKET_VISIBILITY_MODE } from '../../../constants/types';
import { useCart } from './cart';
import { WHITE_LABELS } from '../../../constants/whiteLabels';
import history from '../../../appHistory';

export const useEventPage = (setTheme) => {
  const { urlName, seller } = useParams();
  const { showNotification } = useNotifications();
  const query = new URLSearchParams(useLocation().search);
  const _event = useRef({});
  const _hiddenTicket = useRef({});
  const _eventPromotions = useRef([]);
  const _eventTickets = useRef([]);
  const _isSellerValid = useRef(true);
  const _stadium = useRef({});
  const { updateTicketCart, removeTicketFromCart, deleteCart, cart } = useCart();
  const [isLoading, setIsLoading] = useState(true);
  const [eventIsLoaded, setEventIsLoaded] = useState(false);
  const [isCheckoutOpen, setIsOpenCheckout] = useState(false);

  const resetCartItems = (currentEventId) => {
    const hasTicketsFromOtherEvent = Object.values(cart).some(
      ({ eventId }) => eventId !== currentEventId
    );

    if (hasTicketsFromOtherEvent) deleteCart();
  };

  const getEvent = async () => {
    setEventIsLoaded(false);

    return handleRequestHelper({
      endpoint: () => api.getEventByName(urlName),
      showNotification,
      onSuccess: ({ event }) => {
        _event.current = event;
        _stadium.current = event?.eventStadium.stadium;
        resetCartItems(event.id);
        setTheme(event.Producer.theme ?? WHITE_LABELS.VENTI);
        setEventIsLoaded(true);
      },
      onFailure: () => {
        history.push('/');
      },
    });
  };

  const validateSeller = async () => {
    if (!seller) {
      _isSellerValid.current = true;
      return;
    }
    const event = _event.current;
    await handleRequestHelper({
      endpoint: () => api.validateUserInProducerByAlias(seller.toLowerCase(), event.producerId),
      onFailure: () => {
        _isSellerValid.current = false;
      },
      onSuccess: ({ aliasIsInProducer }) => {
        _isSellerValid.current =
          aliasIsInProducer && !userIsBlacklistedFromEvent(event, { alias: seller });
      },
    });
  };

  const getHiddenTicket = async () => {
    const hiddenTicketExternalId = query.get('ticket');
    const event = _event.current;

    if (hiddenTicketExternalId) {
      await handleRequestHelper({
        endpoint: () => api.getEventHiddenTicket(event.id, hiddenTicketExternalId),
        onSuccess: ({ ticket }) => {
          _hiddenTicket.current = ticket;
        },
      });
    }
  };

  const closeCheckout = () => {
    setIsOpenCheckout(false);
  };

  const openCheckout = () => {
    setIsOpenCheckout(true);
  };

  const addHiddenTicketToCart = () => {
    const hiddenTicket = _hiddenTicket.current;
    if (!hiddenTicket.id) return;

    const { maxQuantity, soldUnits, priceInCents, shouldConvertPriceFromUSD, type } = hiddenTicket;
    if (maxQuantity > soldUnits) {
      updateTicketCart({
        ...hiddenTicket,
        price: priceInCents / 100,
        quantity: type === TICKET_TYPE.TABLE ? hiddenTicket.groupSize : 1,
        ...(shouldConvertPriceFromUSD && { priceInUSD: priceInCents / 100 }),
      });
    }
  };

  const loadCartData = () => {
    Object.values(cart).forEach((ticket) => {
      const ticketType = _eventTickets.current.find(({ id }) => id === ticket.id);
      if (
        ticketType &&
        ticketType.canBeSelled &&
        ticketType.visibilityMode !== TICKET_VISIBILITY_MODE.HIDDEN
      )
        updateTicketCart({
          ...ticketType,
          price: ticketType.priceInCents / 100,
          quantity: Math.min(ticket.quantity, ticketType.maxQuantity - ticketType.soldUnits),
        });
    });

    addHiddenTicketToCart();
  };

  const getEventPromotions = async () => {
    const eventId = _event.current.id;
    await handleRequestHelper({
      endpoint: () => api.getEventPromotions(eventId),
      onSuccess: ({ eventPromotions }) => {
        _eventPromotions.current = eventPromotions;
      },
    });
  };

  const sortTickets = (tickets) => {
    const fieldToOrderTickets = _stadium.current?.stadiumLayoutId
      ? 'ticketTypeSection.0.name'
      : 'name';
    const sortedTickets = sortArrayOrderByProps(tickets, fieldToOrderTickets);

    const canNotBeSelledTickets = sortedTickets.filter(
      ({ visibilityMode, canBeSelled }) =>
        visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE && !canBeSelled
    );

    const canBeSelledTickets = sortedTickets.filter(
      ({ visibilityMode, canBeSelled }) =>
        visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE && canBeSelled
    );

    return canBeSelledTickets.concat(canNotBeSelledTickets);
  };

  const getEventTickets = () => {
    const event = _event.current;
    const sortedTickets = sortTickets(event.TicketTypes);
    _eventTickets.current = sortedTickets;
  };

  const updateTicketInCart = (ticket) => {
    if (ticket.quantity === 0) {
      removeTicketFromCart(ticket.id);
      if (Object.keys(cart).length === 1) closeCheckout();
    } else {
      updateTicketCart(ticket);
      openCheckout();
    }
  };

  const incrementTicketQuantity = (ticket) => {
    const ticketQuantity = cart[ticket.id].quantity + 1 * (ticket.groupSize || 1);
    updateTicketInCart({ ...ticket, quantity: ticketQuantity });
  };

  const decrementTicketQuantity = (ticket) => {
    const ticketQuantity = cart[ticket.id].quantity - 1 * (ticket.groupSize || 1);
    updateTicketInCart({ ...ticket, quantity: ticketQuantity });
  };

  const getInitialData = async () => {
    setIsLoading(true);

    if (!_event.current?.id) return;
    getEventTickets();
    await Promise.all([getHiddenTicket(), validateSeller(), getEventPromotions()]);
    loadCartData();

    setIsLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (Object.keys(cart).length >= 1 && !isLoading) openCheckout();
  }, [isLoading]);

  useEffect(() => {
    if (!eventIsLoaded) return;

    getInitialData();

    return () => {
      setTheme(getThemeByBaseUrl());
    };
  }, [eventIsLoaded]);

  return {
    event: _event.current,
    stadium: _stadium.current,
    country: _event.current?.venue?.city?.country,
    isSellerValid: _isSellerValid.current,
    isLoading,
    eventPromotions: _eventPromotions.current,
    seller,
    eventTickets: _eventTickets.current,
    cart,
    isCheckoutOpen,
    getInitialData,
    incrementTicketQuantity,
    decrementTicketQuantity,
    updateTicketInCart,
    openCheckout,
    closeCheckout,
  };
};
