import { Stack, useTheme } from '@mui/material';
import React from 'react';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const BackofficePageTitle = ({ mainTitle, secondaryTitle }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const pageTitle = mainTitle || secondaryTitle;
  const isMobile = width < theme.breakpoints.values.md;

  return (
    <Stack alignItems="center" width="100%">
      <VTypography
        color={theme.palette.grey[800]}
        align="center"
        variant="h3"
        linesNumber={isMobile ? 2 : 1}
      >
        {pageTitle}
      </VTypography>
      {mainTitle && (
        <VTypography color={theme.palette.grey[700]} align="center" variant="subtitle2">
          {secondaryTitle}
        </VTypography>
      )}
    </Stack>
  );
};

export default BackofficePageTitle;
