import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Person, Mail, Phone, FeaturedPlayList, Room, Public } from '@material-ui/icons';
import styles from './DataFormStep.styles';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { useForm } from '../../../hooks/useForm';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import Button from '../../Buttons/Button/Button';
import { ID_TYPE_OPTIONS } from '../../../constants/identificationTypes';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import CountrySelector from '../../LocationSelectors/CountrySelector/CountrySelector';
import CitySelector from '../../LocationSelectors/CitySelector/CitySelector';

const DataFormStep = ({ classes, handleBack, setOrder, handleNext }) => {
  const [isComplete, setIsComplete] = useState(false);
  const { showNotification } = useNotifications();
  const { user } = useAuth();

  const { formState, handleInputChange, isFormComplete, validateFormInputs } = useForm({
    firstName: {
      type: INPUT_TYPE.ALPHA,
      value: user?.firstName,
    },
    lastName: {
      type: INPUT_TYPE.ALPHA,
      value: user?.lastName,
    },
    idType: {
      type: INPUT_TYPE.ID_TYPE,
      value: user?.identificationType || 'DNI',
    },
    idNumber: {
      type: INPUT_TYPE.ID_NUMBER,
      value: user?.identificationValue,
    },
    mail: {
      type: INPUT_TYPE.MAIL,
      value: user?.mail,
    },
    confirmMail: {
      type: INPUT_TYPE.CONFIRM_MAIL,
      value: user?.mail,
    },
    phoneNumber: {
      type: INPUT_TYPE.NUMBER,
      value: user?.phone,
    },
    country: {
      type: INPUT_TYPE.TEXT,
      value: user?.country,
    },
    city: {
      type: INPUT_TYPE.TEXT,
      value: user?.residenceLocation,
      required: false,
    },
  });

  const onSubmit = () => {
    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    setOrder((prevState) => ({
      ...prevState,
      user: {
        firstName: formState.firstName.value.trim(),
        lastName: formState.lastName.value.trim(),
        idType: formState.idType.value,
        idNumber: formState.idNumber.value,
        mail: formState.mail.value.trim().toLowerCase(),
        phoneNumber: formState.phoneNumber.value,
        country: formState.country.value,
        province: formState.city.value,
      },
    }));

    handleNext();
  };

  useEffect(() => {
    const shouldRequireCity = formState?.country.value !== 'OTHER';
    const isComplete = isFormComplete(formState) && (!shouldRequireCity || formState.city.value);
    setIsComplete(() => isComplete);
  }, [setIsComplete, isFormComplete, formState]);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <VTextInput
          title="Nombre"
          leftIcon={<Person fontSize="small" />}
          onChange={handleInputChange}
          name="firstName"
          errorMessage={formState.firstName.error}
          defaultValue={formState.firstName.value}
        />
        <VTextInput
          title="Apellido"
          leftIcon={<Person fontSize="small" />}
          onChange={handleInputChange}
          name="lastName"
          errorMessage={formState.lastName.error}
          defaultValue={formState.lastName.value}
        />
        <VSelectInput
          title="Tipo de identificación"
          leftIcon={<FeaturedPlayList fontSize="small" />}
          onChange={handleInputChange}
          name="idType"
          options={ID_TYPE_OPTIONS}
          value={formState.idType.value}
          errorMessage={formState.idType.error}
        />
        <VTextInput
          title="Número de identificación"
          leftIcon={<FeaturedPlayList fontSize="small" />}
          onChange={handleInputChange}
          name="idNumber"
          errorMessage={formState.idNumber.error}
          defaultValue={formState.idNumber.value}
        />
        <VTextInput
          title="Mail"
          leftIcon={<Mail fontSize="small" />}
          onChange={handleInputChange}
          name="mail"
          errorMessage={formState.mail.error}
          defaultValue={formState.mail.value}
        />
        <VTextInput
          title="Confirmar mail"
          leftIcon={<Mail fontSize="small" />}
          onChange={handleInputChange}
          name="confirmMail"
          errorMessage={formState.confirmMail.error}
          defaultValue={formState.confirmMail.value}
        />
        <VTextInput
          title="Número de teléfono"
          leftIcon={<Phone fontSize="small" />}
          onChange={handleInputChange}
          name="phoneNumber"
          errorMessage={formState.phoneNumber.error}
          defaultValue={formState.phoneNumber.value}
        />
        <CountrySelector
          onChange={handleInputChange}
          value={formState?.country.value}
          errorMessage={formState?.country.error}
          leftIcon={<Public fontSize="small" />}
        />
        <CitySelector
          country={formState?.country.value}
          onChange={handleInputChange}
          value={formState?.city.value}
          hint="Este dato se utilizará para facturarte"
          errorMessage={formState?.city.error}
          leftIcon={<Room fontSize="small" />}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <Button onClick={handleBack} variant="outlined" title="Anterior" />
        <Button disabled={!isComplete} onClick={onSubmit} title="Siguiente" />
      </div>
    </div>
  );
};

export default withStyles(styles)(DataFormStep);
