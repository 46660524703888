import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const FormItemHeader = ({ classes, title, leftIcon, required, description }) => {
  const theme = useTheme();

  return (
    <Stack>
      {title && (
        <Stack className={classes.textInputTitleContainer} direction="row">
          {leftIcon}
          <VTypography variant="button">{title}</VTypography>
          {required && (
            <VTypography variant="body2" color={theme.palette.error.main}>
              *
            </VTypography>
          )}
        </Stack>
      )}
      {description && (
        <VTypography variant="subtitle6" color={theme.palette.grey[600]}>
          {description}
        </VTypography>
      )}
    </Stack>
  );
};

export default withStyles(styles)(FormItemHeader);
