import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import { FLAGS } from '../../../constants/constants';
import api from '../../../api/api';
import { useForm } from '../../../hooks/useForm';
import { EVENT_EMPTY_STATE, FORM_MODE, NO_STADIUM_ID } from './constants';
import {
  INPUT_TYPE,
  NOTIFICATION_VARIANT,
  TICKET_ACTIVITY_STATUS,
  USER_PERMISSIONS,
} from '../../../constants/types';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { formatDatetime, objectIsEmpty, validateUserRole } from '../../../utils/utils';
import history from '../../../appHistory';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

export const useEventForm = ({ mode, eventData, producerData }) => {
  const initialEventData = { ...EVENT_EMPTY_STATE, ...eventData };
  const { user } = useAuth();
  const { producerId, eventId } = useParams();
  const { showNotification } = useNotifications();
  const userIsSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);
  const [stadium, setStadium] = useState(initialEventData.stadium);
  const [venuesOn, setVenuesOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getVenuesFlag = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFlag(FLAGS.VENUES_ENABLED),
      onSuccess: ({ flag }) => {
        setVenuesOn(flag.context.enabled);
      },
    });
  };

  const {
    handleInputChange,
    formState,
    formValues,
    validateFormInputs,
    handleMultipleInputsChange,
  } = useForm(
    {
      name: { value: mode === FORM_MODE.DUPLICATE ? '' : initialEventData.name },
      promoImg: { value: initialEventData.promoImg, required: false },
      bannerImg: { value: initialEventData.bannerImg, required: false },
      description: { value: initialEventData.description || '', required: false },
      status: { value: initialEventData.status },
      producerId: { value: Number(producerId) },
      shouldBlacklistSellers: {
        value: initialEventData.shouldBlacklistSellers,
      },
      startDate: { value: new Date(initialEventData.startDate), type: INPUT_TYPE.DATE },
      endDate: { value: new Date(initialEventData.endDate), type: INPUT_TYPE.DATE },
      defaultStopOfSalesDate: {
        value: new Date(initialEventData.defaultStopOfSalesDate),
        type: INPUT_TYPE.DATE,
      },
      shouldExtendStopOfSalesDateToInvitations: {
        value: initialEventData.shouldExtendStopOfSalesDateToInvitations,
      },
      placeName: {
        value: initialEventData.placeName || '',
        required: !venuesOn,
      },
      venue: {
        value: initialEventData.venue,
        required: ({ placeName }) => venuesOn && !placeName,
      },
      stadiumId: { value: initialEventData.stadium.id, required: !!initialEventData.stadium.id },
    },
    [venuesOn]
  );

  const getDateLabel = (date) => {
    const { dayOfWeek, day, month, year, time } = formatDatetime(date);
    return `${dayOfWeek} ${day} de ${month} del ${year} a las ${time}hs`;
  };

  const startDateLabel = getDateLabel(formValues?.startDate);
  const endDateLabel = getDateLabel(formValues?.endDate);
  const stopOfSalesDateLabel = getDateLabel(formValues?.defaultStopOfSalesDate);

  const formatFormValues = () => {
    const { stadiumId, venue, ...otherProps } = formValues;

    const { serviceCharge, urlName, currency, id } = initialEventData;
    return {
      ...(mode === FORM_MODE.EDIT && {
        id,
        serviceCharge,
        urlName,
        currency,
      }),
      ...otherProps,
      venueId: venue?.id || null,
    };
  };

  function formatStadiumOption({ name, capacity, numberOfSections, id }) {
    const capacityLabel = userIsSuperAdmin ? ` - ${capacity}p` : '';
    return {
      label: `${name}${capacityLabel} - ${numberOfSections} sectores`,
      value: id,
    };
  }

  const handleVenueChange = (venue) => {
    handleMultipleInputsChange([
      { name: 'venue', value: venue?.id && venue },
      { name: 'placeName', value: venue?.id ? '' : venue?.placeName },
    ]);
  };

  const producerStadiumOptions = [
    { label: 'Sin planimetria', value: NO_STADIUM_ID },
    ...(producerData.stadiums?.map((stadium) => formatStadiumOption(stadium)) || []),
  ];

  const updateStadiumData = async () => {
    if (!userIsSuperAdmin || objectIsEmpty(initialEventData.stadium)) return true;

    const sectionsToUpdate = stadium.sections.map(
      ({ ticketTypes, stockAvailable, ...section }) => ({
        eventId,
        ...section,
      })
    );

    return handleRequestHelper({
      endpoint: () =>
        api.updateEventStadium(eventId, initialEventData.stadium.id, {
          sections: sectionsToUpdate,
        }),
      onFailure: () => false,
      onSuccess: () => true,
      showNotification,
    });
  };

  const navigateToTicketCreation = (eventId) => {
    const ticketCreationPath = `/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`;

    if (mode === FORM_MODE.CREATE || !initialEventData.TicketTypes.length) {
      history.push(`${ticketCreationPath}/crear`);
    } else {
      const ticketsIds = initialEventData.TicketTypes.filter(
        ({ activityStatus }) => activityStatus !== TICKET_ACTIVITY_STATUS.ARCHIVED
      ).map(({ id }) => id);
      history.push(`${ticketCreationPath}/duplicar?tickets=${ticketsIds.join(',')}`);
    }
  };

  const createEventStadium = async (eventId) => {
    if (!formValues.stadiumId) {
      navigateToTicketCreation(eventId);
      return;
    }

    const { id, sections } = producerData.stadiums.find(({ id }) => id === formValues.stadiumId);
    const sectionsToCreate = sections.map(({ id, ...sectionData }) => ({
      stadiumLayoutSectionId: id,
      ...sectionData,
    }));

    await handleRequestHelper({
      endpoint: () => api.createEventStadium(eventId, id, { sections: sectionsToCreate }),
      onFailure: () => history.push('/error/eventStadium'),
      onSuccess: () => navigateToTicketCreation(eventId),
    });
  };

  const updateEventData = async () => {
    await handleRequestHelper({
      endpoint: () => api.updateEvent(formatFormValues(formValues)),
      onSuccess: () => {
        history.push(`/backoffice/productoras/${producerId}/eventos/${eventId}`);
      },
    });
  };

  const createEvent = async () => {
    return handleRequestHelper({
      endpoint: () => api.createEvent(formatFormValues(formValues)),
      showNotification,
      onSuccess: ({ event }) => event.id,
    });
  };

  const handleStadiumChange = ({ target }) => {
    const stadiumId = target.value;
    handleInputChange({ target: { name: 'stadiumId', value: stadiumId } });
    if (stadiumId === NO_STADIUM_ID) {
      setStadium({});
      return;
    }

    if (mode === FORM_MODE.EDIT) return;

    const stadium = producerData.stadiums.find(({ id }) => id === stadiumId);
    setStadium(stadium);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const isFormValid = validateFormInputs();
    const stadiumCapacityIsValid =
      !stadium?.sections ||
      stadium.capacity >= stadium.sections.reduce((acc, { capacity }) => acc + capacity, 0);

    if (!stadiumCapacityIsValid) {
      showNotification(
        'La capacidad de los sectores debe ser menor o igual a la del estadio',
        NOTIFICATION_VARIANT.ERROR
      );
      setIsLoading(false);
      return;
    }

    if (!isFormValid) {
      showNotification('Hay errores en el formulario', NOTIFICATION_VARIANT.ERROR);
      setIsLoading(false);
      return;
    }

    if (mode === FORM_MODE.EDIT) {
      const canUpdateEvent = await updateStadiumData();
      if (canUpdateEvent) await updateEventData();
    } else {
      const eventId = await createEvent();
      if (!eventId) {
        setIsLoading(false);
        return;
      }
      await createEventStadium(eventId);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVenuesFlag();
  }, []);

  return {
    venuesOn,
    stadium,
    formState,
    producerStadiumOptions,
    startDateLabel,
    endDateLabel,
    stopOfSalesDateLabel,
    handleStadiumChange,
    handleInputChange,
    handleSubmit,
    handleVenueChange,
    isLoading,
  };
};
