import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cardContainer: {
      width: 309,
      height: 98,
      borderRadius: 8,
      display: 'flex',
      outline: `1px solid ${theme.palette.grey[400]}`,
    },
    cardInfo: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: 16,
      height: '100%',
      justifyContent: 'space-between',
      rowGap: 4,
    },
    graphicContainer: {
      padding: 16,
      width: 94,
      display: 'flex',
      borderLeft: `1px dashed ${theme.palette.grey[400]}`,
      justifyContent: 'center',
      alignItems: 'center',
    },
    eventInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      '& p': {
        color: theme.palette.charts.primary,
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '14px',
        textTransform: 'initial',
      },
    },
    totalSoldContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    totalSoldValue: {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    totalSoldLabel: {
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  });

export default styles;
