import { EVENT_STATUS } from '../../../constants/types';

export const NO_STADIUM_ID = 0;

export const EVENT_STATUS_OPTIONS = [
  { value: 'active', label: 'Público' },
  { value: 'hidden', label: 'Privado' },
];

export const BLACKLISTED = 'visible';
export const NOT_BLACKLISTED = 'hidden';

export const SELLER_BLACKLIST_STATUS = [
  { value: BLACKLISTED, label: 'Visible' },
  { value: NOT_BLACKLISTED, label: 'Oculto' },
];

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

export const EVENT_EMPTY_STATE = {
  name: '',
  promoImg: '',
  bannerImg: '',
  description: '',
  status: EVENT_STATUS.HIDDEN,
  shouldBlacklistSellers: false,
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  defaultStopOfSalesDate: new Date().toISOString(),
  shouldExtendStopOfSalesDateToInvitations: false,
  placeName: '',
  venue: null,
  stadiumId: NO_STADIUM_ID,
  stadium: {},
};
