import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { useForm } from '../../../hooks/useForm';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const ChangeOrderMailBottomSheet = ({
  open,
  onClose,
  orderId,
  ownerMail,
  getOrder,
  setNewMail,
}) => {
  const { showNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);

  const { formState, handleInputChange, validateFormInputs } = useForm({
    mail: {
      title: 'Mail',
      type: INPUT_TYPE.MAIL,
      value: ownerMail,
    },
  });

  const handleEditEmail = async (e) => {
    e.preventDefault();
    const isFormValid = validateFormInputs();
    if (!isFormValid) return;

    setIsLoading(true);
    await handleRequestHelper({
      endpoint: () => api.updateOrderMail(orderId, formState.mail.value),
      onSuccess: () => {
        showNotification(
          'El mail de la orden fue actualizado con éxito.',
          NOTIFICATION_VARIANT.SUCCESS
        );
        if (getOrder) getOrder();
        if (setNewMail) setNewMail(formState.mail.value);
      },
      showNotification,
    });
    setIsLoading(false);
    onClose();
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <form id="changeMailForm" onSubmit={handleEditEmail}>
        <Stack padding={2} gap={2.5}>
          <Stack gap={3}>
            <Stack gap={1}>
              <VTypography variant="h4">Editar mail de la orden</VTypography>
              <VTypography variant="body2">
                Una vez que lo hagas, se modificará el mail del dueño de la orden y los tickets que
                le pertenezcan a él.
              </VTypography>
            </Stack>

            <VTextInput
              title={formState.mail.title}
              onChange={handleInputChange}
              name="mail"
              defaultValue={formState.mail.value}
              errorMessage={formState.mail.error}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
            <Button size="small" fullwidth variant="outlined" title="Volver" onClick={onClose} />
            <Button
              size="small"
              fullwidth
              type="submit"
              title="Editar"
              onClick={handleEditEmail}
              loading={isLoading}
              form="changeMailForm"
              disabled={!formState.mail.value || formState.mail.value === ownerMail}
            />
          </Stack>
        </Stack>
      </form>
    </BottomSheet>
  );
};

export default ChangeOrderMailBottomSheet;
