import React, { forwardRef } from 'react';
import VTextInput from '../VTextInput/VTextInput';

const DateInput = forwardRef(({ value, onClick, disabled, onChange, errorMessage }, ref) => {
  return (
    <VTextInput
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
});

DateInput.displayName = 'DateInput';

export default DateInput;
