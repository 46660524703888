export const ROLE_OPTIONS = [
  {
    label: 'Vendedor',
    value: 'seller',
  },
  {
    label: 'Vendedor con cash',
    value: 'seller-with-cash',
  },
];

export const MAIL_COLUMN = { value: 'mail', label: 'Mail' };
export const TEAMLEADER_COLUMN = { value: 'teamLeader', label: 'Team Leader' };
export const ERROR_COLUMN = { value: 'error', label: 'Error' };
export const ERROR_CODE = {
  USER_ALREADY_EXISTS: 'user_already_exists',
  USER_ALREADY_EXISTS_WITH_OTHER_ROLE: 'user_already_exists_with_other_role',
};
