import React from 'react';
import { Stack } from '@mui/material';
import VFormCard from '../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import StadiumCustomizer from '../../Backoffice/StadiumCustomizer/StadiumCustomizer';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import VTextArea from '../../../Venti-UI-Kit/VInputs/VTextArea/VTextArea';
import VenueAutocomplete from '../../VenueAutocomplete/VenueAutocomplete';
import Button from '../../Buttons/Button/Button';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';
import { EVENT_STATUS_OPTIONS, FORM_MODE } from './constants';
import CheckBox from '../../../Venti-UI-Kit/CheckBox/CheckBox';
import VImageUploader from '../../../Venti-UI-Kit/VImageUploader/VImageUploader';
import Alert from '../../../Venti-UI-Kit/Alert/Alert';
import { useEventForm } from './hook';
import VDateTimeInput from '../../../Venti-UI-Kit/VInputs/VDateTimeInput/VDateTimeInput';

const EventForm = ({ buttonLabel, mode, eventData, producerData }) => {
  const {
    stadium,
    formState,
    producerStadiumOptions,
    venuesOn,
    startDateLabel,
    endDateLabel,
    stopOfSalesDateLabel,
    handleStadiumChange,
    handleInputChange,
    handleSubmit,
    handleVenueChange,
    isLoading,
  } = useEventForm({ mode, eventData, producerData });

  return (
    <Stack gap={2} alignItems="center" maxWidth={990} width="100%">
      {!!producerData.stadiums.length &&
        ((mode === FORM_MODE.EDIT && eventData.stadium?.id) || mode !== FORM_MODE.EDIT) && (
          <VFormCard label="Planimetría" icon="stadium">
            <VSelectInput
              name="stadiumId"
              value={formState.stadiumId.value}
              options={producerStadiumOptions}
              onChange={handleStadiumChange}
              disabled={mode === FORM_MODE.EDIT}
            />
            {stadium?.id && <StadiumCustomizer stadium={stadium} />}
          </VFormCard>
        )}
      <VFormCard label="Información general" icon="create">
        <VTextInput
          name="name"
          title="Nombre"
          required
          onChange={handleInputChange}
          value={formState.name.value}
          errorMessage={formState.name.error}
        />
        <VTextArea
          name="description"
          title="Descripción"
          onChange={handleInputChange}
          value={formState.description.value}
          errorMessage={formState.description.error}
        />
      </VFormCard>
      <VFormCard label="Ubicación" icon="place">
        {venuesOn ? (
          <VenueAutocomplete
            onSelectOption={handleVenueChange}
            placeName={formState.placeName.value}
            venue={formState.venue.value}
            errorMessage={formState.venue.error}
          />
        ) : (
          <VTextInput
            name="placeName"
            onChange={handleInputChange}
            value={formState.placeName.value}
            errorMessage={formState.placeName.error}
            required
          />
        )}
      </VFormCard>
      <Stack width="100%" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <VFormCard label="Visualización del Evento" icon="visibility">
          <TitledRadioButtons
            name="status"
            title="Tipo de evento"
            description="Esta opción habilita que tu evento se muestre en el inicio. Luego podrás editar esta decisión."
            currentValue={formState.status.value}
            onChange={handleInputChange}
            values={EVENT_STATUS_OPTIONS}
            row
          />
          <CheckBox
            title="Ocultar evento a todos los sellers"
            name="shouldBlacklistSellers"
            checked={formState.shouldBlacklistSellers.value}
            onChange={handleInputChange}
            label="Esta opción sirve para ocultar el evento a los usuarios sellers de la productora. Los administradores podrán seguir visualizando el evento."
          />
        </VFormCard>
        <VFormCard label="Fecha y hora" icon="schedule">
          <VDateTimeInput
            name="startDate"
            title="Inicio del evento"
            description={startDateLabel}
            showTimeInput
            showYearDropdown
            selected={formState.startDate.value}
            onChange={handleInputChange}
            errorMessage={formState.startDate.error}
          />
          <VDateTimeInput
            name="endDate"
            title="Finalización del evento"
            description={endDateLabel}
            showTimeInput
            showYearDropdown
            selected={formState.endDate.value}
            onChange={handleInputChange}
            errorMessage={formState.endDate.error}
          />
          <VDateTimeInput
            name="defaultStopOfSalesDate"
            title="Corte de venta de tickets"
            description={stopOfSalesDateLabel}
            showTimeInput
            showYearDropdown
            selected={formState.defaultStopOfSalesDate.value}
            onChange={handleInputChange}
            errorMessage={formState.defaultStopOfSalesDate.error}
          />
          <CheckBox
            name="shouldExtendStopOfSalesDateToInvitations"
            checked={formState.shouldExtendStopOfSalesDateToInvitations.value}
            onChange={handleInputChange}
            label="Cortar envío de invitaciones. (Se recomienda marcar esta opción siempre que se haga uso de molinetes)"
          />
        </VFormCard>
      </Stack>
      <VFormCard label="Imágenes" icon="photo">
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2} justifyContent="center">
          <VImageUploader
            name="bannerImg"
            width="100%"
            imageHeight={1000}
            imageWidth={2600}
            initialImageSrc={formState.bannerImg.value}
            onChange={handleInputChange}
          />
          <VImageUploader
            name="promoImg"
            width={350}
            imageHeight={1250}
            imageWidth={1000}
            initialImageSrc={formState.promoImg.value}
            onChange={handleInputChange}
          />
        </Stack>
      </VFormCard>
      {mode !== FORM_MODE.EDIT && (
        <Alert
          message={
            <>
              Estas creando este evento para la productora: <b>{producerData.name}</b>
            </>
          }
          severity="info"
        />
      )}
      <Button
        variant="contained"
        fullwidth={false}
        title={buttonLabel}
        onClick={handleSubmit}
        loading={isLoading}
      />
    </Stack>
  );
};

export default EventForm;
