import React from 'react';
import VTable from '../../../Venti-UI-Kit/VTable/VTable';
import { useAmountOrdersByLocationTable } from './hook';

const AmountOrdersByLocationTable = ({ producerId }) => {
  const { columns, metrics, page, handleChangePage } = useAmountOrdersByLocationTable(producerId);
  return (
    <VTable
      title="Cantidad de órdenes por ubicación"
      data={metrics}
      columns={columns}
      page={page}
      onPageChange={handleChangePage}
    />
  );
};

export default AmountOrdersByLocationTable;
