import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      overflow: 'auto',
      textAlign: 'left',
      padding: '8px 16px',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 8,
    },
  });

export default styles;
