import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './StadiumSectionInfoStyles';
import { translate } from '../../../utils/translate';
import priceProvider from '../../../Providers/Price/Price';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const StadiumSectionInfo = ({ section, classes }) => {
  const getTicketLabel = ({ name, type, priceInCents, maxQuantity, currency }) =>
    `• ${name} - ${translate(type)} - $${priceProvider.getPrice(
      priceInCents,
      currency
    )} - ${maxQuantity} loc.`;

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      className={classes.stadiumSectionInfoContainer}
      gap={7}
    >
      <Stack className={classes.imageContainer}>
        <img alt="" src={section.image} className={classes.sectionImage} />
      </Stack>
      <Stack flex={2} maxHeight={300} overflow="auto">
        <Stack direction="row" alignItems="center" gap={2}>
          <VTypography variant="subtitle4">Capacidad total: </VTypography>
          <VTypography variant="body1">{`${section.capacity} loc.`}</VTypography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <VTypography variant="subtitle4">Stock disponible:</VTypography>
          <VTypography variant="body1">{`${section.stockAvailable} loc.`}</VTypography>
        </Stack>
        {section.ticketTypes.length > 0 && (
          <Stack>
            <VTypography variant="subtitle4">Tickets precargados: </VTypography>
            {section.ticketTypes.map((ticket) => (
              <VTypography key={ticket.id} variant="body1">
                {getTicketLabel(ticket)}
              </VTypography>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(StadiumSectionInfo);
