import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      width: '90%',
      padding: 24,
      background: 'white',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    eventAccessSelector: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
      },
      '& .MuiAccordionSummary': {
        '&-root': {
          height: 44,
          borderRadius: 8,
          border: `1px solid ${theme.palette.grey[300]}`,
          minHeight: 'unset !important',
        },
      },
      '& .MuiAccordion-region': {
        backgroundColor: theme.palette.grey[50],
        maxHeight: 245,
        border: `1px solid ${theme.palette.grey[400]}`,
        overflowY: 'auto',
        borderRadius: '0 0 8px 8px',
        position: 'absolute',
        zIndex: 10,
      },
    },
    hidden: {
      display: 'none',
    },
  });

export default styles;
