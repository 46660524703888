import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Divider, Menu } from '@mui/material';
import styles from './UserMenuStyles';
import { getUserInicials } from '../../utils/utils';
import { useAuth } from '../../Providers/AuthProvider/AuthProvider';
import { useUserMenu } from './hook';
import UserMenuItem from './UserMenuItem/UserMenuItem';
import history from '../../appHistory';
import { USER_ROLES } from '../../constants/types';

const UserMenu = ({ classes }) => {
  const { user, logout } = useAuth();
  const { menuItems } = useUserMenu();
  const theme = useTheme();
  const { firstName, alias, mail } = user;
  const userInicials = getUserInicials(user);
  const [anchorEl, setAnchorEl] = useState();
  const isCustomer = user.role === USER_ROLES.CUSTOMER;
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button type="button" onClick={handleOpen} className={classes.buttonContainer}>
        {userInicials}
      </button>
      <Menu
        anchorEl={anchorEl}
        className={classes.menuContainer}
        disableScrollLock
        open={open}
        onClose={handleClose}
      >
        <UserMenuItem
          variant="subtitle4"
          label={firstName || 'Menu'}
          color={theme.palette.grey[600]}
        />
        <UserMenuItem label={mail} color={theme.palette.grey[600]} />
        {!isCustomer && alias && <UserMenuItem label={alias} color={theme.palette.grey[600]} />}
        <Divider className={classes.divider} />
        {menuItems.map(({ label, href, leftIcon }) => (
          <UserMenuItem
            key={href}
            label={label}
            onClick={() => history.replace(href)}
            leftIcon={leftIcon}
            rightIcon="arrow_right"
          />
        ))}
        <Divider className={classes.divider} />
        <UserMenuItem
          label="Cerrar sesión"
          onClick={logout}
          color={theme.palette.error.main}
          leftIcon="logout"
        />
      </Menu>
    </>
  );
};

export default withStyles(styles)(UserMenu);
