import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';
import { useEventHeader } from './hook';
import EventPaymentMethodsAndPromotions from '../../../../Components/EventPaymentMethodsAndPromotions/EventPaymentMethodsAndPromotions';

const EventHeader = ({ classes, event, seller, eventPromotions }) => {
  const { eventInformation, producerLink } = useEventHeader(event, seller);

  return (
    <Stack className={classes.container}>
      <img alt="event_banner" className={classes.bannerDesktop} src={event.bannerImg} />
      <img
        alt="event_banner"
        className={classes.bannerMobile}
        src={event.promoImg || event.bannerImg}
      />
      <Stack gap={3}>
        <VTypography variant="h3" linesNumber={3}>
          {event.name}
        </VTypography>
        <Stack gap={2}>
          {eventInformation.map(
            ({ icon, label, link }) =>
              label && (
                <Stack key={icon} direction="row" gap={2}>
                  <VIcon name={icon} size={24} />
                  <a href={link} className={classes.textLink}>
                    <VTypography variant="body1">{label}</VTypography>
                  </a>
                </Stack>
              )
          )}
        </Stack>
        {event.Producer.logoImg && (
          <Stack gap={2}>
            <VTypography variant="subtitle3">Organizador</VTypography>
            <a href={producerLink} className={classes.textLink}>
              <Stack direction="row" gap={2} alignItems="center">
                <img
                  alt="producer_logo"
                  className={classes.producerLogo}
                  src={event.Producer.logoImg}
                />
                <VTypography variant="body2">{event.Producer.name}</VTypography>
              </Stack>
            </a>
          </Stack>
        )}
        {eventPromotions.length > 0 && (
          <EventPaymentMethodsAndPromotions showArrowIcon eventPromotions={eventPromotions} />
        )}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(EventHeader);
