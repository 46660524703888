import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    textInputTitleContainer: {
      marginBottom: 8,
      gap: 3,
      height: 16,
      maxHeight: 16,
      alignItems: 'center',
    },
  });

export default styles;
