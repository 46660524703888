export const REQUIREMENTS_TEXTS = [
  {
    code: '10_DAYS',
    message:
      'La devolución solo puede solicitarse dentro de los diez (10) días desde el momento de la compra.',
  },
  {
    code: '48_HOURS',
    message:
      'La solicitud de devolución no puede realizarse si faltan 48hs o menos para el inicio del evento.',
  },
  {
    code: 'NO_TRANSFER',
    message: 'Ninguna de las entradas de la orden debe haberse transferido a otra persona.',
  },
  {
    code: 'NO_USED',
    message: 'Ninguna de las entradas de la orden puede haber sido utilizada.',
  },
];
