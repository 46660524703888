import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import withStyles from '@material-ui/core/styles/withStyles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { useMoreEvents } from './hook';
import styles from './styles';
import MediumEventCard from '../../Home/Components/MediumEventCard/MediumEventCard';

const MoreEvents = ({ classes, event }) => {
  const { moreEvents, getMoreEvents } = useMoreEvents(event);

  const { name, urlName } = event.Producer;

  useEffect(() => {
    getMoreEvents();
  }, []);

  if (moreEvents.length === 0) return null;

  return (
    <Stack className={classes.container}>
      <VTypography align="left" variant="h3">
        Más de{' '}
        <a
          href={`/organizadores/${urlName}`}
          className={classes.producerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </VTypography>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" gap={4}>
        {moreEvents.map((event) => (
          <MediumEventCard key={event.id} event={event} />
        ))}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(MoreEvents);
