import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

export const useDuplicateEventPage = () => {
  const eventData = useRef({});
  const producerData = useRef({});
  const { eventId, producerId } = useParams();
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);

  const getEventData = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        eventData.current = event;
        producerData.current.name = event.Producer.name;
      },
      onFailure: () => console.log('Error'),
      showNotification,
    });
  };

  const getEventStadium = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventStadium(eventId),
      onSuccess: ({ stadium }) => {
        eventData.current.stadium = stadium;
      },
    });
  };

  const getProducerStadiums = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerStadiums(producerId),
      onSuccess: ({ stadiums }) => {
        producerData.current.stadiums = stadiums;
      },
      showNotification,
    });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    await getProducerStadiums();
    await getEventData();
    await getEventStadium();
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return {
    eventData: eventData.current,
    producerData: producerData.current,
    isLoading,
  };
};
