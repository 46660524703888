import { useCallback, useEffect, useRef, useState } from 'react';
import { differenceInYears, parse } from 'date-fns';
import { REGULAR_EXPRESSION } from '../constants/constants';
import { INPUT_TYPE } from '../constants/types';
import { IDENTIFICATION_TYPES_VALIDATIONS } from '../constants/identificationTypes';
import { isValidMail } from '../utils/utils';

export const useForm = (initialState = {}, dependencies = []) => {
  const formatInitialState = {};
  const initialErrorsState = {};
  const initialValuesState = {};

  Object.entries(initialState).forEach(
    ([field, { value = '', title, type, required = true, customValidation }]) => {
      formatInitialState[field] = {
        title,
        type,
        minValue: 0,
        maxValue: Infinity,
        value,
        error: '',
        required,
        customValidation,
      };
      initialErrorsState[field] = '';
      initialValuesState[field] = '';
    }
  );

  const [formState, setFormState] = useState(formatInitialState);

  const errorsState = useRef(initialErrorsState);

  const resetForm = () => {
    setFormState(formatInitialState);
    errorsState.current = initialErrorsState;
  };

  const isFormComplete = useCallback(() => {
    for (const field of Object.keys(formState)) {
      if (!formState[field].value && formState[field].required) return false;
    }
    return true;
  }, [formState]);

  const handleMultipleInputsChange = (fields) => {
    const newState = { ...formState };
    fields.forEach(({ name, value }) => {
      newState[name] = { ...newState[name], value };
    });
    setFormState(newState);
  };

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setFormState({
      ...formState,
      [target.name]: {
        ...formState[target.name],
        value,
      },
    });
  };

  const validatePassword = () => {
    const password = formState.password.value;
    const mail = formState.mail?.value || '';

    if (!password.match(REGULAR_EXPRESSION.PASSWORD_LENGHT_VALIDATION))
      return 'La contraseña debe tener al menos 8 caracteres.';

    if (!password.match(REGULAR_EXPRESSION.PASSWORD_VALIDATION))
      return 'La contraseña debe tener al menos una mayúscula, una minúscula, dos números y un carácter especial.';

    if (password.trim() === mail.trim()) return 'La contraseña no puede ser igual al mail.';

    return '';
  };

  const validateConfirmPassword = () => {
    const password = formState.password.value;
    const confirmPassword = formState.confirmPassword.value;
    return password === confirmPassword ? '' : 'Las contraseñas no coinciden.';
  };

  const validateMail = () => {
    const mail = formState.mail.value;
    if (mail !== mail.trim()) return 'El email no puede contener espacios.';
    return isValidMail(mail) ? '' : 'Este formato de email no es válido.';
  };

  const validateConfirmMail = () => {
    const mail = formState.mail.value.trim();
    const confirmMail = formState.confirmMail.value.trim();
    return mail === confirmMail ? '' : 'Los mails no coinciden.';
  };

  const validateAlias = () => {
    const alias = formState.alias.value;
    return alias.trim().match(REGULAR_EXPRESSION.ALIAS_VALIDATION)
      ? ''
      : 'El formato del alias es inválido, solo se permiten letras, números, guiones altos y bajos';
  };

  const validateAlphaText = (string) => {
    return string.trim().match(REGULAR_EXPRESSION.TEXT_VALIDATION)
      ? ''
      : 'Solo se permiten letras.';
  };

  const validateNumber = (number, minValue, maxValue) => {
    if (number < minValue) return `El valor mínimo es ${minValue}.`;
    if (number > maxValue) return `El valor máximo es ${maxValue}.`;
    return number.toString().replace(/\s/g, '').trim().match(REGULAR_EXPRESSION.NUMBER_VALIDATION)
      ? ''
      : 'Solo se permiten solo números.';
  };

  const validateFloat = (number, minValue, maxValue) => {
    if (number < minValue) return `El valor mínimo es ${minValue}.`;
    if (number > maxValue) return `El valor máximo es ${maxValue}.`;
    return number.toString().replace(/\s/g, '').trim().match(REGULAR_EXPRESSION.FLOAT)
      ? ''
      : 'Solo se permiten solo números.';
  };

  const validateAlphaNumeric = (string) => {
    return string.toString().trim().match(REGULAR_EXPRESSION.TEXT_AND_NUMBERS_VALIDATION)
      ? ''
      : 'Solo se permiten letras y números.';
  };

  const validateIdNumber = (number) => {
    const { regex, errorMessage } = IDENTIFICATION_TYPES_VALIDATIONS[formState.idType.value];

    return number.toString().trim().match(regex) ? '' : errorMessage;
  };

  const validateDate = (date) => {
    if (!date) return 'Ingrese una fecha válida.';
    if (new Date(date) < new Date()) return 'Ingrese una fecha no expirada.';
    return '';
  };

  const validateCreditCardExpirationDate = (date) => {
    const [month, year] = date.split('/');
    const expirationDate = new Date(`01/${month}/${year}`);
    if (isNaN(expirationDate)) return 'Ingrese una fecha válida.';
    if (expirationDate < new Date()) return 'Ingrese una fecha no expirada.';
    return '';
  };

  const validateBirthDate = (date) => {
    if (!date.match(REGULAR_EXPRESSION.DATE_VALIDATION)) return 'Formato inválido.';

    const birthDate = parse(date, 'dd/MM/yyyy', new Date());
    if (isNaN(birthDate)) return 'Ingrese una fecha válida.';
    const age = differenceInYears(new Date(), birthDate);
    if (age < 18) return 'Debe ser mayor de 18 años.';
    return '';
  };

  const inputTypeValidation = (value, type, minValue, maxValue) => {
    if (value === null || value === undefined) return '';

    switch (type) {
      case INPUT_TYPE.MAIL:
        return validateMail();
      case INPUT_TYPE.CONFIRM_MAIL:
        return validateConfirmMail();
      case INPUT_TYPE.PASSWORD:
        return validatePassword();
      case INPUT_TYPE.CONFIRM_PASSWORD:
        return validateConfirmPassword();
      case INPUT_TYPE.ALIAS:
        return validateAlias();
      case INPUT_TYPE.TEXT:
        return '';
      case INPUT_TYPE.ALPHA_NUMERIC:
        return validateAlphaNumeric(value);
      case INPUT_TYPE.NUMBER:
        return validateNumber(value, minValue, maxValue);
      case INPUT_TYPE.FLOAT:
        return validateFloat(value, minValue, maxValue);
      case INPUT_TYPE.DATE:
        return validateDate(value);
      case INPUT_TYPE.CREDIT_CARD_EXPIRATION_DATE:
        return validateCreditCardExpirationDate(value);
      case INPUT_TYPE.ALPHA:
        return validateAlphaText(value);
      case INPUT_TYPE.ID_NUMBER:
        return validateIdNumber(value);
      case INPUT_TYPE.BIRTH_DATE:
        return validateBirthDate(value);
      default:
        return '';
    }
  };

  const getFormValues = () => {
    return Object.entries(formState).reduce((formValues, [field, { value }]) => {
      formValues[field] = value;
      return formValues;
    }, {});
  };

  const validateInputType = (field) => {
    const { value, type, customValidation, minValue, maxValue } = formState[field];

    errorsState.current[field] =
      errorsState.current[field] || inputTypeValidation(value, type, minValue, maxValue);

    if (customValidation) {
      const formValues = getFormValues();
      errorsState.current[field] =
        errorsState.current[field] || customValidation(value, formValues);
    }
  };

  const validateRequiredInput = (field) => {
    const { value, required } = formState[field];
    const formValues = getFormValues();
    const isRequired = typeof required === 'function' ? required(formValues) : required;

    errorsState.current[field] =
      isRequired && (value === null || value === '' || value === undefined)
        ? 'Este campo es requerido.'
        : '';

    if (isRequired) validateInputType(field);
  };

  const validateRequiredInputs = () => {
    Object.keys(formState).forEach((field) => validateRequiredInput(field));
  };

  const setInputError = (field, error = '') => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        error: errorsState.current[field] || error,
      },
    }));
  };

  const setFormErrors = () => {
    Object.keys(formState).forEach((field) => setInputError(field));
  };

  const formHasErrors = () => Object.values(errorsState.current).some((error) => error);

  const validateFormInputs = () => {
    validateRequiredInputs();
    setFormErrors();
    return !formHasErrors();
  };

  const updateInitialStateByDependencies = () => {
    if (Object.keys(initialState).length > 0) {
      setFormState((prevState) => {
        const newState = { ...prevState };
        Object.entries(initialState).forEach(([field, initialValues]) => {
          if (newState[field]) {
            newState[field] = { ...newState[field], ...initialValues };
          }
        });
        return newState;
      });
    }
  };

  useEffect(() => updateInitialStateByDependencies(), dependencies);

  return {
    formState,
    formValues: getFormValues(),
    isFormComplete,
    validateFormInputs,
    handleInputChange,
    resetForm,
    setInputError,
    handleMultipleInputsChange,
  };
};
