import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { getUrlSearchParams, validateUserRole } from '../../utils/utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PRODUCER_SIDEBAR_SECTIONS, USER_PERMISSIONS } from '../../constants/types';
import { useAuth } from '../../Providers/AuthProvider/AuthProvider';

export const useSidebar = (sections) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(width >= theme.breakpoints.values.sm);
  const { tab } = getUrlSearchParams();
  const initialSection = validateUserRole(user.role, USER_PERMISSIONS.ADMIN)
    ? PRODUCER_SIDEBAR_SECTIONS.DASHBOARD
    : PRODUCER_SIDEBAR_SECTIONS.ACTIVE_EVENTS;
  const [sectionSelected, setSectionSelected] = useState(tab || initialSection);
  const [selectedSectionComponent, setSelectedSectionComponent] = useState(
    sections.find(({ key }) => key === sectionSelected).component
  );

  const handleClickSection = (section) => {
    setSectionSelected(section);
    const newComponent = sections.find(({ key }) => key === section).component;
    setSelectedSectionComponent(newComponent);
    window.history.replaceState(null, null, `?tab=${section}`);
  };

  const openNewURL = (url) => {
    window.open(url, '_blank');
  };

  const handleToggleSidebarOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(width >= theme.breakpoints.values.sm);
  }, [width]);

  return {
    open,
    sectionSelected,
    selectedSectionComponent,
    openNewURL,
    handleClickSection,
    handleToggleSidebarOpen,
  };
};
