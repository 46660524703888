import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    totalRevenueContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      filter: 'drop-shadow(0px 4px 10px rgba(174, 166, 166, 0.25))',
    },
    totalRevenueHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
      rowGap: 16,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '12px 12px 0px 0px',
    },
    tableTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
      color: theme.palette.primary.contrastText,
    },
    tableSubtitle: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '39px',
      color: theme.palette.primary.contrastText,
    },
    totalRevenueBody: {
      width: '100%',
      display: 'flex',
      backgroundColor: theme.palette.grey[50],
      borderRadius: '0px 0px 12px 12px',
      '&> :first-child': {
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        borderRight: `1px solid ${theme.palette.grey[200]}`,
      },
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        '&> :first-child': {
          border: 0,
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },
    tableCell: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '4px 0',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'space-between',
        padding: '4px 15px',
      },
    },
    cellLabel: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '39px',
    },
    cellValue: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '39px',
    },
  });

export default styles;
