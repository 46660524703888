import React from 'react';
import { Typography, useTheme, withStyles } from '@material-ui/core';
import styles from './SalesReportCardStyles';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { TICKET_TYPE } from '../../../../constants/types';
import priceProvider from '../../../../Providers/Price/Price';

import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const SalesReportCard = ({ classes, data }) => {
  const { name, onlyOnline, totalSold, totalStock, type, price, currency } = data;

  const theme = useTheme();

  if (totalSold === 0 && !totalStock) return null;

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInfo}>
        <div>
          <VTypography linesNumber={3} color={theme.palette.charts.text} variant="subtitle5">
            {name}
          </VTypography>
          <VTypography variant="body2" linesNumber={3} color={theme.palette.text.secondary}>
            {`${priceProvider.getPrice(price, currency)} ${currency}`}
          </VTypography>
        </div>
        {totalStock && (
          <div className={classes.eventInfo}>
            <Typography>{onlyOnline ? 'Online' : 'Online y Cash'}</Typography>
            <Typography>
              {totalSold}/{totalStock}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.graphicContainer}>
        {totalStock ? (
          <ProgressCircle totalValue={totalStock} currentValue={totalSold} />
        ) : (
          <div className={classes.totalSoldContainer}>
            <Typography className={classes.totalSoldValue}>{totalSold}</Typography>
            <Typography className={classes.totalSoldLabel}>
              {type === TICKET_TYPE.INVITATION ? 'enviadas' : 'vendidas'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SalesReportCard);
