import React from 'react';

import { withStyles } from '@material-ui/core';
import { Stack, Box } from '@mui/material';
import ReactCrop from 'react-image-crop';
import styles from './VImageUploader.styles';
import VModal from '../VModal/VModal';

import 'react-image-crop/dist/ReactCrop.css';
import { useVImageUploader } from './hook';
import Button from '../../Components/Buttons/Button/Button';
import VIcon from '../VIcon/VIcon';
import VTypography from '../VTypography/VTypography';
import FormItemHeader from '../../Components/FormItemHeader/FormItemHeader';
import VIconButton from '../../Components/Buttons/IconButton/IconButton';

const VImageUploader = ({
  classes,
  imageWidth,
  imageHeight,
  width,
  height,
  maxHeight,
  maxWidth,
  className,
  initialImageSrc,
  title,
  description,
  required,
  onChange,
  name,
  canDelete = false,
}) => {
  const aspect = imageHeight ? imageWidth / imageHeight : 1;

  const {
    crop,
    previewCanvasRef,
    imageSrc,
    open,
    imgRef,
    isDragActive,
    handleAccept,
    handleSearchFile,
    handleClose,
    onCropChange,
    onCropComplete,
    onImageLoad,
    getInputProps,
    getRootProps,
    handleDeleteImage,
  } = useVImageUploader(aspect, onChange, initialImageSrc, name);

  return (
    <Stack>
      <FormItemHeader title={title} description={description} required={required} />

      <Box width={width} {...getRootProps()} position="relative">
        <input {...getInputProps()} />

        {imageSrc && canDelete && (
          <Stack className={classes.deleteButton}>
            <VIconButton
              onClick={handleDeleteImage}
              Icon={<VIcon name="delete" color="white" />}
              variant="text"
            />
          </Stack>
        )}
        <Stack
          className={`${classes.canvasContainer} ${className}`}
          onClick={handleSearchFile}
          height={height || width}
          width={width}
          maxHeight={maxHeight || maxWidth}
          maxWidth={maxWidth}
        >
          <Stack className={`uploaderText ${imageSrc ? 'showOnHover' : 'showAlways'}`}>
            <VIcon name="upload" size={30} />

            {!isDragActive && (
              <VTypography variant="subtitle6">Arrastrá o subí la imagen</VTypography>
            )}
            {isDragActive && <VTypography variant="subtitle6">Soltá la imagen</VTypography>}
            <VTypography variant="subtitle6">
              Recomendado {`${imageWidth}x${imageHeight}`}
            </VTypography>
          </Stack>
          <canvas id="canvas-red" ref={previewCanvasRef} className={classes.canvas} />
        </Stack>
        <VModal open={open} onClose={handleClose} width="auto" title="Selecciona la imagen">
          <Stack width="100%" alignItems="center" justifyContent="center">
            <ReactCrop
              crop={crop}
              aspect={aspect}
              className={classes.cropper}
              ruleOfThirds
              onChange={onCropChange}
              onComplete={onCropComplete}
              onImageLoaded={onImageLoad}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imageSrc}
                className={classes.cropperImagePreview}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Stack>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} gap={1}>
            <Button
              size="small"
              title="Cancelar"
              variant="outlined"
              onClick={handleClose}
              fullWidth
            />
            <Button size="small" title="Aceptar" onClick={handleAccept} fullWidth />
          </Stack>
        </VModal>
      </Box>
    </Stack>
  );
};

export default withStyles(styles)(VImageUploader);
