import React from 'react';
import { Stack } from '@mui/material';
import VTextInput from '../VInputs/VTextInput/VTextInput';
import Button from '../../Components/Buttons/Button/Button';
import VModal from '../VModal/VModal';

const FormModal = ({
  open,
  okLabel = 'Ok',
  cancelLabel = 'Cancelar',
  title,
  subtitle,
  handleClose,
  onSubmit,
  okDisabled,
  form,
}) => {
  const { formState, handleInputChange } = form;

  return (
    <VModal open={open} onClose={handleClose} title={title} subtitle={subtitle}>
      <>
        {Object.entries(formState).map(([name, { type, title }]) => (
          <VTextInput
            key={name}
            title={title}
            errorMessage={formState[type]?.error}
            onChange={handleInputChange}
            type={type}
            name={name}
            defaultValue={formState[type]?.value}
          />
        ))}

        <Stack width="100%" gap={1} justifyContent="space-between">
          <Button fullWidth title={cancelLabel} onClick={handleClose} variant="outlined" />
          <Button
            fullWidth
            title={okLabel}
            disabled={okDisabled}
            onClick={onSubmit}
            variant="contained"
          />
        </Stack>
      </>
    </VModal>
  );
};

export default FormModal;
