import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './ChangePasswordPageStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { useForm } from '../../../hooks/useForm';
import Button from '../../../Components/Buttons/Button/Button';
import { handleRequestHelper } from '../../../utils/helpers';
import history from '../../../appHistory';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { getUrlSearchParams } from '../../../utils/utils';
import api from '../../../api/api';

const ChangePasswordPage = ({ classes }) => {
  const { token, mail } = getUrlSearchParams();
  const { showNotification } = useNotifications();

  const [isChangePasswordButtonDisabled, setIsChangePasswordButtonDisabled] = useState(true);
  const { formState, formValues, handleInputChange, isFormComplete, validateFormInputs } = useForm({
    password: {
      title: 'Contraseña',
      type: INPUT_TYPE.PASSWORD,
    },
    confirmPassword: {
      title: 'Confirmar contraseña',
      type: INPUT_TYPE.CONFIRM_PASSWORD,
    },
  });

  const changePassword = async () => {
    setIsChangePasswordButtonDisabled(true);

    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      setIsChangePasswordButtonDisabled(false);
      return;
    }

    const changePasswordBody = {
      resetPasswordToken: token,
      newPassword: formState.password.value,
      mail,
    };

    await handleRequestHelper({
      endpoint: () => api.changePassword(changePasswordBody),
      onSuccess: () => {
        showNotification(
          'La contraseña fue modificada con éxito. Te redireccionaremos al Log In.',
          NOTIFICATION_VARIANT.SUCCESS
        );
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      },
    });

    setIsChangePasswordButtonDisabled(false);
  };

  useEffect(() => {
    setIsChangePasswordButtonDisabled(!isFormComplete());
  }, [formValues]);

  return (
    <PageContainer title="Cambio de Contraseña">
      <Box
        paddingBottom={14}
        paddingX={2}
        height="auto"
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className={classes.changePasswordPage}
      >
        <Box gap={3} display="flex" flexDirection="column">
          <Typography className="changePasswordPageTitle">
            Restablecé <br /> tu contraseña
          </Typography>
          <Typography className="changePasswordPageTexts">
            Ingresá una nueva contraseña para poder acceder a tu cuenta.
          </Typography>
          <form>
            <Box display="flex" gap={2} flexDirection="column">
              <VTextInput
                title={formState.password.title}
                onChange={handleInputChange}
                type="password"
                name="password"
                errorMessage={formState.password.error}
                hint="Debe incluir al menos 8 caracteres, una mayúscula, una minúscula, un carácter especial y 2 números."
                autoComplete="new-password-confirm"
              />
              <VTextInput
                title={formState.confirmPassword.title}
                onChange={handleInputChange}
                type="password"
                name="confirmPassword"
                errorMessage={formState.confirmPassword.error}
                autoComplete="new-password-confirm"
              />
            </Box>
          </form>
        </Box>
        <Button
          fullWidth
          title="Cambiar contraseña"
          onClick={changePassword}
          disabled={isChangePasswordButtonDisabled}
        />
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(ChangePasswordPage);
