import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack, TextField } from '@mui/material';
import styles from './VTextArea.styles';
import VTypography from '../../VTypography/VTypography';
import FormItemHeader from '../../../Components/FormItemHeader/FormItemHeader';

const VTextArea = ({
  classes,
  title,
  leftIcon,
  required,
  errorMessage,
  successMessage,
  description,
  minRows = 2,
  hint,
  showHelpText = true,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Stack flex={1} height="100%">
      <FormItemHeader
        title={title}
        leftIcon={leftIcon}
        required={required}
        description={description}
      />
      <TextField
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        multiline
        maxRows={9}
        disabled={false}
        minRows={minRows}
        fullWidth
        variant="outlined"
        className={`${classes.textField} ${errorMessage && classes.error}`}
        {...props}
      />
      {showHelpText && (
        <Box className="bottomInputBox">
          <VTypography
            variant="caption"
            color={(errorMessage && 'error') || (successMessage && 'success')}
          >
            {errorMessage || successMessage || (isFocused && hint)}
          </VTypography>
        </Box>
      )}
    </Stack>
  );
};

export default withStyles(styles)(VTextArea);
