import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      maxWidth: '90%',
      margin: '0 auto',
      display: 'grid',
      gap: 32,
      gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
      marginTop: 32,
    },
  });

export default styles;
