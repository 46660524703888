import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    notFoundSellerText: {
      flex: 1,
      gap: 24,
      padding: 16,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    contentContainer: {
      zIndex: 1,
      margin: '56px 0 0',
      alignItems: 'center',
      alignSelf: 'center',
      gap: 16,
    },
    ticketsContainer: {
      width: '100%',
      gap: 16,
      margin: '40px 0 20px',
      '& > *:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    descriptionContainer: {
      width: '100%',
      gap: 16,
      margin: '24px 0 56px 0',
      wordBreak: 'break-word',
    },
    descriptionImage: {
      maxWidth: 348,
      width: '100%',
      aspectRatio: 4 / 5,
      borderRadius: 8,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  });

export default styles;
