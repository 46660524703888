import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from './TitledRadioButtons.styles';
import FormItemHeader from '../../Components/FormItemHeader/FormItemHeader';

class TitledRadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelperText: false,
    };
  }

  render() {
    const { classes } = this.props;
    const currentValue = this.props.currentValue ? this.props.currentValue : undefined;

    function HelperText(props) {
      if (props.helperText !== '' && (props.showHelperText || props.showErrors)) {
        return <FormHelperText className={classes.helper}>{props.helperText}</FormHelperText>;
      }
      return null;
    }

    function RadioItems(props) {
      if (props.values) {
        return (
          <div>
            <RadioGroup
              aria-label={props.name}
              name={props.name}
              value={currentValue}
              onChange={props.onChange}
              row={props.row}
            >
              {props.values.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </div>
        );
      }
      return null;
    }

    function RadioItemsWithImages(props) {
      if (props.values) {
        return (
          <div>
            <RadioGroup
              aria-label={props.name}
              name={props.name}
              value={currentValue}
              onChange={props.onChange}
              className={classes.radioItems}
              row={props.row}
            >
              {props.values.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  labelPlacement="top"
                />
              ))}
            </RadioGroup>
          </div>
        );
      }
      return null;
    }

    return (
      <div>
        <FormItemHeader
          title={this.props.title}
          leftIcon={this.props.icon}
          required={this.props.required}
          description={this.props.description}
        />
        <FormControl margin="none" required fullWidth disabled={this.props.disabled}>
          {this.props.withImages ? (
            <RadioItemsWithImages
              values={this.props.values}
              name={this.props.name}
              currentValue={this.props.currentValue}
              onChange={this.props.onChange}
              row={this.props.row}
            />
          ) : (
            <RadioItems
              values={this.props.values}
              name={this.props.name}
              currentValue={this.props.currentValue}
              onChange={this.props.onChange}
              row={this.props.row}
            />
          )}
        </FormControl>
        <HelperText
          showHelperText={this.state.showHelperText}
          showErrors={this.props.showErrors}
          helperText={this.props.helperText}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TitledRadioButtons);
