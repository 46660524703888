import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';

const { v4: uuidv4 } = require('uuid');

export const useVenueAutocomplete = (onSelectOption) => {
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userLocation, setUserLocation] = useState({});
  const [session] = useState(uuidv4());
  const [suggestedVenues, setSuggestedVenues] = useState([]);

  const { producerId } = useParams();

  const isLoading = isLoadingOptions || isLoadingVenue;

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const getProducerVenues = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerVenues(producerId),
      onSuccess: ({ venues }) => {
        setSuggestedVenues(venues);
      },
    });
  };

  useEffect(() => {
    getUserLocation();
    getProducerVenues();
  }, []);

  const getVenues = async (searchQuery) => {
    setSearchQuery(searchQuery);

    if (!searchQuery) {
      setVenueOptions([]);
      return;
    }
    setIsLoadingOptions(true);

    await handleRequestHelper({
      endpoint: () => api.searchVenue({ searchQuery, session, ...userLocation }),
      onSuccess: ({ places }) => {
        const options = places.map(({ description, placeId }) => ({
          label: description,
          placeId,
          ...userLocation,
        }));
        options.push({ label: `Usar "${searchQuery}"`, isPlaceName: true });
        setVenueOptions(options);
      },
    });

    setIsLoadingOptions(false);
  };

  const onChangeSearch = useMemo(
    () => debounce(({ target: { value } }) => getVenues(value), 500),
    [getVenues]
  );

  const handleOpenAutocomplete = () => setOpenAutocomplete(true);

  const handleCloseAutocomplete = () => {
    onSelectOption(null);
    setSearchQuery('');
    setVenueOptions([]);
    setOpenAutocomplete(false);
  };

  const handleSelectSuggestedOption = (suggestedVenue) => onSelectOption(suggestedVenue);

  return {
    openAutocomplete,
    onChangeSearch,
    venueOptions,
    searchQuery,
    isLoading,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
    handleSelectSuggestedOption,
    setIsLoadingVenue,
    suggestedVenues,
  };
};
