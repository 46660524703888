import { removeStringSpaces } from '../../../../utils/utils';

export const CARD_PATTERNS = {
  AMEX: {
    mask: '9999 999999 99999',
    cardLength: 15,
  },
  DEFAULT: {
    mask: '9999 9999 9999 9999',
    cardLength: 16,
  },
};
export const checkIsLemonCard = (cardNumber) => {
  // const LEMON_BIN = '434532'; // '40403100'; TO REDO
  const EXCUSIVE_BINS_8_DIGITS = ['53314904', '53207511', '42039000', '40787400'];
  const EXCUSIVE_BINS_6_DIGITS = ['512444', '521219', '533130'];
  // return cardNumber.startsWith(LEMON_BIN);
  return (
    EXCUSIVE_BINS_8_DIGITS.includes(cardNumber.slice(0, 8)) ||
    EXCUSIVE_BINS_6_DIGITS.includes(cardNumber.slice(0, 6))
  );
};

export const checkIsAmexCard = (cardNumber) =>
  cardNumber.slice(0, 2) === '37' || cardNumber.slice(0, 2) === '34';

export const getCardType = (cardNumber = '') => {
  const cardNumberWithoutSpaces = removeStringSpaces(cardNumber);

  const isLemonCard = checkIsLemonCard(cardNumberWithoutSpaces);
  const cardPattern = checkIsAmexCard(cardNumberWithoutSpaces)
    ? CARD_PATTERNS.AMEX
    : CARD_PATTERNS.DEFAULT;
  return {
    ...cardPattern,
    isLemonCard,
  };
};

export const getMonthAndYearFromCardExpirationDate = (cardExpirationDate) => {
  const [month, year] = cardExpirationDate.split('/');
  return { month, year };
};
