import { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';
import { NOTIFICATION_VARIANT } from '../../../constants/types';

export const useSelfManagedRefundBottomSheet = (orderId, canRefund, onClose, getEventQrs) => {
  const [orderInfo, setOrderInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isRefunding, setIsRefunding] = useState(false);
  const { showNotification } = useNotifications();

  const getOrderInfo = async () => {
    setIsLoading(true);
    if (canRefund)
      await handleRequestHelper({
        useNotifications,
        endpoint: () => api.getOrderById(orderId),
        onSuccess: ({ order }) => {
          const ticketCounts = order.tickets.reduce((acc, { name }) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc;
          }, {});

          setOrderInfo({
            payment: order.payment.tickets,
            tickets: ticketCounts,
          });
        },
      });

    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
  };

  const handleRefund = async () => {
    setIsRefunding(true);
    await handleRequestHelper({
      showNotification,
      endpoint: () => api.selfManagedRefundOrder(orderId),
      onSuccess: () => {
        onClose();
        showNotification('Devolución exitosa', NOTIFICATION_VARIANT.SUCCESS);
        getEventQrs();
      },
    });
    setIsRefunding(false);
  };

  useEffect(() => {
    getOrderInfo();
  }, [orderId]);

  return {
    handleClose,
    orderInfo,
    isLoading,
    isRefunding,
    handleRefund,
  };
};
