import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';
import { useBulkCreateUsersPage } from './hook';
import CSVUploaderModal from '../../../Components/Modals/CSVUploaderModal/CSVUploaderModal';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import CustomTable from '../../../Components/Tables/CustomTable';
import { ROLE_OPTIONS } from './constants';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import Button from '../../../Components/Buttons/Button/Button';
import EventsAccessesAccordion from '../../../Components/Accordions/EventsAccessesAccordion/EventsAccessesAccordion';
import FormItemHeader from '../../../Components/FormItemHeader/FormItemHeader';
import Alert from '../../../Venti-UI-Kit/Alert/Alert';

const BulkCreateUsersPage = ({ classes }) => {
  const {
    producer,
    openCSVUploadModal,
    handleUploadCSVFile,
    handleCloseCSVUploadModal,
    handleUploadUsersByCSV,
    tableColumns,
    usersList,
    isCreating,
    handleBulkCreateUsers,
    handleRoleSelectorChange,
    role,
    events,
    openEventsAccordion,
    handleEventsAccordionChange,
    isCreationAvailable,
    selectedProducersEvents,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    getAmountOfSelectedEventsByProducerId,
    showUsersErrorAlert,
    hasErrors,
  } = useBulkCreateUsersPage();

  const { producerId } = useParams();

  return (
    <PageContainer title="Backoffice - Carga masiva de usuarios">
      <VBreadcrumbs pageTitle="Carga masiva de usuarios" />
      <Stack alignItems="center" gap={3} px={1} marginBottom={12}>
        <BackofficePageTitle mainTitle={producer?.name} secondaryTitle="Carga masiva de usuarios" />
        <Stack className={classes.container} gap={2}>
          {showUsersErrorAlert && (
            <Alert
              severity="info"
              message="Tras la creación de usuarios, la tabla mostrará aquellos que no pudieron ser
                  creados, detallando el motivo del fallo. El resto de los usuarios se crearon
                  exitosamente."
            />
          )}
          <Stack
            direction={{ md: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems={{ md: 'left', lg: 'center' }}
            gap={2}
          >
            <Stack minWidth={250}>
              <VSelectInput
                title="Rol de los usuarios"
                onChange={handleRoleSelectorChange}
                name="role"
                options={ROLE_OPTIONS}
                value={role}
                showHelpText={false}
              />
            </Stack>
            <Stack width={{ md: '100%', lg: '40%' }}>
              <FormItemHeader title="Permitir acceso a los siguientes eventos" />
              <EventsAccessesAccordion
                producerId={producerId}
                events={events}
                expanded={openEventsAccordion}
                onChange={handleEventsAccordionChange}
                getAmountOfSelectedEventsByProducerId={getAmountOfSelectedEventsByProducerId}
                modifyAllSelectedProducerEventsStates={modifyAllSelectedProducerEventsStates}
                selectedProducersEvents={selectedProducersEvents}
                modifySelectedProducerEventState={modifySelectedProducerEventState}
                className={classes.eventAccessSelector}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignSelf={{ md: 'start', lg: 'end' }}
              gap={1}
            >
              {!usersList.length && (
                <Button onClick={handleUploadCSVFile} title="Cargar CSV" minWidth={210} />
              )}
              {!!usersList.length && (
                <Button
                  onClick={handleBulkCreateUsers}
                  loading={isCreating}
                  title="Crear Usuarios"
                  minWidth={130}
                  disabled={!isCreationAvailable}
                />
              )}
            </Stack>
          </Stack>
          <CustomTable
            data={usersList}
            columns={tableColumns}
            noMatch="No has subido ningún CSV todavía"
            showDownloadButton={hasErrors}
            csvName="ErrorCreacionUsuarios.csv"
          />
          {isCreating && (
            <VTypography>
              <strong>No refresque la página.</strong> Estamos procesando la creación, puede demorar
              unos segundos.
            </VTypography>
          )}
        </Stack>
        {openCSVUploadModal && (
          <CSVUploaderModal
            handleOpen={openCSVUploadModal}
            title="Subir archivo CSV"
            confirmButtonTitle="Cargar"
            handleClose={handleCloseCSVUploadModal}
            handleConfirm={handleUploadUsersByCSV}
            columns={tableColumns}
            message={
              <span>
                Subí tu archivo CSV para crear múltiples usuarios, el mismo deberá tener{' '}
                {producer?.generatesSellerReports
                  ? "las columnas 'Mail' y 'Team Leader'"
                  : "la columna 'Mail'"}
                . Los usuarios se crearán al presionar 'Cargar'.
                <br />
                <b>Importante: el mismo no debe constar de espacios vacíos.</b>
              </span>
            }
          />
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(BulkCreateUsersPage);
