import { useState } from 'react';
import { WHITE_LABELS } from '../../../../constants/whiteLabels';
import darkTheme from '../../../../themes/darkTheme';
import lightTheme from '../../../../themes/lightTheme';

export const useAppearanceForm = (onChange, formState) => {
  const [selectedTheme, setSelectedTheme] = useState(formState?.theme.value);

  const handleSelectTheme = ({ target }) => {
    setSelectedTheme(target.value);
    onChange({ target });
  };

  const fontColor =
    selectedTheme === WHITE_LABELS.DARK_VENTI
      ? darkTheme.palette.text.primary
      : lightTheme.palette.text.primary;

  const bgColor =
    selectedTheme === WHITE_LABELS.DARK_VENTI
      ? darkTheme.palette.background.default
      : lightTheme.palette.background.default;

  return {
    selectedTheme,
    bgColor,
    fontColor,
    handleSelectTheme,
  };
};
