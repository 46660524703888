import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import EventForm from '../../../Components/Forms/EventForm/EventForm';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import { FORM_MODE } from '../../../Components/Forms/EventForm/constants';
import { useDuplicateEventPage } from './hook';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const DuplicateEventPage = () => {
  const { isLoading, eventData, producerData } = useDuplicateEventPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Duplicar Evento">
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Duplicar Evento">
      <VBreadcrumbs pageTitle="Duplicar Evento" />
      <Stack alignItems="center" gap={3} px={2}>
        <BackofficePageTitle mainTitle={eventData.name} secondaryTitle="Duplicar Evento" />
        <EventForm
          eventData={eventData}
          producerData={producerData}
          buttonLabel="Duplicar"
          mode={FORM_MODE.DUPLICATE}
        />
      </Stack>
    </PageContainer>
  );
};

export default DuplicateEventPage;
