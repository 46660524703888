import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Stack } from '@mui/material';
import UploadOrderCard from '../../../../Components/Cards/UploadOrderCard/UploadOrderCard';
import { TICKET_ACTIVITY_STATUS, TICKET_TYPE } from '../../../../constants/types';
import { userIsBlacklistedFromEvent } from '../../../../utils/utils';
import styles from './CashSaleStyles';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import { handleRequestHelper } from '../../../../utils/helpers';
import api from '../../../../api/api';
import history from '../../../../appHistory';
import BackofficePageTitle from '../../../../Components/BackofficePageTitle/BackofficePageTitle';

const CashSale = ({ urlParams }) => {
  const { user } = useAuth();
  const { eventId, producerId } = urlParams;
  const { showNotification } = useNotifications();

  const [ticketTypes, setTicketTypes] = useState(null);
  const [event, setEvent] = useState(null);

  const getEvent = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      showNotification,
      onSuccess: ({ event }) => {
        userIsBlacklistedFromEvent(event, user) && history.push('/error/401');

        const ticketTypes = event.TicketTypes?.filter(
          ({ activityStatus, type, onlyOnline }) =>
            activityStatus !== TICKET_ACTIVITY_STATUS.ARCHIVED &&
            type !== TICKET_TYPE.INVITATION &&
            !onlyOnline
        );

        setEvent(event);
        setTicketTypes(ticketTypes);
      },
      onFailure: () => history.push('/error/404'),
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <PageContainer title="Backoffice - Venta Cash">
      <VBreadcrumbs pageTitle="Venta Cash" />
      <Stack alignItems="center" gap={3} px={2}>
        <BackofficePageTitle mainTitle={event?.name} secondaryTitle="Venta Cash" />

        <UploadOrderCard
          eventId={event?.id}
          ticketTypes={ticketTypes}
          showNotification={showNotification}
          user={user}
          producerId={producerId}
        />
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(CashSale);
