import { useEffect, useState } from 'react';
import { formatDatetime } from '../../../utils/utils';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { CURRENCY } from '../../../constants/types';

export const useFinancialOperationsPage = (urlParams) => {
  const { eventId } = urlParams;
  const [financialOperations, setFinancialOperations] = useState([]);
  const [initialFinancialOperations, setInitialFinancialOperations] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [currency, setCurrency] = useState(CURRENCY.ARS);
  const [eventName, setEventName] = useState('');
  const { showNotification } = useNotifications();

  const formatFinancialOperationDate = (financialOperation) => {
    const { date: createdAt } = formatDatetime(financialOperation.createdAt);

    return {
      ...financialOperation,
      createdTimestamp: new Date(financialOperation.createdAt),
      createdAt,
    };
  };

  const getEvent = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        setEventName(event.name);
        setCurrency(event.currency);
      },
    });
  };

  const getFinancialOperations = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFinancialOperations(eventId),
      onSuccess: ({ financialOperations }) => {
        const financialOperationsData = financialOperations.map((financialOperation) => {
          return formatFinancialOperationDate(financialOperation);
        });

        setFinancialOperations(financialOperationsData);
        setInitialFinancialOperations(JSON.parse(JSON.stringify(financialOperationsData)));
      },
      showNotification,
    });
  };

  useEffect(() => {
    getEvent();
    getFinancialOperations();
  }, []);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleCreateFinancialOperation = async (financialOperation) => {
    financialOperation = {
      ...financialOperation,
      paymentDate: financialOperation.paymentDate || null,
      currency,
      eventId,
      notes: financialOperation.notes || null,
    };

    await handleRequestHelper({
      endpoint: () => api.createFinancialOperation(financialOperation),
      onSuccess: ({ financialOperation }) => {
        financialOperation = formatFinancialOperationDate(financialOperation);
        setFinancialOperations((prevState) => [...prevState, financialOperation]);
        setInitialFinancialOperations((prevState) => [...prevState, { ...financialOperation }]);
        showNotification('La operación fue creada con éxito.', 'success');
      },
      showNotification,
    });
  };

  return {
    eventName,
    financialOperations,
    setFinancialOperations,
    initialFinancialOperations,
    setInitialFinancialOperations,
    openCreateModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleCreateFinancialOperation,
    eventCurrency: currency,
  };
};
