import React from 'react';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const AutocompleteInput = ({ classes, onChange, showError, ...props }) => {
  return (
    <VTextInput
      {...props}
      name="placeName"
      onChange={onChange}
      showHelpText={false}
      errorMessage={showError}
      placeholder="Busca tu venue"
    />
  );
};

export default AutocompleteInput;
