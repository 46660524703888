import { useEffect, useState } from 'react';
import useProducers, { NO_TEAM_LEADER } from '../../../hooks/useProducers';
import { useEventsAccessesAccordion } from '../../Accordions/EventsAccessesAccordion/hook';

const NO_TEAM_LEADER_ID = 0;

export const useProducersListForm = ({ currentProducerId, initialUserData = {} }) => {
  const { initialProducers, initialTeamLeaders, initialEventsAccesses } = initialUserData;
  const [selectedTeamLeaders, setSelectedTeamLeaders] = useState(initialTeamLeaders);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [selectedProducers, setSelectedProducers] = useState({
    ...(!initialProducers && { [currentProducerId]: true }),
    ...initialProducers,
  });

  const {
    selectedProducersEvents,
    setSelectedProducersEvents,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    getAmountOfSelectedEventsByProducerId,
  } = useEventsAccessesAccordion(initialEventsAccesses);

  const { producers, getUserProducers } = useProducers(currentProducerId);

  useEffect(() => getUserProducers(), []);

  const setInitialProducersData = () => {
    const formattedProducerTeamLeader = {};
    const formattedProducersEvents = {};

    Object.values(producers).forEach(({ id: producerId, events, teamLeaders }) => {
      formattedProducersEvents[producerId] = { allEvents: true };

      events?.forEach(({ id: eventId }) => {
        formattedProducersEvents[producerId] = {
          ...formattedProducersEvents[producerId],
          [eventId]: true,
        };
      });

      const noTeamLeader = teamLeaders?.find(
        ({ teamLeaderId }) => teamLeaderId === NO_TEAM_LEADER_ID
      );

      formattedProducerTeamLeader[producerId] = noTeamLeader;
    });

    const initialEventsMappedByProducerId = {};

    Object.keys(formattedProducersEvents).forEach((producerId) => {
      initialEventsMappedByProducerId[producerId] = {
        ...formattedProducersEvents[producerId],
        ...(initialEventsAccesses && initialEventsAccesses[producerId]),
      };
    });

    setSelectedProducersEvents(initialEventsMappedByProducerId);
    setSelectedTeamLeaders((prevState) => ({ ...formattedProducerTeamLeader, ...prevState }));
    setIsLoadingData(false);
  };

  useEffect(() => {
    producers && producers.length && setInitialProducersData();
  }, [producers, initialUserData]);

  useEffect(() => {
    setSelectedProducers({
      ...(!initialProducers && { [currentProducerId]: true }),
      ...initialProducers,
    });
    setSelectedTeamLeaders((prevState) => ({ ...prevState, ...initialTeamLeaders }));
  }, [initialUserData]);

  const handleToggleProducer = ({ target }) => {
    const { checked, name: producerId } = target;

    setSelectedProducers((prevState) => ({ ...prevState, [producerId]: checked }));
    if (selectedTeamLeaders[producerId])
      setSelectedTeamLeaders((prevState) => ({
        ...prevState,
        [producerId]: {
          ...NO_TEAM_LEADER,
          initialTeamLeaderId: prevState[producerId].initialTeamLeaderId,
        },
      }));
  };

  const handleSelectTeamLeader = (producerId, teamLeader) => {
    setSelectedTeamLeaders((prevState) => ({
      ...prevState,
      [producerId]: {
        ...prevState[producerId],
        ...teamLeader,
      },
    }));
  };

  return {
    selectedProducersEvents,
    selectedTeamLeaders,
    selectedProducers,
    producers,
    isLoadingData,
    getAmountOfSelectedEventsByProducerId,
    handleToggleProducer,
    handleSelectTeamLeader,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
  };
};
