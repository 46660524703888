import React from 'react';
import { Stack } from '@mui/material';
import StadiumImage from '../../StadiumImage/StadiumImage';
import SectionForm from '../SectionForm/SectionForm';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../utils/utils';
import { USER_PERMISSIONS } from '../../../constants/types';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const StadiumCustomizer = ({ stadium }) => {
  const { user } = useAuth();
  const isSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);

  if (!stadium?.id) return null;

  return (
    <Stack gap={3} direction={{ xs: 'column', md: 'row' }}>
      <Stack flex={1.8} alignItems="center" justifyContent="center">
        <Stack maxWidth={320}>
          <StadiumImage image={stadium.image} />
        </Stack>
      </Stack>
      <Stack gap={2.5} flex={2}>
        <VTypography variant="body1">
          {'Sectores: '}
          <strong>{stadium.numberOfSections}</strong>
          {isSuperAdmin && (
            <>
              {' Capacidad total: '}
              <strong>{stadium.capacity} loc.</strong>
            </>
          )}
        </VTypography>
        <Stack gap={1} paddingRight={1} maxHeight={320} overflow="auto">
          {stadium.sections.map((section) => (
            <SectionForm disabled={!isSuperAdmin} section={section} key={section.id} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StadiumCustomizer;
