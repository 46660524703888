import { useState } from 'react';
import { useForm } from '../../../../hooks/useForm';
import { INPUT_TYPE } from '../../../../constants/types';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';

export const useSendSettlementModal = (handleCreate, handleClose) => {
  const [receiverMails, setReceiverMails] = useState([]);
  const { formState, handleInputChange, validateFormInputs, resetForm } = useForm(
    {
      mail: { type: INPUT_TYPE.MAIL, required: true },
    },
    [receiverMails]
  );
  const { showNotification } = useNotifications();

  const addReceiverMail = (mail) => {
    const isFormValid = validateFormInputs();
    if (!isFormValid) return;
    if (receiverMails.some((receiverMail) => receiverMail === mail)) {
      showNotification('Este mail ya fue agregado', 'error');
      return;
    }

    setReceiverMails([...receiverMails, mail]);
    resetForm();
  };

  const removeReceiverMail = (mail) => {
    setReceiverMails(receiverMails.filter((receiverMail) => receiverMail !== mail));
  };

  const handleSend = () => {
    if (!receiverMails.length) return;

    handleCreate(receiverMails);
    resetForm();
    setReceiverMails([]);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    setReceiverMails([]);
    resetForm();
  };

  return {
    formState,
    handleInputChange,
    handleSend,
    handleCancel,
    receiverMails,
    addReceiverMail,
    removeReceiverMail,
  };
};
