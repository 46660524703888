import React, { useEffect } from 'react';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './CheckoutTicketCard.styles';
import { formatPriceWithCurrency } from '../../../utils/utils';
import { CURRENCY } from '../../../constants/types';

const CheckoutTicketCard = ({ classes, actions, ticket, checkCanContinue }) => {
  const {
    name,
    price,
    quantity,
    maxQuantityPerOrder,
    maxQuantity,
    soldUnits,
    currency,
    priceInUSD,
    groupSize,
  } = ticket;
  const { increaseTicketQuantity, decreaseTicketQuantity } = actions;

  const getPrice = () => {
    const priceToUse = currency === CURRENCY.USD ? priceInUSD : price;
    return formatPriceWithCurrency(priceToUse, currency);
  };
  useEffect(() => {
    checkCanContinue();
  }, [quantity]);

  const increaseQuantity = () => {
    increaseTicketQuantity(ticket);
  };

  const decreaseQuantity = () => {
    decreaseTicketQuantity(ticket);
  };

  const canIncreaseQuantity = (quantity) => {
    const stock = maxQuantity - soldUnits;
    return !(quantity >= maxQuantityPerOrder || quantity >= stock);
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.informationContainer}>
        <Typography className={classes.ticketNameLabel}>{name}</Typography>
        <Typography className={classes.priceLabel}>
          {price === 0 ? 'Gratis' : getPrice()}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>
        <IconButton
          size="small"
          className={classes.actionsText}
          onClick={() => decreaseQuantity()}
          aria-label="remove"
        >
          <RemoveIcon />
        </IconButton>
        <IconButton size="small" onClick={() => {}} aria-label="quantity">
          <Typography className={classes.actionsText} align="left" variant="body1">
            {quantity / (groupSize || 1)}
          </Typography>
        </IconButton>
        <IconButton
          size="small"
          onClick={() => increaseQuantity()}
          className={classes.actionsText}
          edge="end"
          aria-label="add"
          disabled={!canIncreaseQuantity(quantity)}
        >
          <AddIcon />
        </IconButton>
      </div>
      {!canIncreaseQuantity(quantity - 1) && (
        <Typography className={classes.errorMessage}>Cantidad máxima alcanzada</Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(CheckoutTicketCard);
