import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import StarterKit from '@tiptap/starter-kit';
import {
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
  RichTextEditor,
} from 'mui-tiptap';
import { Underline } from '@tiptap/extension-underline';
import { Stack } from '@mui/material';
import { Link } from '@tiptap/extension-link';
import styles from './styles';
import FormItemHeader from '../../../Components/FormItemHeader/FormItemHeader';
import VTypography from '../../VTypography/VTypography';

const VRichTextInput = ({
  classes,
  title,
  required,
  description,
  leftIcon,
  value,
  onChange,
  name,
  errorMessage,
  showHelpText = true,
  hint,
  onFocus,
  infoMessage,
}) => {
  const rteRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = ({ editor }) => {
    onChange({ target: { name, value: editor.getHTML() } });
  };

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Stack>
      <FormItemHeader
        title={title}
        required={required}
        description={description}
        leftIcon={leftIcon}
      />
      <RichTextEditor
        ref={rteRef}
        extensions={[
          StarterKit,
          Underline,
          LinkBubbleMenuHandler,
          Link.configure({
            openOnClick: false,
            autolink: true,
            defaultProtocol: 'https',
            protocols: ['http', 'https'],
          }),
        ]}
        className={`${classes.richTextEditor} ${errorMessage && classes.error}`}
        content={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onUpdate={handleChange}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonUnderline />
            <MenuButtonStrikethrough />
            <MenuDivider />
            <LinkBubbleMenu />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
            <MenuButtonEditLink />
            <MenuDivider />
            <MenuButtonUndo />
            <MenuButtonRedo />
          </MenuControlsContainer>
        )}
      />
      {showHelpText && (
        <Stack mt={0.5} minHeight={16}>
          <VTypography variant="caption" color={errorMessage && 'error'}>
            {errorMessage || (isFocused && hint) || infoMessage}
          </VTypography>
        </Stack>
      )}
    </Stack>
  );
};

export default withStyles(styles)(VRichTextInput);
