import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    VDateTimeInputContainer: {
      '& .react-datepicker-wrapper': {
        display: 'flex',
        alignItems: 'start',
        flex: 1,
      },
      '& .react-datepicker-popper': {
        paddingTop: 0,
        top: '-12px !important',
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__tab-loop': {
        position: 'absolute',
      },
      '& .react-datepicker': {
        width: 340,
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .react-datepicker__time-container': {
        width: '100%',
      },
      '& .react-datepicker__time-box': {
        width: '100% !important',
        margin: '0px !important',
      },
      '& .react-datepicker__time-list-item--selected': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
  });

export default styles;
