import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ChartLayout from '../ChartLayout';
import { useActiveEventsVolumeBarChart } from './hook';
import { formatLargeNumber } from '../../../utils/utils';

export const ActiveEventsVolumeBarChart = ({ producerId }) => {
  const { metrics, theme } = useActiveEventsVolumeBarChart(producerId);
  const { width } = useWindowDimensions();
  const isMobile = width <= theme.breakpoints.values.lg;

  const formatCurrencyValue = (value) => {
    const formatNumber = formatLargeNumber(value);
    return formatNumber === 0 ? '0' : `${formatNumber} ARS`;
  };

  const formatEventName = (value) => {
    return value.length > 10 ? `${value.slice(0, 10)}...` : value;
  };

  return (
    <ChartLayout title="Volumen por evento activo">
      {metrics?.length && isMobile && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={metrics} width={width} layout="vertical" barCategoryGap={4}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis dataKey="event" type="category" tickFormatter={formatEventName} />
            <XAxis type="number" dataKey="volume" tickFormatter={formatCurrencyValue} />
            <Tooltip
              formatter={(value) => {
                return [formatCurrencyValue(value), 'Volumen'];
              }}
              cursor={{ fill: 'transparent' }}
            />
            <Bar
              dataKey="volume"
              fill={theme.palette.primary.main}
              radius={[0, 8, 8, 0]}
              maxBarSize={70}
              activeBar={<Rectangle fill={theme.palette.primary.dark} />}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {metrics?.length && !isMobile && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={metrics} margin={{ right: 60, left: 30 }} barCategoryGap={4}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis interval={0} dataKey="event" tickFormatter={formatEventName} />
            <YAxis tickFormatter={formatCurrencyValue} />
            <Tooltip
              formatter={(value) => {
                return [formatCurrencyValue(value), 'Volumen'];
              }}
              cursor={{ fill: 'transparent' }}
            />
            <Bar
              dataKey="volume"
              fill={theme.palette.primary.main}
              radius={[8, 8, 0, 0]}
              maxBarSize={70}
              activeBar={<Rectangle fill={theme.palette.primary.dark} />}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartLayout>
  );
};
