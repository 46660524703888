import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import EventForm from '../../../Components/Forms/EventForm/EventForm';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import { FORM_MODE } from '../../../Components/Forms/EventForm/constants';
import { useCreateEventPage } from './hook';

const CreateEventPage = () => {
  const { isLoading, producerData } = useCreateEventPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Crear Evento">
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Crear Evento">
      <VBreadcrumbs pageTitle="Crear Evento" />
      <Stack alignItems="center" gap={3} px={2}>
        <VTypography variant="h3">Crear Evento</VTypography>
        <EventForm producerData={producerData} buttonLabel="Crear" mode={FORM_MODE.CREATE} />
      </Stack>
    </PageContainer>
  );
};

export default CreateEventPage;
