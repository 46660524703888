import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { useForm } from '../../../hooks/useForm';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../../constants/types';

export const useCreateEventPromotionPage = () => {
  const { eventId, producerId } = useParams();
  const [eventName, setEventName] = useState('');
  const { showNotification } = useNotifications();

  const { formState, formValues, handleInputChange } = useForm({
    promotionText: {
      value: '',
    },
    promotionLink: {
      value: '',
      required: false,
    },
    image: {
      value: '',
      required: false,
    },
  });

  const formatPromotionLink = () => {
    const { promotionLink } = formValues;
    return promotionLink.startsWith('https://') ? promotionLink : `https://${promotionLink}`;
  };

  const handleSubmit = async () => {
    const { promotionText, promotionLink, image } = formValues;

    await handleRequestHelper({
      endpoint: () =>
        api.createEventPromotion({
          eventId: Number(eventId),
          promotionText,
          ...(!!image && { promotionImages: [image] }),
          ...(!!promotionLink && { promotionLink: formatPromotionLink() }),
        }),
      showNotification,
      onSuccess: () => {
        showNotification('Promoción creada correctamente', NOTIFICATION_VARIANT.SUCCESS);
        window.location.href = `/backoffice/productoras/${producerId}/eventos/${eventId}/promociones`;
      },
    });
  };

  const getEventName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventName(eventId),
      onSuccess: ({ name }) => setEventName(name),
    });
  };

  useEffect(() => {
    getEventName();
  }, []);

  return { formState, eventName, handleInputChange, handleSubmit };
};
