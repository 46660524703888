import React from 'react';
import { Button, Stack } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme, withStyles } from '@material-ui/core';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import EventTicketCard from './EventTicketCard/EventTicketCard';
import {
  formatCattaneoEventTicketCardDate,
  formatEventTicketCardDate,
} from '../UserEvents/EventCard/EventCard.utils';
import styles from './UserEventTickets.styles';
import {
  HERNAN_CATTANEO_15_16_EVENT_ID,
  HERNAN_CATTANEO_PARKING_15_16_EVENT_ID,
} from '../UserEvents/EventCard/EventCard';
import SliderButtons from '../../../Venti-UI-Kit/SliderButtons/SliderButtons';
import history from '../../../appHistory';
import { useUserEventTickets } from './hook';

const UserEventTickets = ({ classes }) => {
  const theme = useTheme();
  const {
    isFetching,
    eventInfo,
    currentTicketIndex,
    eventQrs,
    selfManagedRefundEnabled,
    setCurrentTicketIndex,
    getEventQrs,
  } = useUserEventTickets();

  return (
    <PageContainer
      isLoading={isFetching}
      title={`Mis tickets - ${eventInfo?.name}`}
      withPaddingTop={false}
      headerChildren={
        <Stack gap={1} paddingBottom={2} alignItems="start" direction="row">
          <Button onClick={() => history.go(-1)} style={{ minWidth: 15, padding: 0, marginTop: 5 }}>
            <ArrowBackIosNewIcon
              className={classes.goBackButton}
              htmlColor={theme.palette.text.primary}
            />
          </Button>
          <Stack>
            <VTypography variant="subtitle1" linesNumber={1}>
              {eventInfo.name}
            </VTypography>

            <VTypography variant="body2" linesNumber={1}>
              {eventInfo.id !== HERNAN_CATTANEO_15_16_EVENT_ID &&
              eventInfo.id !== HERNAN_CATTANEO_PARKING_15_16_EVENT_ID
                ? formatEventTicketCardDate(eventInfo.startDate)
                : formatCattaneoEventTicketCardDate(eventInfo.startDate)}
            </VTypography>
          </Stack>
        </Stack>
      }
    >
      <SliderButtons
        margin="24px 0"
        setCurrentTicketIndex={setCurrentTicketIndex}
        currentTicketIndex={currentTicketIndex}
        length={eventQrs.length}
      />
      <Stack alignItems="center" width="100%">
        <EventTicketCard
          qr={eventQrs[currentTicketIndex]}
          event={eventInfo}
          getEventQrs={getEventQrs}
          selfManagedRefundEnabled={selfManagedRefundEnabled}
        />
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(UserEventTickets);
