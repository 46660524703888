import React from 'react';
import { withStyles } from '@material-ui/core';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import { Button, Divider, Stack } from '@mui/material';
import { useSidebar } from './hook';
import styles from './Sidebar.styles';
import SidebarItemButton from './SidebarItemButton/SidebarItemButton';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import { Drawer } from './Sidebar.styled';

const Sidebar = ({ classes, sections, footerUrl, footerUrlLabel, headerLabel }) => {
  const {
    open,
    sectionSelected,
    selectedSectionComponent,
    handleToggleSidebarOpen,
    openNewURL,
    handleClickSection,
  } = useSidebar(sections);

  return (
    <Stack direction="row" className={classes.sidebarContainer}>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingX={2.5}
          paddingY={1}
          gap={1}
        >
          {open && headerLabel && (
            <VTypography variant="subtitle3" linesNumber={2}>
              {headerLabel}
            </VTypography>
          )}

          <Button
            disableRipple
            sx={{ alignSelf: open ? 'end' : 'center' }}
            className={classes.toggleSidebarButton}
            onClick={handleToggleSidebarOpen}
          >
            <VIcon name={open ? 'chevron_left' : 'menu'} />
          </Button>
        </Stack>
        <Divider />
        <Stack flex={1}>
          {sections.map(({ title, key, icon, show, url }) => (
            <ListItem key={title} disablePadding>
              {show && (
                <SidebarItemButton
                  active={sectionSelected === key}
                  onClick={() => (url ? openNewURL(url) : handleClickSection(key))}
                  href={url}
                  open={open}
                  title={title}
                  icon={icon}
                />
              )}
            </ListItem>
          ))}
        </Stack>
        <Stack padding={2}>
          {footerUrlLabel && (
            <a className={classes.footerUrl} href={footerUrl}>
              <VIcon name="arrow_back" size={16} />
              {open && <VTypography variant="subtitle6">{footerUrlLabel}</VTypography>}
            </a>
          )}
        </Stack>
      </Drawer>
      <Stack flex={1} padding={2} alignItems="center">
        {selectedSectionComponent}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(Sidebar);
