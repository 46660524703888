import {
  getEventVenueAddress,
  getEventVenueName,
  getEventVenueLink,
  formatDatetime,
} from '../../../../utils/utils';

export const useEventHeader = (event, seller) => {
  const eventHasVenue = !!event?.venue;
  const { day, dayOfWeek, month, time } = formatDatetime(event.startDate);
  const formattedDate = `${dayOfWeek}, ${day} de ${month}`;
  const producerLink = `/organizadores/${event.Producer.urlName}`;
  const eventInformation = [
    {
      icon: 'calendar_today',
      label: formattedDate,
    },
    {
      icon: 'access_time',
      label: time,
    },
    {
      icon: 'home',
      label: eventHasVenue ? getEventVenueName(event) : event.placeName,
      link: eventHasVenue && getEventVenueLink(event),
    },
    {
      icon: 'location_on',
      label: getEventVenueAddress(event),
      link: getEventVenueLink(event),
    },
    {
      icon: 'person',
      label: seller,
    },
  ];

  return {
    eventInformation,
    producerLink,
  };
};
