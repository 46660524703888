import React from 'react';
import { withStyles, FormHelperText, Chip } from '@material-ui/core';
import { Autocomplete, Stack } from '@mui/material';
import styles from './VenueAutocomplete.styles';
import { useVenueAutocomplete } from './hook';
import AutocompleteInput from './AutocompleteInput/AutocompleteInput';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';
import VenueCard from './VenueCard/VenueCard';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const VenueAutocomplete = ({ classes, venue, onSelectOption, placeName, errorMessage }) => {
  const {
    openAutocomplete,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
    handleSelectSuggestedOption,
    venueOptions,
    searchQuery,
    onChangeSearch,
    isLoading,
    setIsLoadingVenue,
    suggestedVenues,
  } = useVenueAutocomplete(onSelectOption);

  return (
    <Stack>
      {!venue && !placeName ? (
        <>
          {suggestedVenues.length > 0 && (
            <>
              <VTypography variant="body2">Usadas recientemente</VTypography>
              <Stack className={classes.suggestions} flexWrap="wrap" direction="row" gap={1}>
                {suggestedVenues.map((suggestedVenue) => (
                  <Chip
                    key={suggestedVenue.id}
                    label={suggestedVenue.placeName}
                    variant="outlined"
                    onClick={() => handleSelectSuggestedOption(suggestedVenue)}
                  />
                ))}
              </Stack>
            </>
          )}
          <Autocomplete
            className={classes.autocompleteContainer}
            open={openAutocomplete}
            onOpen={handleOpenAutocomplete}
            onClose={handleCloseAutocomplete}
            options={isLoading ? [] : venueOptions}
            loading={isLoading}
            freeSolo={!searchQuery}
            clearOnBlur={false}
            noOptionsText="Loading..."
            renderInput={(props) => (
              <AutocompleteInput onChange={onChangeSearch} showError={errorMessage} {...props} />
            )}
            renderOption={(props, option) => (
              <AutocompleteOption
                option={option}
                searchQuery={searchQuery}
                onSelectOption={onSelectOption}
                setIsLoadingVenue={setIsLoadingVenue}
                key={option.label}
              />
            )}
          />
          {errorMessage && (
            <FormHelperText className={classes.helper}>{errorMessage}</FormHelperText>
          )}
        </>
      ) : (
        <VenueCard
          venue={venue || { readableAddress: placeName }}
          onClose={handleCloseAutocomplete}
        />
      )}
    </Stack>
  );
};

export default withStyles(styles)(VenueAutocomplete);
