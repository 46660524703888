import React from 'react';
import { Stack } from '@mui/material';

import PlusIcon from '@material-ui/icons/Add';
import { Chip } from '@material-ui/core';
import Button from '../../../Buttons/Button/Button';
import VModal from '../../../../Venti-UI-Kit/VModal/VModal';
import VTextInput from '../../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import VIconButton from '../../../Buttons/IconButton/IconButton';
import { useSendSettlementModal } from './hook';

const SendSettlementModal = ({ open, handleCreate, handleClose }) => {
  const {
    formState,
    handleInputChange,
    handleSend,
    handleCancel,
    receiverMails,
    addReceiverMail,
    removeReceiverMail,
  } = useSendSettlementModal(handleCreate, handleClose);

  return (
    <VModal
      open={open}
      title="Envíar Liquidación"
      onClose={handleClose}
      width="100%"
      maxWidth={600}
    >
      <Stack>
        <VTextInput
          onChange={handleInputChange}
          value={formState.mail.value}
          title="Emails de Destinatarios"
          name="mail"
          type="mail"
          errorMessage={formState.mail.error}
          endAdornment={
            <VIconButton
              type="submit"
              Icon={<PlusIcon />}
              onClick={() => addReceiverMail(formState.mail.value)}
              size={44}
            />
          }
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {receiverMails.map((mail, index) => (
            <Chip key={index} label={mail} onDelete={() => removeReceiverMail(mail)} />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Button fullWidth title="Cancelar" onClick={handleCancel} variant="outlined" />
        <Button
          fullWidth
          title="Enviar"
          disabled={receiverMails.length === 0}
          onClick={handleSend}
        />
      </Stack>
    </VModal>
  );
};

export default SendSettlementModal;
