import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { getUrlSearchParams } from '../../../utils/utils';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { DISCOUNT_CODES_STATUS } from '../../../constants/types';

export const useDuplicateTicketPage = () => {
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const initialTicketData = useRef({});
  const { tickets } = getUrlSearchParams();
  const ticketsIdsArray = tickets.split(',').map(Number);
  const ticketId = ticketsIdsArray[0];

  const getTicketInfo = async () => {
    await handleRequestHelper({
      endpoint: () => api.getTicketType(ticketId),
      onSuccess: ({ ticketType }) => {
        initialTicketData.current = ticketType;
      },
    });
  };

  const getEventStadiumSections = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventStadium(eventId),
      onSuccess: ({ stadium }) => {
        const ticketSection = stadium.sections?.find(({ ticketTypes }) =>
          ticketTypes.some(({ id }) => id === Number(ticketId))
        );

        initialTicketData.current = {
          ...initialTicketData.current,
          section: ticketSection,
          stadiumSections: stadium.sections,
        };
      },
    });
  };

  const getDiscountCode = async () => {
    await handleRequestHelper({
      endpoint: () => api.getDiscountCode(ticketId),
      onSuccess: ({ discountCodes }) => {
        if (!discountCodes || !discountCodes.length) return;

        const discountCodeData = discountCodes.find(
          ({ status }) => status === DISCOUNT_CODES_STATUS.ACTIVE
        );

        if (!discountCodeData) return;
        initialTicketData.current = { ...initialTicketData.current, discountCodeData };
      },
    });
  };

  const getEventInfo = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        initialTicketData.current = {
          ...initialTicketData.current,
          eventId,
          shouldExtendStopOfSalesDateToInvitations: event.shouldExtendStopOfSalesDateToInvitations,
          eventStopOfSalesDate: event.defaultStopOfSalesDate,
          eventEndDate: event.endDate,
        };
      },
    });
  };

  const getInitialData = async () => {
    setIsLoading(true);

    await getTicketInfo();
    await getEventInfo();
    await getDiscountCode();
    await getEventStadiumSections();

    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return { isLoading, initialTicketData: initialTicketData.current };
};
