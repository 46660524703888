import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    richTextEditor: {
      backgroundColor: theme.palette.grey[50],
      textAlign: 'left',
      borderRadius: '8px !important',
      overflow: 'hidden',

      '& .ProseMirror': {
        minHeight: 100,
        padding: 12,
      },

      '&:hover .MuiTiptap-FieldContainer-notchedOutline': {
        border: `1px solid ${theme.palette.primary.main} !important`,
      },

      '&:focus-within .MuiTiptap-FieldContainer-notchedOutline': {
        border: `2px solid ${theme.palette.primary.main} !important`,
      },

      '& .MuiTiptap-FieldContainer-notchedOutline': {
        border: `1px solid ${theme.palette.grey[300]} !important`,
      },
    },
    error: {
      '&:hover .MuiTiptap-FieldContainer-notchedOutline': {
        border: `1px solid ${theme.palette.error.main} !important`,
      },

      '&:focus-within .MuiTiptap-FieldContainer-notchedOutline': {
        border: `2px solid ${theme.palette.error.main} !important`,
      },

      '& .MuiTiptap-FieldContainer-notchedOutline': {
        border: `1px solid ${theme.palette.error.main} !important`,
      },
    },
  });

export default styles;
