import React from 'react';
import { useTheme } from '@material-ui/core';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { TERMS_BY_COUNTRY } from '../../../../constants/constants';
import CustomLink from '../../../../Components/CustomLink/CustomLink';

const EventDisclaimer = ({ country }) => {
  const termsLink = TERMS_BY_COUNTRY[country] ?? TERMS_BY_COUNTRY.AR;
  const theme = useTheme();

  return (
    <VTypography variant="body2" align="justify" color={theme.palette.grey[600]}>
      La carga de productos y textos en el sistema licenciado son de exclusiva responsabilidad del
      comerciante. Venti no se hace responsable civil o jurídicamente por las declaraciones emitidas
      por estos en lo relativo a los productos vendidos en su plataforma. El comerciante es el único
      y exclusivo responsable de la venta, en forma oportuna y en conformidad a la ley vigente.{' '}
      <CustomLink
        title="Ver términos y condiciones."
        href={termsLink}
        target="_blank"
        underline
        color={theme.palette.grey[600]}
      />
    </VTypography>
  );
};

export default EventDisclaimer;
