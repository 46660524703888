import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../../../Venti-UI-Kit/VTypography/VTypography';
import VTextInput from '../../../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import { formatPrice } from '../../../../../utils/utils';
import { CURRENCY, INPUT_TYPE } from '../../../../../constants/types';

const AmountCell = ({ rowIndex, value, edit, handleInputChange, eventCurrency }) => {
  return (
    <Stack alignItems="center">
      {edit ? (
        <VTextInput
          onChange={(event) => handleInputChange(event, rowIndex)}
          defaultValue={value}
          name="amount"
          type={INPUT_TYPE.NUMBER}
          startAdornment={eventCurrency === CURRENCY.ARS ? '$' : 'US$'}
          showHelpText={false}
        />
      ) : (
        <VTypography>{formatPrice(value, true, false, eventCurrency)}</VTypography>
      )}
    </Stack>
  );
};

export default AmountCell;
