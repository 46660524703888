import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import { useEditTicketPage } from './hook';
import TicketForm from '../../../Components/Forms/TicketForm/TicketForm';
import { FORM_MODE } from '../../../Components/Forms/EventForm/constants';

const EditTicketPage = () => {
  const { isLoading, initialTicketData } = useEditTicketPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Editar Ticket">
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Editar Ticket">
      <VBreadcrumbs pageTitle="Editar Ticket" />
      <Stack alignItems="center" gap={3} px={2}>
        <VTypography variant="h3">Editar Ticket</VTypography>
        <TicketForm
          mode={FORM_MODE.EDIT}
          buttonLabel="Guardar"
          initialTicketData={initialTicketData}
        />
      </Stack>
    </PageContainer>
  );
};

export default EditTicketPage;
