import { BACKOFFICE_PRODUCER_CARD_CATEGORY, USER_PERMISSIONS } from '../../../constants/types';
import { icons } from '../../../Providers/Icons/IconsProvider';
import { validateUserRole } from '../../../utils/utils';

export const infoCards = ({ userRole, category, generatesSellerReports, producerId }) => {
  const teamCards = [
    {
      title: 'Crear usuario',
      text: 'Crea administradores o vendedores para la productora',
      link: `/backoffice/productoras/${producerId}/usuarios/crear`,
      icon: icons.infoUser,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM,
      permissions: validateUserRole(userRole, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Crear múltiples vendedores',
      text: 'Crea múltiples vendedores para la productora',
      link: `/backoffice/productoras/${producerId}/usuarios/creacion-multiple`,
      icon: icons.infoUser,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM,
      permissions: validateUserRole(userRole, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Panel de usuarios',
      text: 'Mirá y editá administradores o vendedores de la productora',
      link: `/backoffice/productoras/${producerId}/usuarios`,
      icon: icons.infoList,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM,
      permissions: validateUserRole(userRole, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY),
    },
    {
      title: 'Crear team leader',
      text: 'Crea team leaders para la productora',
      link: `/backoffice/productoras/${producerId}/teamLeaders/crear`,
      icon: icons.infoUser,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM,
      permissions: validateUserRole(userRole, USER_PERMISSIONS.ADMIN) && generatesSellerReports,
    },
    {
      title: 'Panel de team leaders',
      text: 'Mirá y editá team leaders de la productora',
      link: `/backoffice/productoras/${producerId}/teamLeaders`,
      icon: icons.infoList,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM,
      permissions:
        validateUserRole(userRole, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY) && generatesSellerReports,
    },
  ];

  const configCards = [
    {
      title: 'Editar productora',
      link: `/backoffice/productoras/${producerId}/configuracion`,
      text: 'Editá la productora cambiando su título, apariencia, entre otros.',
      icon: icons.infoEvent,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.CONFIG,
      permissions: validateUserRole(userRole, USER_PERMISSIONS.ADMIN),
    },
  ];

  const helpCards = [
    {
      title: 'Ayuda',
      text: 'Consultá en el centro de ayuda las preguntas y procedimientos más comunes.',
      link: 'https://intercom.help/venti-latam/es',
      icon: icons.infoHelp,
      category: BACKOFFICE_PRODUCER_CARD_CATEGORY.HELP,
      permissions: true,
      newTab: true,
    },
  ];

  switch (category) {
    case BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM:
      return teamCards;
    case BACKOFFICE_PRODUCER_CARD_CATEGORY.CONFIG:
      return configCards;
    case BACKOFFICE_PRODUCER_CARD_CATEGORY.HELP:
      return helpCards;
    default:
      return [];
  }
};
