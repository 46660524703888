import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import styles from './SearchEventsResultsPage.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import MediumEventCard from '../Home/Components/MediumEventCard/MediumEventCard';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useSearchEventsResultsPage } from './hook';

const SearchEventsResultsPage = ({ classes }) => {
  const { from, until, query, events, theme } = useSearchEventsResultsPage();

  return (
    <PageContainer showSearchButton withFooter>
      <Stack className={classes.pageContainer}>
        <Stack width="100%">
          <VTypography variant="h3">Resultados</VTypography>
          {query && (
            <VTypography variant="subtitle6" color={theme.palette.grey[700]}>
              Busqueda: {query}
            </VTypography>
          )}
          {(from || until) && (
            <VTypography variant="subtitle6" color={theme.palette.grey[700]}>
              {from} - {until}
            </VTypography>
          )}
        </Stack>
        <Box className={classes.eventsContainer}>
          {events.length === 0 ? (
            <VTypography variant="h6" color={theme.palette.grey[400]}>
              No se encontraron eventos.
            </VTypography>
          ) : (
            events?.map((event) => <MediumEventCard event={event} key={event.id} />)
          )}
        </Box>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(SearchEventsResultsPage);
