import { useForm } from '../../../../hooks/useForm';
import { INPUT_TYPE } from '../../../../constants/types';

export const useEditRebateModal = (updateRebatePercentage, handleClose, rebatePercentage) => {
  const { formState, formValues, handleInputChange, validateFormInputs, resetForm } = useForm(
    {
      amount: { type: INPUT_TYPE.FLOAT, required: true, value: rebatePercentage },
    },
    [rebatePercentage]
  );

  const handleEdit = () => {
    const isFormValid = validateFormInputs();
    if (!isFormValid) return;

    updateRebatePercentage(Number(formValues.amount));
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    resetForm();
  };

  return { formState, handleInputChange, handleEdit, handleCancel };
};
