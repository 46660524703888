import React from 'react';
import { Stack, Box } from '@mui/material';
import { INPUT_TYPE } from '../../../constants/types';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const MAX_SECTION_NAME_LENGTH = 32;

const SectionForm = ({ disabled, section }) => {
  const { name, capacity, color } = section;

  const handleSectionChange = (field, value) => {
    section[field] = value || '';
  };

  const handleSectionNameChange = ({ target }) => handleSectionChange('name', target.value);

  const handleSectionCapacityChange = ({ target }) =>
    handleSectionChange('capacity', target.value ? Number(target.value) : '');

  const capacityError =
    section.capacity <= 0
      ? 'Debe ser mayor a 0'
      : !section.capacity
      ? 'Este campo es requerido'
      : '';

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
      <VTextInput
        name="name"
        title="Nombre del sector"
        maxLength={MAX_SECTION_NAME_LENGTH}
        onChange={handleSectionNameChange}
        defaultValue={name}
        disabled={disabled}
        errorMessage={!name ? 'Este campo es obligatorio' : ''}
        flex={1.8}
        startAdornment={<Box ml={-1.8} mr={0.5} width={24} height="200%" bgcolor={color} />}
      />
      <VTextInput
        name="capacity"
        title="Capacidad"
        flex={1}
        onChange={handleSectionCapacityChange}
        defaultValue={capacity}
        errorMessage={capacityError}
        type={INPUT_TYPE.NUMBER}
        disabled={disabled}
      />
    </Stack>
  );
};

export default SectionForm;
