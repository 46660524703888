import React from 'react';
import { withStyles } from '@material-ui/core';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import styles from './CheckBoxStyles';
import FormItemHeader from '../../Components/FormItemHeader/FormItemHeader';
import VTypography from '../VTypography/VTypography';

const CheckBox = ({
  classes,
  title,
  description,
  name,
  checked,
  label,
  onChange,
  size = 'small',
}) => {
  return (
    <Stack>
      <FormItemHeader title={title} subtitle={description} />
      <Stack flexDirection="row" gap={1}>
        <FormControlLabel
          name={name}
          checked={Boolean(checked)}
          className={classes.termsCheckbox}
          control={<Checkbox className="checkbox" size={size} />}
          onChange={onChange}
        />
        <VTypography variant="body2">{label}</VTypography>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(CheckBox);
