import React from 'react';
import { withStyles } from '@material-ui/core';
import { Pagination, Stack } from '@mui/material';
import styles from './ProducerListPage.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Searcher from '../../../Components/Backoffice/Searcher/Searcher';
import { useProducerListPage } from './hook';
import ProducerCard from './ProducerCard/ProducerCard';

const ProducerListPage = ({ classes }) => {
  const { user, producers, totalPages, currentPage, handleSearch, handleNewPage } =
    useProducerListPage();

  return (
    <PageContainer withPaddingTop={false} user={user} title="Backoffice - Productoras">
      <Stack className={classes.producerListPageContaienr}>
        <VTypography variant="h3" align="center">
          Productoras
        </VTypography>
        <Searcher onSubmit={handleSearch} placeholder="Buscar productora" />
        <Stack gap={2}>
          {producers.map((producer) => (
            <ProducerCard producer={producer} key={producer.id} />
          ))}
        </Stack>
        <Stack alignSelf="center">
          <Pagination count={totalPages} page={currentPage} onChange={handleNewPage} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(ProducerListPage);
