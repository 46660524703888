import React from 'react';
import VFormCard from '../../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import VTextInput from '../../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import CountrySelector from '../../../LocationSelectors/CountrySelector/CountrySelector';
import CitySelector from '../../../LocationSelectors/CitySelector/CitySelector';
import VTextArea from '../../../../Venti-UI-Kit/VInputs/VTextArea/VTextArea';

const GeneralInformationForm = ({ formState, onChange }) => {
  return (
    <VFormCard label="Información general">
      <VTextInput
        title="Nombre"
        name="name"
        onChange={onChange}
        defaultValue={formState?.name.value}
        errorMessage={formState?.name.error}
      />
      <CountrySelector
        onChange={onChange}
        value={formState?.country.value}
        errorMessage={formState?.country.error}
      />
      <CitySelector
        country={formState?.country.value}
        onChange={onChange}
        value={formState?.city.value}
        errorMessage={formState?.city.error}
      />
      <VTextArea
        title="Descripción"
        name="description"
        onChange={onChange}
        defaultValue={formState?.description.value}
        errorMessage={formState?.description.error}
      />
    </VFormCard>
  );
};

export default GeneralInformationForm;
