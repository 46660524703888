import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import CustomLink from '../../../Components/CustomLink/CustomLink';
import Button from '../../../Components/Buttons/Button/Button';
import ReCaptchaMessage from '../../../Components/ReCaptchaMessage/ReCaptchaMessage';
import PowerByVenti from '../../../Components/PowerByVenti/PowerByVenti';
import GoogleReCaptcha from '../../../Components/GoogleReCaptcha/GoogleReCaptcha';
import TermsAndConditionsLabel from '../LoginPage/TermsAndConditionsModal/TermsAndConditionsLabel';
import CheckBox from '../../../Venti-UI-Kit/CheckBox/CheckBox';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import { ID_TYPE_OPTIONS } from '../../../constants/identificationTypes';
import { GENDERS } from '../../../constants/selectorOptions';
import { useSignupPage } from './hook';
import CountrySelector from '../../../Components/LocationSelectors/CountrySelector/CountrySelector';
import CitySelector from '../../../Components/LocationSelectors/CitySelector/CitySelector';

const SignupPage = ({ classes }) => {
  const {
    formState,
    handleInputChange,
    signup,
    isSignupButtonDisabled,
    isLoading,
    validateRecaptchaToken,
    handleToggleCheckTerms,
  } = useSignupPage();

  return (
    <PageContainer showLoginButton={false} title="Registro">
      <Box className={classes.signupPage}>
        <Box gap={3} display="flex" flexDirection="column">
          <VTypography variant="h1">Creá tu cuenta</VTypography>
          <Box className={classes.formContainer}>
            <VTextInput
              title="Nombre"
              onChange={handleInputChange}
              name="firstName"
              errorMessage={formState.firstName.error}
              required
            />
            <VTextInput
              title="Apellido"
              onChange={handleInputChange}
              name="lastName"
              errorMessage={formState.lastName.error}
              required
            />
            <VSelectInput
              title="Tipo de identificación"
              onChange={handleInputChange}
              name="idType"
              options={ID_TYPE_OPTIONS}
              value={formState.idType.value}
              errorMessage={formState.idType.error}
              required
            />
            <VTextInput
              title="Número de identificación"
              onChange={handleInputChange}
              name="idNumber"
              errorMessage={formState.idNumber.error}
              required
            />
            <VTextInput
              type="email"
              name="mail"
              onChange={handleInputChange}
              title="Email"
              errorMessage={formState.mail.error}
              required
              defaultValue={formState.mail.value}
              autoComplete="email"
            />
            <VTextInput
              title="Número de teléfono"
              onChange={handleInputChange}
              name="phone"
              errorMessage={formState.phone.error}
            />
            <VTextInput
              type="password"
              name="password"
              hint="Mínimo 8 caracteres e incluir al menos una mayúscula, una minúscula, un carácter especial y dos números."
              onChange={handleInputChange}
              title="Contraseña"
              errorMessage={formState.password.error}
              required
              autoComplete="new-password"
            />
            <VTextInput
              type="password"
              name="confirmPassword"
              onChange={handleInputChange}
              title="Confirmar contraseña"
              errorMessage={formState.confirmPassword.error}
              required
              autoComplete="new-password"
            />
            <VTextInput
              title="Fecha de nacimiento (DD/MM/AAAA)"
              onChange={handleInputChange}
              name="birthDate"
              type="tel"
              mask="99/99/9999"
              errorMessage={formState.birthDate.error}
              required
              maskChar={'_'}
            />
            <VSelectInput
              title="Género"
              onChange={handleInputChange}
              name="gender"
              value={formState.gender.value}
              options={GENDERS}
              errorMessage={formState.gender.error}
              required
            />
            <CountrySelector
              onChange={handleInputChange}
              value={formState?.country.value}
              errorMessage={formState?.country.error}
              required
            />
            <CitySelector
              country={formState?.country.value}
              onChange={handleInputChange}
              value={formState?.city.value}
              errorMessage={formState?.city.error}
              required
            />
          </Box>
        </Box>

        <Box>
          <Box gap={4} display="flex" flexDirection="column" marginBottom={4}>
            <GoogleReCaptcha mt={2} validation={validateRecaptchaToken} />
            <CheckBox
              label={<TermsAndConditionsLabel leftText="Aceptar los" />}
              onChange={handleToggleCheckTerms}
            />
            <Box display="flex" justifyContent="center">
              <Button
                onClick={signup}
                fullWidth={false}
                title="Crear cuenta"
                disabled={isSignupButtonDisabled}
                width={200}
                loading={isLoading}
              />
            </Box>
            <VTypography variant="body2" align="center">
              ¿Ya tenés una cuenta? <CustomLink to="/login" title="Ingresar" />
            </VTypography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" gap={1.5} marginTop={1.5}>
            <PowerByVenti />
            <ReCaptchaMessage />
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(SignupPage);
