import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

export const useEventPromotionsPage = () => {
  const { eventId } = useParams();
  const [eventName, setEventName] = useState('');
  const { showNotification } = useNotifications();
  const [eventPromotions, setEventPromotions] = useState([]);

  const getEventPromotions = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventPromotions(eventId),
      onSuccess: ({ eventPromotions }) => setEventPromotions(eventPromotions),
      showNotification,
    });
  };

  const handleDeletePromotion = async (promotionId) => {
    await handleRequestHelper({
      endpoint: () => api.deleteEventPromotion(promotionId),
      showNotification,
      onSuccess: async () => {
        setEventPromotions(eventPromotions.filter((promotion) => promotion.id !== promotionId));
        showNotification('La promoción fue eliminada con éxito.', 'success');
      },
    });
  };

  const getEventName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventName(eventId),
      onSuccess: ({ name }) => setEventName(name),
    });
  };

  useEffect(() => {
    getEventName();
    getEventPromotions();
  }, []);

  return { eventPromotions, eventName, handleDeletePromotion };
};
