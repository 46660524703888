import { useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useHome } from '../../../hooks/useHome';

export const useProducerHomePage = (urlParams, setTheme) => {
  const { userAlias } = urlParams;
  const theme = useTheme();

  const { events, getHomeEvents, hasMoreEvents, producer } = useHome(urlParams, 6);

  useEffect(() => {
    if (producer) setTheme(producer.theme);
  }, [producer]);

  return {
    producer,
    events,
    hasMoreEvents,
    getHomeEvents,
    userAlias,
    theme,
  };
};
