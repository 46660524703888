import React from 'react';
import { Grid } from '@mui/material';
import { SOCIALS_CONFIG } from '../ProducerForm.constants';
import VTextInput from '../../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import VFormCard from '../../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';

const SocialsForm = ({ formState, onChange }) => {
  return (
    <VFormCard label="Redes sociales" description="Completa con el nombre de tu cuenta.">
      <Grid container padding="8px 0" columnSpacing={2} columns={{ xs: 1, sm: 8, lg: 12 }}>
        {Object.entries(SOCIALS_CONFIG).map(([social, { icon, label, startAdornment }]) => (
          <Grid key={social} item xs={1} sm={8} lg={6}>
            <VTextInput
              title={label}
              name={social}
              leftIcon={<VIcon name={icon} />}
              onChange={onChange}
              defaultValue={formState[social]?.value}
              errorMessage={formState[social]?.error}
              startAdornment={startAdornment}
            />
          </Grid>
        ))}
      </Grid>
    </VFormCard>
  );
};

export default SocialsForm;
