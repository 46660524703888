import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { HTTP_CODE, INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useReCaptcha } from '../../../hooks/useReCaptcha';
import { useForm } from '../../../hooks/useForm';
import history from '../../../appHistory';

export const useSignupPage = () => {
  const { showNotification } = useNotifications();
  const [isSignupButtonDisabled, setIsSignupButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const { buttonActionDisabled, validateRecaptchaToken } = useReCaptcha({
    history,
    page: 'signup',
    action: 'login',
  });

  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const {
    formValues,
    formState,
    isFormComplete,
    handleInputChange,
    validateFormInputs,
    setInputError,
  } = useForm({
    firstName: { type: INPUT_TYPE.ALPHA },
    lastName: { type: INPUT_TYPE.ALPHA },
    idType: { type: INPUT_TYPE.ID_TYPE },
    idNumber: { type: INPUT_TYPE.ID_NUMBER },
    mail: {
      type: INPUT_TYPE.MAIL,
      value: email,
    },
    phone: {
      type: INPUT_TYPE.NUMBER,
      required: false,
    },
    password: { type: INPUT_TYPE.PASSWORD },
    confirmPassword: { type: INPUT_TYPE.CONFIRM_PASSWORD },
    birthDate: { type: INPUT_TYPE.BIRTH_DATE },
    gender: { type: INPUT_TYPE.TEXT },
    country: { type: INPUT_TYPE.TEXT },
    city: { type: INPUT_TYPE.TEXT, required: false },
  });

  const signup = async () => {
    setIsLoading(true);
    const isCaptchaValid = await validateRecaptchaToken();
    if (!isCaptchaValid) {
      setIsLoading(false);
      return;
    }

    setIsSignupButtonDisabled(true);
    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      setIsSignupButtonDisabled(false);
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      setIsLoading(false);
      return;
    }

    const signupBody = {
      user: {
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        identificationType: formState.idType.value,
        identificationValue: formState.idNumber.value,
        mail: formState.mail.value,
        phone: formState.phone.value,
        password: formState.password.value,
        country: formState.country.value,
        residenceLocation: formState.city.value,
        birthDate: parse(formState.birthDate.value, 'dd/MM/yyyy', new Date()),
        gender: formState.gender.value,
      },
      hostname: window.location.hostname,
    };

    await handleRequestHelper({
      endpoint: () => api.signup(signupBody),
      showNotification,
      errorCodesNotShowingSnackbar: [HTTP_CODE.CONFLICT],
      onSuccess: () =>
        history.push({
          pathname: '/mailvalidation/sent',
          state: { mail: formState.mail.value },
        }),
      onFailure: ({ statusCode }) => {
        if (statusCode === HTTP_CODE.CONFLICT)
          setInputError(
            'mail',
            'El email que ingresaste ya está vinculado a una cuenta. Iniciá sesión o usá otro email.'
          );
      },
    });
    setIsLoading(false);
    setIsSignupButtonDisabled(false);
  };

  const handleToggleCheckTerms = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const shouldRequireCity = formState.country?.value !== 'OTHER';

  useEffect(
    () =>
      setIsSignupButtonDisabled(
        !isFormComplete() ||
          (shouldRequireCity && !formState.city.value) ||
          buttonActionDisabled ||
          !isTermsChecked
      ),
    [formValues, shouldRequireCity, buttonActionDisabled, isTermsChecked]
  );

  return {
    formState,
    handleInputChange,
    isSignupButtonDisabled,
    isLoading,
    signup,
    isTermsChecked,
    handleToggleCheckTerms,
    validateRecaptchaToken,
  };
};
