import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import TitledSwitch from '../../../Venti-UI-Kit/Switches/TitledSwitch';
import VSelectInput from '../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import Button from '../../../Components/Buttons/Button/Button';
import { useAdvancedEventSettingsPage } from './hook';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';
import IconButton from '../../../Components/Buttons/IconButton/IconButton';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import { INPUT_TYPE } from '../../../constants/types';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const AdvancedEventSettingsPage = ({ classes }) => {
  const {
    formState,
    validationModeOptions,
    gateSecretApiKey,
    isLoading,
    eventName,
    handleInputChange,
    updateEventSettings,
    copySecretApiKeyToClipboard,
    isUpdating,
  } = useAdvancedEventSettingsPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Opciones Avanzadas">
        <VBreadcrumbs pageTitle="Opciones Avanzadas" />
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Opciones Avanzadas">
      <VBreadcrumbs pageTitle="Opciones Avanzadas" />
      <Stack padding={2} alignItems="start" maxWidth={990} alignSelf="center">
        <BackofficePageTitle
          mainTitle={eventName}
          secondaryTitle="Configuración avanzada del evento"
        />

        <Stack className={classes.pageContent}>
          <VTextInput
            title="Secret Gate API Key"
            description="Secret API Key necesaría para poder vincular los molinetes"
            name="gateSecretApiKey"
            readOnly
            type={INPUT_TYPE.PASSWORD}
            value={gateSecretApiKey}
            showHelpText={false}
            endAdornment={
              <IconButton Icon={<VIcon name="copy" />} onClick={copySecretApiKeyToClipboard} />
            }
          />
          <TitledSwitch
            name="secondaryMarketEnable"
            onChange={handleInputChange}
            checked={formState.secondaryMarketEnable.value}
            label="Activar Venti Swap"
          />
          <TitledSwitch
            name="secondaryMarketEventButtonEnable"
            onChange={handleInputChange}
            checked={formState.secondaryMarketEventButtonEnable.value}
            label="Activar el botón de Venti Swap en el evento"
          />
          {/* <TitledSwitch
          titleVariant="body1"
          name="shouldBlacklistSellers"
          onChange={handleInputChange}
          checked={formState.shouldBlacklistSellers.value}
          title="Habilitar la blacklist de sellers"
        />
        <TitledSwitch
          name="shouldExtendStopOfSalesToInvitations"
          onChange={handleInputChange}
          checked={formState.shouldExtendStopOfSalesToInvitations.value}
          label="Extender la fecha de corte de ventas hacia las invitaciones"
        />
        <TitledSwitch
          name="shouldConvertPriceForUsd"
          onChange={handleInputChange}
          checked={formState.shouldConvertPriceForUsd.value}
          label="Convertir los precios a Dolares"
          description="Los precios de los tickets se mostrarán en dolares"
        />
         */}
          <VTextInput
            title="Liberación de QRs"
            description="Cantidad de horas antes del evento para liberar los QRs"
            onChange={handleInputChange}
            name="hoursBeforeRelease"
            defaultValue={formState.hoursBeforeRelease.value}
            showHelpText={false}
            type={INPUT_TYPE.NUMBER}
          />
          <VTextInput
            title="Finalización venta Venti Swap"
            description="Cantidad de horas antes del evento para finalizar la venta en Venti Swap"
            onChange={handleInputChange}
            name="hoursBeforeReleaseSecondaryMarket"
            defaultValue={formState.hoursBeforeReleaseSecondaryMarket.value}
            showHelpText={false}
            type={INPUT_TYPE.NUMBER}
          />
          <VSelectInput
            title="Modo de validación de QRs"
            description="Seleccione el modo de validación de QRs e indique el dispositivo que desea utilizar para marcar un QR como usado"
            onChange={handleInputChange}
            name="scanOptions"
            options={validationModeOptions}
            value={formState.scanOptions.value}
            showHelpText={false}
          />
          <Stack alignItems="center">
            <Button
              title="Guardar"
              fullwidth={false}
              onClick={updateEventSettings}
              loading={isUpdating}
            />
          </Stack>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(AdvancedEventSettingsPage);
