import React from 'react';
import { Stack } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { translate } from '../../../../../utils/translate';
import VSelectInput from '../../../../../Venti-UI-Kit/VInputs/VSelectInput/VSelectInput';
import VTypography from '../../../../../Venti-UI-Kit/VTypography/VTypography';
import { TYPES_OPTIONS } from '../FinancialOperationsTable.constants';

const TypeCell = ({ rowIndex, value, edit, handleInputChange }) => {
  return (
    <Stack alignItems="center">
      {edit ? (
        <Stack width={230}>
          <VSelectInput
            options={TYPES_OPTIONS}
            name="type"
            onChange={(event) => handleInputChange(event, rowIndex)}
            value={value}
            showHelpText={false}
          >
            {TYPES_OPTIONS.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </VSelectInput>
        </Stack>
      ) : (
        <VTypography>{translate(value)}</VTypography>
      )}
    </Stack>
  );
};

export default TypeCell;
