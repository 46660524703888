import React, { useEffect, useRef, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './SendMultipleInvitationsPageStyles';
import CustomTable from '../../../Components/Tables/CustomTable';
import InvitationSelector from '../../../Components/InvitationSelector/InvitationSelector';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import CSVUploaderModal from '../../../Components/Modals/CSVUploaderModal/CSVUploaderModal';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { isValidMail } from '../../../utils/utils';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const NO_INVITATION_ID = -1;

const SendMultipleInvitationsPage = ({ classes, urlParams }) => {
  const { eventId } = urlParams;
  const { showNotification } = useNotifications();
  const [selectedInvitation, setSelectedInvitation] = useState();
  const [noInvitations, setNoInvitations] = useState(true);
  const [openCSVUploadModal, setOpenCSVUploadModal] = useState(false);
  const [invitationsList, setInvitationsList] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const eventName = useRef('');

  const getEventName = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventName(eventId),
      onSuccess: ({ name }) => {
        eventName.current = name;
      },
    });
  };

  const tableColumns = [
    {
      value: 'firstName',
      label: 'Nombre',
    },
    {
      value: 'lastName',
      label: 'Apellido',
    },
    {
      value: 'mail',
      label: 'Mail',
    },
    {
      value: 'ticketQuantity',
      label: 'Cantidad a enviar',
    },
  ];

  const handleInvitationChange = (selectedInvitation) => {
    setNoInvitations(!selectedInvitation || selectedInvitation === NO_INVITATION_ID);
    setSelectedInvitation(selectedInvitation);
  };

  const handleUploadCSVFile = () => {
    setOpenCSVUploadModal(true);
  };

  const handleCloseCSVUploadModal = () => {
    setOpenCSVUploadModal(false);
  };

  const handleAssignInvitationsByCSV = (file) => {
    let csvValid = true;
    let errorMessage = '';

    file.forEach(({ firstName, lastName, mail, ticketQuantity }, index) => {
      if (!firstName) {
        errorMessage = `Falta el nombre del usuario en la fila N° ${index + 2}.`;
        csvValid = false;
      } else if (!lastName) {
        errorMessage = `Falta el apellido del usuario en la fila N° ${index + 2}.`;
        csvValid = false;
      } else if (!mail) {
        errorMessage = `Falta el mail del usuario en la fila N° ${index + 2}.`;
        csvValid = false;
      } else if (mail !== mail.trim()) {
        errorMessage = `El mail del usuario en la fila N° ${index + 2} contiene espacios vacíos.`;
        csvValid = false;
      } else if (!isValidMail(mail)) {
        errorMessage = `El mail del usuario en la fila N° ${index + 2} es invalido.`;
        csvValid = false;
      } else if (!ticketQuantity) {
        errorMessage = `Falta la cantidad a enviar del usuario en la fila N° ${index + 2}.`;
        csvValid = false;
      } else if (isNaN(ticketQuantity)) {
        errorMessage = `La cantidad a enviar del usuario en la fila N° ${
          index + 2
        } debe ser un número.`;
        csvValid = false;
      }
    });

    if (!csvValid) {
      showNotification(errorMessage, NOTIFICATION_VARIANT.ERROR);
      return;
    }

    setInvitationsList(
      file.map(({ ticketQuantity, mail, ...row }) => ({
        ...row,
        mail: mail.trim().toLowerCase(),
        ticketQuantity: parseInt(ticketQuantity, 10),
      }))
    );
  };

  const handleSubmit = async () => {
    setIsSending(true);
    await handleRequestHelper({
      endpoint: () => api.sendMultipleInvitationsByAdmin(selectedInvitation, invitationsList),
      showNotification,
      onSuccess: () => {
        showNotification(
          'Tu solicitud de envío fue procesada exitosamente. En los próximos minutos tus invitados recibirán su acceso',
          NOTIFICATION_VARIANT.SUCCESS
        );
        setInvitationsList([]);
      },
    });
    setIsSending(false);
  };

  useEffect(() => {
    getEventName();
  }, []);

  return (
    <PageContainer title="Backoffice - Enviar Múltiples Invitaciones">
      <div className={classes.pageContainer}>
        <VBreadcrumbs pageTitle="Enviar Múltiples Invitaciones" />
        <Stack alignItems="center" gap={3} px={2}>
          <BackofficePageTitle
            mainTitle={eventName.current}
            secondaryTitle="Envío múltiple de invitaciones"
          />

          <div className={classes.tableContainer}>
            <div className={classes.tableHeaderContainer}>
              <div className={classes.invitationSelectorContainer}>
                <Typography>Invitación</Typography>
                <InvitationSelector
                  eventId={eventId}
                  onInvitationIdChange={handleInvitationChange}
                />
              </div>
              <Button disabled={noInvitations} onClick={handleUploadCSVFile}>
                Subir CSV de invitaciones
              </Button>
            </div>
            {!selectedInvitation ? (
              <CustomCircularProgress />
            ) : noInvitations ? (
              <Typography>Este evento no tiene invitaciones disponibles.</Typography>
            ) : (
              <>
                <CustomTable
                  data={invitationsList}
                  columns={tableColumns}
                  noMatch="No has subido ningún CSV todavía"
                />
                {isSending && (
                  <Typography>
                    <strong>No refresque la página.</strong> Estamos procesando el envío, puede
                    demorar unos segundos.
                  </Typography>
                )}
                <Button
                  disabled={noInvitations || !invitationsList.length || isSending}
                  className={classes.sendButton}
                  onClick={handleSubmit}
                >
                  {isSending ? <CustomCircularProgress size={20} /> : 'Enviar'}
                </Button>
              </>
            )}
          </div>
          {openCSVUploadModal && (
            <CSVUploaderModal
              handleOpen={openCSVUploadModal}
              title="Subir archivo CSV"
              confirmButtonTitle="Cargar"
              handleClose={handleCloseCSVUploadModal}
              handleConfirm={handleAssignInvitationsByCSV}
              columns={tableColumns}
              message={
                <span>
                  Subí tu archivo CSV para enviar invitaciones a múltiples usuarios, el mismo deberá
                  tener las columnas 'Nombre', 'Apellido', 'Mail' y 'Cantidad a enviar'. Las
                  invitaciones se enviarán al presionar 'Enviar'.
                  <br />
                  <b>Importante: el mismo no debe constar de espacios vacíos.</b>
                </span>
              }
            />
          )}
        </Stack>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(SendMultipleInvitationsPage);
