import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import styles from './SuccessPurchaseContent.styles';
import { ReactComponent as SuccessImage } from '../../../../assets/images/successMailValidation.svg';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../../../Components/Buttons/Button/Button';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';

const SuccessPurchaseContent = ({ classes, order }) => {
  const { user } = useAuth();
  const { id: orderId, mail, Payments: payments, Qrs: qrs } = order;
  const ticketsPayment = payments.find(({ type }) => type === 'tickets');
  const ticketsPriceInCents = ticketsPayment.finalPriceInCents || 0;

  const tickets = qrs.reduce((tickets, { TicketType }) => {
    const { id, name } = TicketType;

    if (!tickets[id]) {
      tickets[id] = {
        name,
        quantity: 1,
      };
    } else {
      tickets[id].quantity++;
    }
    return tickets;
  }, {});

  return (
    <Stack maxWidth={500} alignSelf="center" flex={1} padding={2} gap={4}>
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <SuccessImage />
      </Stack>
      <Stack gap={2} flex={2}>
        <VTypography align="center" variant="h2" className="mailValidationPageTitle">
          Tu compra fue realizada con éxito
        </VTypography>
        <VTypography variant="body1" align="center">
          N° de orden: <span className={classes.textWithColor}>{orderId}</span>
        </VTypography>
        <VTypography variant="body2" align="center">
          Enviaremos tu resumen de compra a <span className={classes.textWithColor}>{mail}</span>.
          {user
            ? ' Podés encontrar tus tickets desde tu cuenta de Venti.'
            : ' Creá tu cuenta de Venti para poder encontrar tus tickets.'}
        </VTypography>
        <VTypography variant="body2" align="center">
          Los QRs se habilitarán cerca de la fecha del evento.
        </VTypography>
      </Stack>
      <Stack gap={2}>
        {user ? (
          <Button href="/mis-tickets" title="Ir a mis tickets" target="_self" />
        ) : (
          <Button href="/signup" title="Crear cuenta" target="_self" />
        )}
        <Button href="/eventos" variant="outlined" title="Explorar más eventos" target="_self" />
      </Stack>

      <Box id="pixelInformation" display="none">
        <Box className="pixelOrderPrice">{ticketsPriceInCents / 100}</Box>
        {Object.values(tickets).map(({ name, quantity }) => (
          <Box className="event" key={name}>
            <span id="ticketName">{name}</span>
            <span id="ticketQuanity">x {quantity}</span>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default withStyles(styles)(SuccessPurchaseContent);
