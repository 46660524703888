import React from 'react';
import { List, ListItem, Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { REQUIREMENTS_TEXTS } from './constants';
import { useSelfManagedRefundBottomSheet } from './hook';

const SelfManagedRefundBottomSheet = ({ onClose, open, orderId, canRefund, getEventQrs }) => {
  const { handleClose, handleRefund, isRefunding, orderInfo, isLoading } =
    useSelfManagedRefundBottomSheet(orderId, canRefund, onClose, getEventQrs);

  return (
    <BottomSheet open={open} onClose={handleClose}>
      <Stack padding={2} gap={2.5}>
        <Stack gap={1}>
          <VTypography variant="h4">Devolución de entradas</VTypography>
          {canRefund && !isLoading && orderInfo.tickets ? (
            <>
              <VTypography variant="body2">
                Estás a punto de solicitar la devolución de la orden #{orderId} por un monto de $
                {orderInfo.payment} (ARS).
              </VTypography>
              <VTypography variant="body2">Esta orden contiene los siguientes QRs:</VTypography>
              <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
                {Object.entries(orderInfo.tickets).map(([name, count]) => (
                  <ListItem key={name} sx={{ display: 'list-item' }}>
                    <VTypography variant="body2">
                      {name} x{count}
                    </VTypography>
                  </ListItem>
                ))}
              </List>
              <VTypography variant="body2">
                Las devoluciones no incluyen el cargo por servicio.{' '}
              </VTypography>
              <VTypography variant="body2">
                Si estás seguro, clickeá en "Devolver Entradas” para proceder con la solicitud.{' '}
              </VTypography>
            </>
          ) : (
            <>
              <VTypography variant="body2">
                No cumples con alguno de los requisitos necesarios para realizar la devolución de
                las entradas.
              </VTypography>
              <VTypography variant="body2">Requisitos necesarios:</VTypography>
              <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
                {REQUIREMENTS_TEXTS.map((requirement) => (
                  <ListItem key={requirement.code} sx={{ display: 'list-item' }}>
                    <VTypography variant="body2">{requirement.message}</VTypography>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Stack>
        <Stack direction="column" gap={1}>
          {canRefund && (
            <Button
              size="small"
              color="error"
              fullwidth
              title="Devolver Entradas"
              variant="outlined"
              onClick={handleRefund}
              loading={isRefunding}
            />
          )}
          <Button color="alternative" size="small" fullwidth title="Volver" onClick={handleClose} />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default SelfManagedRefundBottomSheet;
