import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAuth } from '../../Providers/AuthProvider/AuthProvider';
import { translate } from '../../utils/translate';

const IntercomButton = () => {
  const { user, getUserIntercomHash } = useAuth();
  const { boot, shutdown } = useIntercom();
  const formattedUserProducers = user?.producers.map(({ id, name }) => ({ id, name }));

  const intercomUserInfo = user?.id
    ? {
        user_id: user.id,
        email: user.mail,
        name: `${user.firstName} ${user.lastName}`.trim(),
        phone: user.phone,
        userHash: getUserIntercomHash(),
        customAttributes: {
          identificationType: user.identificationType,
          identificationValue: user.identificationValue,
          userRole: translate(user.role),
          userCountry: translate(user.country),
          userProvince: user.residenceLocation,
          producersList: JSON.stringify(formattedUserProducers),
          userAlias: user.alias,
          ventiUserId: user.id,
        },
      }
    : { name: 'Usuario no logueado' };

  useEffect(() => {
    shutdown();
    boot(intercomUserInfo);
  }, [user]);

  return <div />;
};

export default IntercomButton;
