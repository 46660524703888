import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import { setHours, setMinutes } from 'date-fns';
import styles from './styles';
import VDateTimePicker from '../../VDateTimePicker/VDateTimePicker';
import DateInput from './DateInput';
import FormItemHeader from '../../../Components/FormItemHeader/FormItemHeader';

const VDateTimeInput = ({
  classes,
  onChange,
  errorMessage,
  showTimeInput,
  selected,
  showYearDropdown,
  title,
  description,
  required,
  disabled,
  name,
}) => {
  const timeInputRef = useRef();
  const [selectedDate, setSelectedDate] = useState(selected);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (showTimeInput && timeInputRef.current) timeInputRef.current.toggleCalendar();
    onChange({ target: { name, value: date, type: 'picker' } });
  };

  return (
    <Stack>
      <FormItemHeader title={title} required={required} description={description} />
      <Stack direction="row" gap={1} className={classes.VDateTimeInputContainer}>
        <VDateTimePicker
          dateFormat="dd/MM/yyyy"
          onChange={handleDateChange}
          selected={selectedDate}
          showYearDropdown={showYearDropdown}
          disabled={disabled}
          customInput={
            <DateInput errorMessage={errorMessage} title={title} description={description} />
          }
        />
        {showTimeInput && (
          <VDateTimePicker
            showTimeSelectOnly
            timeIntervals={15}
            showTimeSelect
            onChange={handleDateChange}
            selected={selectedDate}
            ref={timeInputRef}
            disabled={disabled}
            customInput={<DateInput errorMessage={errorMessage} />}
            dateFormat="HH:mm"
            timeCaption="Seleccione una hora"
            injectTimes={[setHours(setMinutes(selectedDate, 59), 23)]}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(VDateTimeInput);
