import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    base: {
      height: 48,
      minWidth: 250,
      boxShadow: 'none',
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: 20,

      '&:hover': {
        opacity: 0.8,
        boxShadow: 'none',
      },
    },

    small: {
      height: 36,
    },
    medium: {
      height: 48,
    },
    large: {
      height: 56,
    },

    contained: {
      '&.main': {
        backgroundColor: theme.palette.buttons.main.background,

        '& .buttonText': {
          color: theme.palette.buttons.main.text,
        },

        '&:hover': {
          backgroundColor: theme.palette.buttons.main.hover,
        },
      },

      '&.error': {
        backgroundColor: theme.palette.error.main,

        '& .buttonText': {
          color: theme.palette.buttons.main.text,
        },

        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },

      '&.alternative': {
        backgroundColor: theme.palette.buttons.alternative.background,

        '& .buttonText': {
          color: theme.palette.buttons.alternative.text,
        },

        '&:hover': {
          backgroundColor: theme.palette.buttons.alternative.hover,
        },
      },

      '&:disabled': {
        '& .buttonText': {
          color: theme.palette.buttons.alternative.text,
        },
      },
    },
    outlined: {
      '&.main': {
        border: `1px solid ${theme.palette.buttons.main.background}`,

        '& .buttonText': {
          color: theme.palette.buttons.main.background,
        },

        '&.Mui-disabled .buttonText': {
          color: theme.palette.buttons.disabledText,
        },
      },

      '&.error': {
        border: `1px solid ${theme.palette.error.main}`,

        '& .buttonText': {
          color: theme.palette.error.main,
        },

        '&:hover': {
          border: `1px solid ${theme.palette.error.dark}`,
        },
      },

      '&.alternative': {
        border: `1px solid ${theme.palette.buttons.alternative.background}`,

        '& .buttonText': {
          color: theme.palette.buttons.alternative.background,
        },

        '&.Mui-disabled .buttonText': {
          color: theme.palette.buttons.disabledText,
        },
      },
    },
    fullwidth: {
      width: '100%',
      minWidth: 'unset',
    },
  });

export default styles;
