import { useEffect, useRef, useState, useCallback } from 'react';
import { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useDropzone } from 'react-dropzone';
import { canvasPreview, setCanvasImage } from './VimageUploader.canvas';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';
import { useNotifications } from '../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../constants/types';
import { IMAGES_CDN_BY_ENV } from '../../constants/constants';
import { getEnviroment } from '../../utils/utils';

const MAX_IMAGE_SIZE = 2097152;
const SLICE_SIZE = 512;
const ENV = getEnviroment();

export const useVImageUploader = (aspect, onChange, initialImageSrc, name) => {
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(initialImageSrc);
  const { showNotification } = useNotifications();
  const isUploading = useRef(false);
  const imgRef = useRef();
  const previewCanvasRef = useRef();

  const centerAspectCrop = (mediaWidth, mediaHeight) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  useEffect(() => {
    if (initialImageSrc && previewCanvasRef.current) {
      setCanvasImage(previewCanvasRef.current, initialImageSrc);
    }
  }, [initialImageSrc]);

  const getBlobCropImage = (image) => {
    const contentType = image.match(/^data:(.*);base64/)?.[1] || 'image/jpeg';
    const byteCharacters = atob(image.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += SLICE_SIZE) {
      const slice = byteCharacters.slice(offset, offset + SLICE_SIZE);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const uploadImage = async (image) => {
    const imageBlob = getBlobCropImage(image);
    const formData = new FormData();
    formData.append('picture', imageBlob, 'image.jpg');

    isUploading.current = true;

    await handleRequestHelper({
      endpoint: () => api.uploadImage(formData),
      onSuccess: ({ data }) => {
        const imageCDN = IMAGES_CDN_BY_ENV[ENV];
        const imageUrl = `${imageCDN}/${data.Key}`;
        onChange({ target: { name, value: imageUrl } });
        isUploading.current = false;
      },
    });
  };

  const updateCanvasPreview = () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  };

  const handleClose = () => setOpen(false);

  const handleAccept = async () => {
    updateCanvasPreview();
    const croppedImage = previewCanvasRef.current.toDataURL('image/jpeg');
    await uploadImage(croppedImage);
    setOpen(false);
  };

  const onImageLoad = ({ currentTarget }) => {
    if (aspect) {
      const { width, height } = currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onCropComplete = (crop) => setCompletedCrop(crop);

  const onCropChange = (_, percentCrop) => setCrop(percentCrop);

  const onSelectFile = (files) => {
    if (files && files.length > 0) {
      if (files[0].size > MAX_IMAGE_SIZE) {
        showNotification('La imagen no puede ser mayor a 2MB', NOTIFICATION_VARIANT.ERROR);
        return;
      }

      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(files[0]);
      setOpen(true);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    onSelectFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleSearchFile = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleDeleteImage = () => {
    onChange({ target: { name, value: '' } });
    setImageSrc('');
    imgRef.current = null;

    if (previewCanvasRef.current) {
      const ctx = previewCanvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, previewCanvasRef.current.width, previewCanvasRef.current.height);
    }

    if (inputRef.current) inputRef.current.value = '';
  };

  return {
    crop,
    previewCanvasRef,
    imageSrc,
    open,
    imgRef,
    inputRef,
    isDragActive,
    handleAccept,
    handleSearchFile,
    onSelectFile,
    handleClose,
    onCropChange,
    onCropComplete,
    onImageLoad,
    getRootProps,
    getInputProps,
    handleDeleteImage,
  };
};
