import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    datePickerContainer: {
      position: 'absolute',
      top: 60,
      left: 0,
      width: '100%',
      maxWidth: 340,
    },
    dateButton: {
      '&.MuiChip-root': {
        borderRadius: 100,
        backgroundColor: '#FFFFFF',
        fontWeight: 600,

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: '#FFFFFF ',
        },
      },
    },
  });

export default styles;
