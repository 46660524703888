import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';
import VRichTextInput from '../../../Venti-UI-Kit/VInputs/VRichTextInput/VRichTextInput';
import VImageUploader from '../../../Venti-UI-Kit/VImageUploader/VImageUploader';
import { useCreateEventPromotionPage } from './hook';
import Button from '../../../Components/Buttons/Button/Button';
import VTextInput from '../../../Venti-UI-Kit/VInputs/VTextInput/VTextInput';

const CreateEventPromotionPage = () => {
  const { formState, eventName, handleInputChange, handleSubmit } = useCreateEventPromotionPage();

  return (
    <PageContainer title="Backoffice - Nueva Promoción" maxWidth={900}>
      <VBreadcrumbs pageTitle="Nueva Promoción" />
      <Stack maxWidth={900} width="100%" gap={3} alignSelf="center" p={2}>
        <BackofficePageTitle secondaryTitle="Nueva Promoción" mainTitle={eventName} />
        <VRichTextInput
          name="promotionText"
          value={formState.promotionText.value}
          errorMessage={formState.promotionText.error}
          required={formState.promotionText.required}
          onChange={handleInputChange}
          title="Descripción de la promoción"
        />
        <VTextInput
          title="Link de la promoción"
          startAdornment="https://"
          name="promotionLink"
          onChange={handleInputChange}
          value={formState.promotionLink.value}
          errorMessage={formState.promotionLink.error}
          required={formState.promotionLink.required}
        />
        <VImageUploader
          title="Imagen de la promoción"
          width={230}
          name="image"
          imageHeight={230}
          imageWidth={230}
          initialImageSrc={formState.image.value}
          required={formState.image.required}
          onChange={handleInputChange}
          canDelete
        />
        <Stack width="100%" alignItems="center">
          <Button variant="contained" fullwidth={false} title="Crear" onClick={handleSubmit} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default CreateEventPromotionPage;
