import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    ticketCardContainer: {
      width: '100%',
      minHeight: 150,
      gap: 24,
      padding: '16px 0',
    },
    ticketCardImage: {
      height: 230,
      objectFit: 'cover',
      borderRadius: 16,
    },
  });

export default styles;
