import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, Modal, Stack } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import modalStyles from '../../../Venti-UI-Kit/Modals/ModalStyles';
import CustomLink from '../../CustomLink/CustomLink';
import styles from './EventPaymentMethodsAndPromotionsModalStyles';
import { ReactComponent as MastercardImage } from '../../../assets/images/mastercard.svg';
import { ReactComponent as VisaImage } from '../../../assets/images/visa.svg';
import { ReactComponent as AmexImage } from '../../../assets/images/amex.svg';
import { ReactComponent as CabalImage } from '../../../assets/images/cabal.svg';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import IconButton from '../../Buttons/IconButton/IconButton';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';

const cardImages = {
  AMEX: <AmexImage />,
  VISA: <VisaImage />,
  MASTER: <MastercardImage />,
  CABAL: <CabalImage />,
};

const EventPromotionsModal = ({
  classes,
  open,
  title,
  subtitle,
  onClose,
  eventPromotions,
  availableCards,
}) => {
  const hasEventPromotions = !!eventPromotions?.length;
  const hasAvailableCards = !!availableCards?.length;
  const theme = useTheme();

  return (
    <Modal open={open} className={classes.modalContainer} onClose={onClose} disableScrollLock>
      <Stack className="modalBoxContainer bankPromotionModal">
        <Stack alignItems="center">
          <VTypography variant="subtitle1">{title}</VTypography>
          <VTypography>{subtitle}</VTypography>
          <IconButton
            variant="text"
            Icon={<VIcon name="close" color={theme.palette.grey[700]} />}
            style={{ position: 'absolute', right: 10, top: 10 }}
            onClick={onClose}
          />
        </Stack>
        {hasAvailableCards && (
          <Stack gap={3}>
            <VTypography variant="subtitle4">Tarjetas de débito y crédito</VTypography>
            <Box display="flex" gap={2} flexWrap="wrap">
              {availableCards.map(({ issuerCode }) => cardImages[issuerCode])}
            </Box>
          </Stack>
        )}

        {hasEventPromotions && (
          <Stack overflow="auto" gap={3}>
            {hasAvailableCards && (
              <VTypography variant="subtitle4">Promociones bancarias</VTypography>
            )}

            {eventPromotions.map(({ promotionText, promotionImages, promotionLink }, index) => (
              <Box key={promotionText} textAlign="left">
                {ReactHtmlParser(promotionText)}
                <Box display="flex" gap={2} key={index} flexWrap="wrap">
                  {promotionImages.map((image) => (
                    <img src={image} alt="card" key={image} className="promotionImage" />
                  ))}
                </Box>
                {promotionLink && (
                  <CustomLink title="Ver más información" target="_blank" href={promotionLink} />
                )}
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default withStyles(styles)(withStyles(modalStyles)(EventPromotionsModal));
