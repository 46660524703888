import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './RevenueTableStyles';
import { formatPrice } from '../../../utils/utils';

const RevenueTable = ({ classes, revenueData }) => {
  const { totalSales, totalCashSales, totalOnlineSales } = revenueData;

  const infoToShow = [
    {
      label: 'Total online',
      value: formatPrice(totalOnlineSales),
    },
    {
      label: 'Total cash',
      value: formatPrice(totalCashSales),
    },
  ];

  return (
    <div className={classes.totalRevenueContainer}>
      <div className={classes.totalRevenueHeader}>
        <Typography className={classes.tableTitle}>Ingresos Totales</Typography>
        <Typography className={classes.tableSubtitle}>{formatPrice(totalSales)}</Typography>
      </div>
      <div className={classes.totalRevenueBody}>
        {infoToShow.map(({ label, value }, index) => (
          <div key={value + index} className={classes.tableCell}>
            <Typography className={classes.cellLabel}>{label}</Typography>
            <Typography className={classes.cellValue}>{value}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(RevenueTable);
