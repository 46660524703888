import React from 'react';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import styles from './EventListItem.styles';
import permissions from '../../../Providers/Permissions/Permissions';
import { copyToClipboard, formatDate, getEventLink } from '../../../utils/utils';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

const EventListItem = ({ classes, event }) => {
  const { name, promoImg, startDate, producerId, description, id } = event;
  const { showNotification } = useNotifications();
  const { user } = useAuth();
  const { role } = user;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.titleMobileContainer}>
        <Typography className={classes.titleMobile}>{name}</Typography>
        <Typography className={classes.startDateMobile}>{formatDate(startDate)}</Typography>
      </div>
      <div className={classes.imageAndInfoContainer}>
        <img alt="Event_image" className={classes.image} src={promoImg} />
        <div className={classes.infoContainer}>
          <Tooltip title={name} followcursor="true">
            <Typography className={classes.titleDesktop}>{name}</Typography>
          </Tooltip>
          <Typography className={classes.startDateDesktop}>{formatDate(startDate)}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {permissions.isSeller(role) ? (
          <Button
            className={classes.button}
            color="alternative"
            onClick={() =>
              copyToClipboard(getEventLink(event, user), showNotification, 'Enlace copiado!')
            }
          >
            Copiar mi link
          </Button>
        ) : (
          <Button
            target="_blank"
            className={classes.button}
            href={getEventLink(event, user)}
            color="alternative"
          >
            Ver evento
          </Button>
        )}
        <Button
          className={classes.optionsButton}
          href={`/backoffice/productoras/${producerId}/eventos/${id}`}
        >
          Opciones
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(EventListItem);
