import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContent: {
      backgroundColor: theme.palette.background.paper,
      marginTop: 16,
      borderRadius: 8,
      padding: 16,
      width: '100%',
      alignSelf: 'center',
      gap: 24,
    },
  });

export default styles;
