import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import EventForm from '../../../Components/Forms/EventForm/EventForm';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import { FORM_MODE } from '../../../Components/Forms/EventForm/constants';
import { useEditEventPage } from './hook';
import BackofficePageTitle from '../../../Components/BackofficePageTitle/BackofficePageTitle';

const EditEventPage = () => {
  const { isLoading, producerData, eventData } = useEditEventPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Editar Evento">
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Editar Evento">
      <VBreadcrumbs pageTitle="Editar Evento" />
      <Stack alignItems="center" gap={3} px={2}>
        <BackofficePageTitle mainTitle={eventData.name} secondaryTitle="Editar Evento" />
        <EventForm
          producerData={producerData}
          eventData={eventData}
          buttonLabel="Guardar"
          mode={FORM_MODE.EDIT}
        />
      </Stack>
    </PageContainer>
  );
};

export default EditEventPage;
