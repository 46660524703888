import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  INPUT_TYPE,
  NOTIFICATION_VARIANT,
  TICKET_TYPE,
  TICKET_VISIBILITY_MODE,
  USER_PERMISSIONS,
} from '../../../constants/types';
import { useForm } from '../../../hooks/useForm';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { getUrlSearchParams, validateUserRole } from '../../../utils/utils';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { INVITATION_MAX_QUANTITY_PER_ORDER, TICKET_EMPTY_STATE } from './constants';
import { FORM_MODE } from '../EventForm/constants';
import { FLAGS } from '../../../constants/constants';

export const useTicketForm = (ticketData, mode) => {
  const initialTicketData = { ...TICKET_EMPTY_STATE, ...ticketData };
  const { eventId, producerId } = useParams();
  const { user } = useAuth();
  const { showNotification } = useNotifications();
  const ticketSection = useRef(initialTicketData.section);
  const isTableType = useRef(initialTicketData.type === TICKET_TYPE.TABLE);
  const isInviationType = useRef(initialTicketData.type === TICKET_TYPE.INVITATION);
  const isSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);
  const [showCashSaleCheckbox, setShowCashSaleCheckbox] = useState(isSuperAdmin);
  const [isLoading, setIsLoading] = useState(false);

  const getCashSaleFlag = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFlag(FLAGS.CASH_SALE_ENABLED),
      onSuccess: ({ flag }) => {
        setShowCashSaleCheckbox(
          (prevState) =>
            prevState || (flag.context.enabled && flag.userIdAllowList.includes(Number(producerId)))
        );
      },
    });
  };

  useEffect(() => {
    getCashSaleFlag();
  }, []);

  const [isAccessTimeRestricted, setIsAccessTimeRestricted] = useState(
    !!initialTicketData.stopOfAccessDate
  );

  const validateMaxQuantity = (value) => {
    if (!ticketSection.current || !Object.keys(ticketSection.current).length) return;

    const otherSectionTickets = ticketSection.current.ticketTypes.filter(
      ({ id }) => id !== initialTicketData.id
    );

    const usedStock = otherSectionTickets.reduce((acc, { maxQuantity }) => acc + maxQuantity, 0);
    const availableStock = ticketSection.current.capacity - usedStock;

    return value > availableStock
      ? `Superaste el stock disponible del sector, el máximo disponible es: ${availableStock} tickets`
      : '';
  };

  const { formState, formValues, handleInputChange, validateFormInputs } = useForm(
    {
      name: {
        value: initialTicketData.name,
      },
      price: {
        value: initialTicketData.priceInCents / 100,
        required: !isInviationType.current,
        minValue: 0,
        type: INPUT_TYPE.FLOAT,
      },
      maxQuantity: {
        value: initialTicketData.maxQuantity,
        customValidation: validateMaxQuantity,
        type: INPUT_TYPE.NUMBER,
        minValue: 1,
      },
      description: {
        value: initialTicketData.description,
        required: false,
      },
      visibilityMode: {
        value: initialTicketData.visibilityMode,
        required: !isInviationType.current,
      },
      activityStatus: {
        value: initialTicketData.activityStatus,
      },
      onlyOnline: {
        value: !initialTicketData.onlyOnline,
        required: !isInviationType.current,
      },
      type: {
        value: initialTicketData.type,
      },
      maxQuantityPerOrder: {
        value: initialTicketData.maxQuantityPerOrder / (initialTicketData.groupSize || 1),
        type: INPUT_TYPE.NUMBER,
        minValue: 1,
      },
      groupSize: {
        value: initialTicketData.groupSize,
        required: isTableType.current,
        minValue: 1,
      },
      stopOfSalesDate: {
        value: isInviationType.current
          ? initialTicketData.shouldExtendStopOfSalesDateToInvitations
            ? initialTicketData.eventStopOfSalesDate
            : initialTicketData.eventEndDate
          : initialTicketData.stopOfSalesDate ||
            initialTicketData.eventStopOfSalesDate ||
            new Date(),
        type: INPUT_TYPE.DATE,
        required: !isInviationType.current,
      },
      stopOfAccessDate: {
        value: initialTicketData.stopOfAccessDate || new Date(),
        required: false,
        type: INPUT_TYPE.DATE,
      },
      isLemonExclusive: {
        value: initialTicketData.isLemonExclusive,
        required: false,
      },
      discountCodePercentage: {
        value: initialTicketData.discountCodeData.percentage,
        required: ({ discountCode, isLemonExclusive }) => discountCode && !isLemonExclusive,
        type: INPUT_TYPE.NUMBER,
        minValue: 1,
      },
      discountCode: {
        value: initialTicketData.discountCodeData.code,
        required: false,
      },
      image: {
        value: initialTicketData.Images?.[0]?.url,
        required: false,
      },
      section: {
        value: initialTicketData.section?.id,
        required: initialTicketData.stadiumSections?.length > 0,
      },
    },
    [isTableType, isInviationType]
  );

  const toggleAccessTimeRestriction = () => {
    setIsAccessTimeRestricted((prevState) => !prevState);
  };

  const handleTypeChange = ({ target }) => {
    handleInputChange({ target });

    isTableType.current = target.value === TICKET_TYPE.TABLE;
    isInviationType.current = target.value === TICKET_TYPE.INVITATION;

    if (target.value === TICKET_TYPE.PRESENTIAL) {
      isTableType.current = false;
      isInviationType.current = false;
    }
  };

  const handleSectionChange = ({ target }) => {
    ticketSection.current = initialTicketData.stadiumSections.find(({ id }) => id === target.value);
    handleInputChange({ target });
  };

  const formatTicketInfo = () => {
    const priceInCents = isInviationType.current ? 0 : formValues.price * 100;
    const {
      hasDiscountCode,
      discountCodePercentage,
      discountCode,
      section,
      priceWithLemon,
      maxQuantity,
      maxQuantityPerOrder,
      ...otherProps
    } = formValues;
    return {
      ...otherProps,
      ...(mode === FORM_MODE.EDIT && { id: initialTicketData.id }),
      eventStadiumLayoutSectionId: section,
      priceInCents,
      onlyOnline: isInviationType.current ? true : !formValues.onlyOnline,
      maxQuantity: Number(maxQuantity),
      maxQuantityPerOrder: Number(
        isTableType.current
          ? maxQuantityPerOrder * otherProps.groupSize
          : isInviationType.current
          ? INVITATION_MAX_QUANTITY_PER_ORDER
          : maxQuantityPerOrder
      ),
      priceInCentsWithLemon: priceInCents,
      priceWithLemon: priceInCents / 100,
      price: priceInCents,
      visibilityMode: isInviationType.current
        ? TICKET_VISIBILITY_MODE.HIDDEN
        : formValues.visibilityMode,
      isLemonExclusive: isInviationType.current ? false : formValues.isLemonExclusive,
      stopOfAccessDate: !isAccessTimeRestricted ? null : formValues.stopOfAccessDate,
      eventId,
      images: formValues.image ? [{ url: formValues.image, type: 'image', priority: 1 }] : [],
    };
  };

  const skipAllTickets = () => {
    window.location.href = `/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`;
  };

  const handleNavigation = () => {
    if (mode === FORM_MODE.DUPLICATE) {
      const { tickets } = getUrlSearchParams();
      const ticketsIdsArray = tickets.split(',');
      ticketsIdsArray.shift();
      const newTicketsQuery = ticketsIdsArray.join(',');

      if (!newTicketsQuery.length) skipAllTickets();
      else window.location.href = `${window.location.pathname}?tickets=${newTicketsQuery}`;
    } else
      setTimeout(() => {
        window.location.href = `/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`;
      }, 500);
  };

  const deleteDiscountCode = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.deleteDiscountCode(initialTicketData.id, initialTicketData.discountCodeData.code),
      showNotification,
    });
  };

  const createDiscountCode = async (ticketId) => {
    const initialDiscountCode = initialTicketData.discountCodeData.code;
    const currentDiscountCode = formValues.discountCode;

    const isDiscountCodeModified = initialDiscountCode !== currentDiscountCode;

    const shouldCreateDiscountCode = isDiscountCodeModified && currentDiscountCode;

    const shouldDeleteDiscountCode =
      formValues.isLemonExclusive ||
      isInviationType.current ||
      (initialDiscountCode && isDiscountCodeModified);

    if (!shouldCreateDiscountCode && !shouldDeleteDiscountCode) return true;

    const discountCode = {
      code: currentDiscountCode,
      percentage: Number(formValues.discountCodePercentage),
    };

    if (shouldDeleteDiscountCode && !shouldCreateDiscountCode) {
      await deleteDiscountCode();
      return true;
    }

    return handleRequestHelper({
      endpoint: () => api.createDiscountCode(ticketId, discountCode),
      onSuccess: async () => {
        if (shouldDeleteDiscountCode) await deleteDiscountCode();
        return true;
      },
      onFailure: () => false,
      showNotification,
    });
  };

  const updateTicket = async () => {
    const ticketData = formatTicketInfo();
    const canUpdate = await createDiscountCode(ticketData.id);
    if (!canUpdate) return false;

    await handleRequestHelper({
      endpoint: () => api.updateTicketType(ticketData),
      onSuccess: async () => {
        showNotification('Ticket actualizado con éxito', NOTIFICATION_VARIANT.SUCCESS);
        handleNavigation();
      },
      onFailure: () => false,
      showNotification,
    });
  };

  const createTicket = async () => {
    const ticketData = formatTicketInfo();

    await handleRequestHelper({
      endpoint: () => api.createTicketType(ticketData),
      onSuccess: async ({ ticketType }) => {
        await createDiscountCode(ticketType.id);
        showNotification('Ticket creado con éxito', NOTIFICATION_VARIANT.SUCCESS);
        handleNavigation();
      },
      onFailure: () => false,
      showNotification,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const isValid = validateFormInputs();
    if (!isValid) {
      showNotification('Hay errores en el formulario', NOTIFICATION_VARIANT.ERROR);
      setIsLoading(false);
      return;
    }

    if (mode === FORM_MODE.EDIT) await updateTicket();
    else await createTicket();
    setIsLoading(false);
  };

  const stadiumSectionsOptions = initialTicketData.stadiumSections?.map(
    ({ id, name, capacity }) => ({
      value: id,
      label: `${name} - ${capacity} loc.`,
    })
  );

  return {
    formState,
    stadiumSectionsOptions,
    isAccessTimeRestricted,
    isSuperAdmin,
    isInviationType: isInviationType.current,
    isTableType: isTableType.current,
    ticketSection: ticketSection.current,
    showCashSaleCheckbox,
    handleInputChange,
    handleTypeChange,
    toggleAccessTimeRestriction,
    handleSectionChange,
    handleSubmit,
    skipAllTickets,
    handleNavigation,
    isLoading,
  };
};
