import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { QR_STATUS, SECONDARY_MARKET_PUBLICATION_STATUS } from '../../../../constants/types';
import { priceFormatter } from './EventTicketCard.utils';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';

export const useEventTicketCard = (qr, isShieldActive, isShieldActiveForPublishedQrs) => {
  const { user } = useAuth();
  const customerId = user?.id;
  const [openTransferBottomSheet, setOpenTransferBottomSheet] = useState(false);
  const [openReturnBottomSheet, setOpenReturnBottomSheet] = useState(false);
  const [openPostBottomSheet, setOpenPostBottomSheet] = useState(false);
  const [openDeletePostBottomSheet, setOpenDeletePostBottomSheet] = useState(false);
  const [openSelfManagedRefundBottomSheet, setOpenSelfManagedRefundBottomSheet] = useState(false);
  const [qrIsLoading, setQrIsLoading] = useState(false);
  const [showMoreIsOpen, setShowMoreIsOpen] = useState(false);

  const isOriginalOwner = !qr.qrTransfer || qr.qrTransfer?.order.customerId === customerId;
  const theme = useTheme();

  const { id: qrId, price, status, qrTransfer, secondaryMarket } = qr;

  useEffect(() => {
    setQrIsLoading(true);
  }, [qrId]);

  const formattedTicketPrice = priceFormatter(price);

  const getQrMessage = () => {
    let qrMessage = 'Se habilitará horas antes al inicio del evento';

    if (status === QR_STATUS.USED) {
      qrMessage = 'El QR fue utilizado';
    } else if (status === QR_STATUS.EXPIRED) {
      qrMessage = 'El QR expiró';
    } else if (qrId) {
      qrMessage = '';
    }

    return qrMessage;
  };

  const ticketBelongsToAnotherCustomer =
    (qrTransfer && qrTransfer.destinationCustomer.id !== customerId) ||
    secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.SOLD;

  const showButtonsActions =
    !ticketBelongsToAnotherCustomer &&
    !qrId &&
    price !== 0 &&
    (isShieldActiveForPublishedQrs || !secondaryMarket);

  const ticketIsPublished =
    secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.PUBLISHED;

  const goToSecondaryMarketPublication = () => {
    window.location.href = secondaryMarket.publicationLink;
  };

  const actionsButtons = showButtonsActions && [
    {
      show:
        (secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.UNPUBLISHED ||
          secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT) &&
        isShieldActive,
      key: 'post',
      label: 'Publicar en Venti Swap',
      action: () => setOpenPostBottomSheet(true),
    },
    {
      show: ticketIsPublished && secondaryMarket?.publicationLink,
      key: 'editPost',
      label: 'Modificar publicación',
      action: () => goToSecondaryMarketPublication(),
    },
    {
      show: ticketIsPublished,
      key: 'deletePost',
      label: 'Dejar de publicar',
      action: () => setOpenDeletePostBottomSheet(true),
    },
    {
      show:
        !ticketIsPublished &&
        (!qrTransfer || qrTransfer.destinationCustomer.id !== customerId) &&
        isShieldActive,
      key: 'transfer',
      label: 'Transferir ticket',
      action: () => setOpenTransferBottomSheet(true),
    },
    {
      show:
        !ticketIsPublished &&
        qrTransfer &&
        qrTransfer.destinationCustomer.id === customerId &&
        isShieldActive,
      key: 'return',
      label: 'Devolver ticket',
      action: () => setOpenReturnBottomSheet(true),
    },
  ];

  const bottomSheets = {
    transfer: {
      open: openTransferBottomSheet,
      onClose: () => setOpenTransferBottomSheet(false),
    },
    return: {
      open: openReturnBottomSheet,
      onClose: () => setOpenReturnBottomSheet(false),
    },
    post: {
      open: openPostBottomSheet,
      onClose: () => setOpenPostBottomSheet(false),
    },
    deletePost: {
      open: openDeletePostBottomSheet,
      onClose: () => setOpenDeletePostBottomSheet(false),
    },
    selfManagedRefund: {
      open: openSelfManagedRefundBottomSheet,
      onClose: () => setOpenSelfManagedRefundBottomSheet(false),
    },
  };

  const { firstName, lastName, mail } = qrTransfer?.originCustomer || {};
  const originCustomerLabel =
    qrTransfer && firstName && lastName ? `${firstName} ${lastName}` : mail;

  const getSecondaryMarketStatusLabel = () => {
    const status = secondaryMarket?.status;

    switch (status) {
      case SECONDARY_MARKET_PUBLICATION_STATUS.SOLD:
        return {
          label: 'Vendido',
          bgcolor: theme.palette.error.main,
          show: true,
        };
      case SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT:
        return {
          label: 'Venti Swap',
          bgcolor: theme.palette.info.main,
          show: true,
        };
      case SECONDARY_MARKET_PUBLICATION_STATUS.PUBLISHED:
        return {
          label: 'Publicado',
          bgcolor: theme.palette.success.main,
          show: isShieldActiveForPublishedQrs,
        };
      default:
        return {
          label: null,
          bgcolor: null,
          show: false,
        };
    }
  };

  const secondaryMarketStatusLabel = getSecondaryMarketStatusLabel();

  const getLastTransactionData = () => {
    if (!secondaryMarket) return null;

    const { soldData, boughtData } = secondaryMarket;

    if (!soldData && !boughtData) return null;

    if (!soldData) {
      return {
        ...boughtData,
        type: SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT,
      };
    }

    if (!boughtData) {
      return {
        ...soldData,
        type: SECONDARY_MARKET_PUBLICATION_STATUS.SOLD,
      };
    }

    const soldDate = new Date(soldData.date);
    const boughtDate = new Date(boughtData.date);

    return soldDate > boughtDate
      ? { ...soldData, type: SECONDARY_MARKET_PUBLICATION_STATUS.SOLD }
      : { ...boughtData, type: SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT };
  };

  const handleOpenSelfManagedRefundBottomSheet = () => {
    setOpenSelfManagedRefundBottomSheet(true);
  };

  return {
    formattedTicketPrice,
    actionsButtons,
    ticketBelongsToAnotherCustomer,
    bottomSheets,
    originCustomerLabel,
    secondaryMarketStatusLabel,
    secondaryMarket,
    secondaryMarketTransactionData: getLastTransactionData(),
    isOriginalOwner,
    handleOpenSelfManagedRefundBottomSheet,
    qrMessage: getQrMessage(),
    qrId,
    qrStatus: status,
    qrIsLoading,
    setQrIsLoading,
    showMoreIsOpen,
    setShowMoreIsOpen,
  };
};
